import React from "react"

import { SecondaryAntCalendarWrapper } from "./Calendar.style"
import dayjs, { Dayjs } from "dayjs"
import ArraySupport from "dayjs/plugin/arraySupport"
import { CalendarObjProps, CalendarProps } from "../../types"
dayjs.extend(ArraySupport)
const SecondaryCalendar = ({
  cellRender,
  value,
  minWidth,
}: CalendarProps): JSX.Element => {
  return (
    <SecondaryAntCalendarWrapper
      $minWidth={minWidth}
      value={dayjs(value)}
      headerRender={() => null}
      disabledDate={(date: Dayjs) => {
        const today = value ? dayjs(value) : dayjs()
        const isThisMonth = today.month() !== date.month()
        return isThisMonth
      }}
      validRange={[
        dayjs([dayjs(value)?.year(), dayjs(value)?.month(), 1]),
        dayjs([
          dayjs(value)?.year(),
          dayjs(value)?.month(),
          dayjs(value).daysInMonth() + 1,
        ]),
      ]}
      fullCellRender={(date: Dayjs) => {
        const today = value ? dayjs(value) : dayjs()
        const active = today.month() === date.month()
        const { isHoliday, content, holidayContent } = cellRender(
          date,
        ) as CalendarObjProps

        return active ? (
          <div
            className={`content ${isHoliday ? "holiday__content" : ""}`}
            title={""}
          >
            <div
              className={`default-border ${
                isHoliday ? "border-white" : "border-gray"
              } ${dayjs().isSame(date, "date") ? "active-border" : ""} `}
            >
              <div className={"month_day"}>
                <div className={"date"}>{dayjs(date).format("DD")}</div>
              </div>

              <div className={"info"}>
                {isHoliday ? holidayContent || "" : content}
              </div>
            </div>
          </div>
        ) : (
          <div className={"content"}>
            <div className={"default-border"}>
              <div className={"month_day"}>
                <div className={"date"}>{dayjs(date).format("DD")}</div>
              </div>
            </div>
          </div>
        )
      }}
      fullscreen
    />
  )
}
SecondaryCalendar.DisplayName = "SecondaryCalendar"
export { SecondaryCalendar }
