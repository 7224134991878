import { Row } from "antd"
import styled from "styled-components"
import { GridProps } from "./Grid"
import { theme } from "../../theme"

export const StyledRow = styled(Row)<GridProps>`
  width: 100%;
  border: 1px solid ${theme.colors.border};
  .col__content {
    padding: ${(props) => props?.padding || "24px 16px"};
    background-color: ${theme.colors.white};
    width: 100%;
  }
  .label {
    border-right: 1px solid ${theme.colors.border};
    text-align: ${(props) => props?.textAlign || "center"};
    display: flex;
    align-items: ${(props) => props?.labelAlignItems || "center"};
    justify-content: ${(props) => props?.justifyContent || "space-between"};
    #label__text {
      ${theme.typography["body-regular"]};
      font-weight: ${(props) => props?.fontWeight || "400"} !important;
    }
  }
  .row__header {
    ${theme.typography["body-regular"]};
  }
  &:not(:last-child) {
    border-bottom: 0 !important;
  }

  /* //MEDIA QUERIES */
  @media screen and (max-width: ${theme.breakpoints.lg}) {
    border: 0;
    .label {
      border-right: 0 !important;
      border: 1px solid ${theme.colors.border} !important;
      border-radius: ${(props) => props?.borderRadius || "5px"};
      #label__text {
        padding: 5px !important;
      }
    }
    .col__content {
      padding: 5px 0px;
      border-radius: ${(props) => props?.borderRadius || "5px"};
    }
  }

  @media print {
    border: 2px solid black;
    flex-wrap: nowrap !important;
    page-break-inside: avoid !important;
    .ant-col.ant-col-sm-24 {
      flex: 0.5 !important;
      min-width: 150px !important;
    }
    .ant-col:nth-child(2) {
      flex: 1 !important;
      width: 100% !important;
    }
    .label {
      border-bottom: 0 !important;
      background: white !important;
      border-right: 2px solid black;
    }
    &:not(:last-child) {
      border-bottom: 1px solid black !important;
    }
  }
`
