import styled from "styled-components"
import { useResponsiveMediaQuery } from "../../hooks"
import { theme } from "../../theme"
interface ErrorMassageProps {
  message: string
  size?: string
  type?: "info" | "error" | "subInfo"
  mt?: string
  ml?: string
}
const StyleMessage = styled.p<any>`
  margin: 0;
  margin-top: ${(props) => props.mt || "8px"};
  margin-left: ${(props) => props.ml || "8px"};
  padding: 0;
  color: ${(props) =>
    props.type === "info"
      ? theme.colors["text-gray"]
      : props.type === "subInfo"
        ? theme.colors.text
        : theme.colors.error};
  font-size: ${(props) => props.size || "13px"};
  opacity: ${(props) => (props.type === "info" ? 0.6 : 1)};
  white-space: ${(props) => (props.$isTabletOrMobile ? "normal" : "nowrap")};
  transition: all 1s ease-in-out;
  @media screen and (max-width: ${theme.breakpoints.lg}) {
    margin-top: 4px !important;
  }
`
const ErrorMassage = ({
  message,
  type = "error",
  mt,
  ml,
}: ErrorMassageProps): JSX.Element => {
  const { isTabletOrMobile } = useResponsiveMediaQuery()
  return (
    <StyleMessage
      type={type}
      mt={mt}
      ml={ml}
      className={"error-message"}
      $isTabletOrMobile={isTabletOrMobile}
    >
      {message}
    </StyleMessage>
  )
}
ErrorMassage.displayName = "ErrorMassage"
export { ErrorMassage }
