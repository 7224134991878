import { API, removeBlankAttributes } from "@project/common"

export const fetchUserFacility = ({
  ...args
}: {
  page?: number
  pageSize?: number | string
  facility?: string
  user_id?: number
  application_status?: number
  year?: string
  month?: string
  service_type01?: any
  service_type02?: any
  service_type03?: any
}): Promise<Response> => {
  const service = []
  if (args.service_type01) {
    service.push("1")
  }
  if (args.service_type02) {
    service.push("2")
  }
  if (args.service_type03) {
    service.push("3")
  }
  const params = removeBlankAttributes({
    page: args?.page,
    pageSize: args?.pageSize,
    facilityIds: args?.facility,
    user_id: args?.user_id,
    application_status: args?.application_status,
    year: args?.year,
    month: args?.month,
    service: service?.join(","),
  })
  return API.get(`/user-facility-schedule-registration-status`, {
    params,
  })
}

export const getUserData = async ({
  ...args
}: {
  page: number
  pageSize: string
}) => {
  const params = removeBlankAttributes(args)
  return API.get(`/user`, { params })
}
