import { theme } from "../../theme"
import styled from "styled-components"
import { Box } from "../../components"

export const ResetFormContainer = styled(Box)`
  background: ${theme.colors.white};
  border: 1px solid ${theme.colors.border};
  padding: 2em;
  border-radius: 16px;
  width: 360px;
`
