import React from "react"
import { Skeleton as AntSkeleton, Space } from "antd"
interface SkeletonProps {
  length?: number
  gutter?: any
}
const Skeleton = ({ length = 1 }: SkeletonProps): JSX.Element => {
  return (
    <Space
      direction={"vertical"}
      style={{ width: "100%", padding: "16px" }}
      size={16}
    >
      {Array.from({ length }, (_, i) => (
        <AntSkeleton active key={i} />
      ))}
    </Space>
  )
}
Skeleton.displayName = "Skeleton"
export { Skeleton }
