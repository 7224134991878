import { API, removeBlankAttributes } from "@project/common"
import { UserAttendanceCalendarParams } from "../types"

export const getAttendanceScheduleStats = async ({
  facilityId,
  year,
  month,
  ...rest
}: UserAttendanceCalendarParams) => {
  const params = removeBlankAttributes(rest)
  return API.get(`/user-attendance/calendar/${facilityId}/${year}/${month}`, {
    params,
  })
}
