export const FULL_DATE_FORMATE = "YYYY年MM月DD日"
export const PAGE_SIZE = 20
export const PAGE_SIZE_20 = 20
export const FULL_DATE_FORMAT = "YYYY年MM月DD日"
export const FULL_DATE_TIME_FORMAT = "YYYY年MM月DD日 HH:mm:ss"
export const SLASH_DATE_FORMAT = "YYYY/MM/DD"

export const FULL_DATE_FORMAT_EN = "YYYY-MM-DD"
export const YM_FORMAT = "YYYY年MM月"
export const MILITARY_DATE_FORMAT = "YYYY-MM-DDT00:00:00Z"

export const TARGET_OF_USER = [
  {
    label: "Staff",
    value: "1",
  },
  {
    label: "User",
    value: "2",
  },
]
export const PHONE_NO_REGEX = /^(?=.*[0-9])[-0-9]+$/

export const FuriganaAlphabetsOption = [
  {
    label: "---",
    value: 0,
  },
  {
    label: "あ",
    value: "あ",
  },
  {
    label: "い",
    value: "い",
  },
  {
    label: "う",
    value: "う",
  },
  {
    label: "え",
    value: "え",
  },
  {
    label: "お",
    value: "お",
  },
  {
    label: "か",
    value: "か",
  },
  {
    label: "き",
    value: "き",
  },
  {
    label: "く",
    value: "く",
  },
  {
    label: "け",
    value: "け",
  },
  {
    label: "こ",
    value: "こ",
  },
  {
    label: "さ",
    value: "さ",
  },
  {
    label: "し",
    value: "し",
  },
  {
    label: "す",
    value: "す",
  },
  {
    label: "せ",
    value: "せ",
  },
  {
    label: "そ",
    value: "そ",
  },
  {
    label: "た",
    value: "た",
  },
  {
    label: "ち",
    value: "ち",
  },
  {
    label: "つ",
    value: "つ",
  },
  {
    label: "て",
    value: "て",
  },
  {
    label: "と",
    value: "と",
  },
  {
    label: "な",
    value: "な",
  },
  {
    label: "に",
    value: "に",
  },
  {
    label: "ぬ",
    value: "ぬ",
  },
  {
    label: "ね",
    value: "ね",
  },
  {
    label: "の",
    value: "の",
  },
  {
    label: "は",
    value: "は",
  },
  {
    label: "ひ",
    value: "ひ",
  },
  {
    label: "ふ",
    value: "ふ",
  },
  {
    label: "へ",
    value: "へ",
  },
  {
    label: "ほ",
    value: "ほ",
  },
  {
    label: "ま",
    value: "ま",
  },
  {
    label: "み",
    value: "み",
  },
  {
    label: "む",
    value: "む",
  },
  {
    label: "め",
    value: "め",
  },
  {
    label: "も",
    value: "も",
  },
  {
    label: "や",
    value: "や",
  },
  {
    label: "ゆ",
    value: "ゆ",
  },
  {
    label: "よ",
    value: "よ",
  },
  {
    label: "ら",
    value: "ら",
  },
  {
    label: "り",
    value: "り",
  },
  {
    label: "る",
    value: "る",
  },
  {
    label: "れ",
    value: "れ",
  },
  {
    label: "ろ",
    value: "ろ",
  },
  {
    label: "わ",
    value: "わ",
  },
  {
    label: "を",
    value: "を",
  },
  {
    label: "ん",
    value: "ん",
  },
]

export const benefitPaymentAmount = () => {
  const amounts = Array.from({ length: 32 }, (_, i) => ({
    value: i,
    label: i,
  }))
  return amounts
}

export const DONT_DO_LIST = [
  { label: "Do", value: "1" },
  {
    label: "Do not",
    value: "0",
  },
]

export const YES_NO_LIST = [
  { label: "Yes", value: 1 },
  {
    label: "No",
    value: 2,
  },
]
export const YES_NO_BOOL_LIST = [
  { label: "Yes", value: true },
  {
    label: "No",
    value: false,
  },
]

export const YES_NO_LIST_REVERSED = [
  {
    label: "No",
    value: "0",
  },
  { label: "Yes", value: "1" },
]

export const BURDERN_UPPER_LIMIT_WITH_STRING_VALUE = [
  { label: "0", value: 0 },
  { label: "4,600", value: 4600 },
  { label: "9,300", value: 9300 },
  { label: "18,600", value: 18600 },
  { label: "37,200", value: 37200 },
]

export const ACHIEVEMENT_GOAL_TYPE = [
  { label: "---", value: 0 },
  { label: "achievement", value: 1 },
  { label: "Not achieved", value: 2 },
  { label: "Partially achieved", value: 3 },
  { label: "others", value: 4 },
]

export const EVALUATION_TYPES = [
  { label: "---", value: 0 },
  { label: "continuation", value: 1 },
  { label: "correction", value: 2 },
  { label: "end", value: 3 },
  { label: "others", value: 4 },
]

export const TIME_INTERVALS = [
  {
    label: "06:00",
    value: "06:00",
  },
  {
    label: "06:15",
    value: "06:15",
  },
  {
    label: "06:30",
    value: "06:30",
  },
  {
    label: "06:45",
    value: "06:45",
  },
  {
    label: "07:00",
    value: "07:00",
  },
  {
    label: "07:15",
    value: "07:15",
  },
  {
    label: "07:30",
    value: "07:30",
  },
  {
    label: "07:45",
    value: "07:45",
  },
  {
    label: "08:00",
    value: "08:00",
  },
  {
    label: "08:15",
    value: "08:15",
  },
  {
    label: "08:30",
    value: "08:30",
  },
  {
    label: "08:45",
    value: "08:45",
  },
  {
    label: "09:00",
    value: "09:00",
  },
  {
    label: "09:15",
    value: "09:15",
  },
  {
    label: "09:30",
    value: "09:30",
  },
  {
    label: "09:45",
    value: "09:45",
  },
  {
    label: "10:00",
    value: "10:00",
  },
  {
    label: "10:15",
    value: "10:15",
  },
  {
    label: "10:30",
    value: "10:30",
  },
  {
    label: "10:45",
    value: "10:45",
  },
  {
    label: "11:00",
    value: "11:00",
  },
  {
    label: "11:15",
    value: "11:15",
  },
  {
    label: "11:30",
    value: "11:30",
  },
  {
    label: "11:45",
    value: "11:45",
  },
  {
    label: "12:00",
    value: "12:00",
  },
  {
    label: "12:15",
    value: "12:15",
  },
  {
    label: "12:30",
    value: "12:30",
  },
  {
    label: "12:45",
    value: "12:45",
  },
  {
    label: "13:00",
    value: "13:00",
  },
  {
    label: "13:15",
    value: "13:15",
  },
  {
    label: "13:30",
    value: "13:30",
  },
  {
    label: "13:45",
    value: "13:45",
  },
  {
    label: "14:00",
    value: "14:00",
  },
  {
    label: "14:15",
    value: "14:15",
  },
  {
    label: "14:30",
    value: "14:30",
  },
  {
    label: "14:45",
    value: "14:45",
  },
  {
    label: "15:00",
    value: "15:00",
  },
  {
    label: "15:15",
    value: "15:15",
  },
  {
    label: "15:30",
    value: "15:30",
  },
  {
    label: "15:45",
    value: "15:45",
  },
  {
    label: "16:00",
    value: "16:00",
  },
  {
    label: "16:15",
    value: "16:15",
  },
  {
    label: "16:30",
    value: "16:30",
  },
  {
    label: "16:45",
    value: "16:45",
  },
  {
    label: "17:00",
    value: "17:00",
  },
  {
    label: "17:15",
    value: "17:15",
  },
  {
    label: "17:30",
    value: "17:30",
  },
  {
    label: "17:45",
    value: "17:45",
  },
  {
    label: "18:00",
    value: "18:00",
  },
  {
    label: "18:15",
    value: "18:15",
  },
  {
    label: "18:30",
    value: "18:30",
  },
  {
    label: "18:45",
    value: "18:45",
  },
  {
    label: "19:00",
    value: "19:00",
  },
  {
    label: "19:15",
    value: "19:15",
  },
  {
    label: "19:30",
    value: "19:30",
  },
  {
    label: "19:45",
    value: "19:45",
  },
  {
    label: "20:00",
    value: "20:00",
  },
]
export const BLANK_PLAN_ITEMS = [
  {
    id: new Date().getTime(),
    title: "",
    achievement_goals: [
      {
        id: new Date().getTime(),
        title: "",
      },
    ],
    support_and_considerations: [
      {
        id: new Date().getTime(),
        title: "",
      },
    ],
    achievement_time: [
      {
        id: new Date().getTime(),
        title: "",
      },
    ],
  },
]

export const PLAN_DISPLAY_CONTENT = [
  {
    label: "Consent date",
    value: "1",
  },
  {
    label: "User sign field",
    value: "2",
  },
  {
    label: "Agreement text",
    value: "3",
  },
  {
    label: "Service manager consent text",
    value: "4",
  },
  {
    label: "User seal",
    value: "5",
  },
  {
    label: "Service manager seal",
    value: "6",
  },
  {
    label: "Service manager signature column",
    value: "7",
  },
]
export const CONSENT_DATE_FIELDS = [
  {
    value: "1",
    label: "Separating the explanation date from the consent date",
  },
  {
    value: "2",
    label: "Display and fill in digital signature",
  },
]
export const PARENT_FIELDS = [
  {
    label:
      "Change 'Name of Guardian' to 'Name of Guardian and Family Representative'",
    value: "1",
  },
]

export const PRINT_SETTINGS = [
  {
    value: "1",
    label: "Landscape",
  },
  {
    value: "2",
    label: "Portrait",
  },
]

export const DEFAULT_DATE_SIGNATURE_PAD_IMAGE =
  "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAXwAAABQCAYAAAAa/s4zAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAMvSURBVHgB7d3hMSNhHMfxJzf3ngqogAqogAqogAqogAroQAVUQAVUQAcquPPPDBdkd5MIN/w+nxkzN5e8esZ+d/PfZ2P050kD4Mf71QCIIPgAIQQfIITgA4QQfIAQgg8QQvABQgg+QAjBBwgh+AAhBB8ghOADhBB8gBCCDxBC8AFCCD5ACMEHCCH4ACEEHyCE4AOEEHyAEIIPEELwAUIIPkAIwQcIIfgAIQQfIITgM5PLy8t2d3fXgO9r9OdJgwEbGxvt+Pi47e3tNZbj5OSkPTw8DL7v9PS0raysNIY9Pj6OL05msbu7G7euvxsMODs7G4fp/v5+HKk+29vbbWtrqzHs5uZmHKg6mU5Tr11dXY1PtII/m1qzw8PDtra21vu++n1eX1+P+10VfHrVgXF+ft52dnZeXY1WiCpUbw+sOuCYXa1rBX2aWu9aZ+Y3NH5MPYEKPr0ODg7GUb+4uHj1/xWqGu/s7+834HsQfDo9z5hdZX6eOpHWaGcan5ZYNsGn02g0GgepZvhvo18xqlFE3VCcVO8bmp/yT43F6ubhNLXGR0dHDZZF8On0HJsKT0V8ctZcr9UNr7exEvv5VPC7dj7VpyvBX0yNHHlP8JlJhXxyR0Pd9KpY2ZHzMXbffI6he0tdY7SfTvDhP6mdJPVTu6BYrqHnRWozQiLBZ6rb29uXED1fDU0eJDVu6LrhWKMfo51+FfoaldUadl3l1+sedGOZBJ+pVldXX/49bWzTt4PE7pJh19fX45FY36x5lqdwYR6Cz1R1hd43arAPf3HPD7N1PXDFx9VT4bwn+PDFaptrGRrX+KS0uM3NzcZ7gg9fqO559D1sVSeDySv/+qTlfsj8hr5aoev7i34635bJQuqAqhDZVji/Gul0Rbyu6mtta13rR+zn17e+6QQfIIQ/gAIQQvABQgg+QAjBBwgh+AAhBB8ghOADhBB8gBCCDxBC8AFCCD5ACMEHCCH4ACEEHyCE4AOEEHyAEIIPEELwAUIIPkAIwQcIIfgAIQQfIITgA4QQfIAQgg8QQvABQgg+QAjBBwgh+AAhBB8ghOADhBB8gBCCDxBC8AFCCD5AiL+SlPl4aS2xaQAAAABJRU5ErkJggg=="

export const WEEK_DAYS = [
  {
    label: "Sun",
    value: "0",
    key: "sun",
  },
  {
    label: "Mon",
    value: "1",
    key: "mon",
  },
  {
    label: "Tue",
    value: "2",
    key: "tue",
  },
  {
    label: "Wed",
    value: "3",
    key: "wed",
  },
  {
    label: "Thu",
    value: "4",
    key: "thu",
  },
  {
    label: "Fri",
    value: "5",
    key: "fri",
  },
  {
    label: "Sat",
    value: "6",
    key: "sat",
  },
]
export const YES_NO_OPTION = [
  {
    label: "Yes",
    value: "1",
  },
  { label: "No", value: "0" },
]
export const BUSINESS_REGISTRATION_SUBJECTED_OCCUPATION = [
  {
    label: "Administrator",
    key: "administrator",
    value: "1",
  },
  {
    label: "Child Support Development Manager",
    key: "child_support_development_manager",
    value: "2",
  },
  {
    label: "Child Instructor",
    key: "child_instructor",
    value: "3",
  },
  {
    label: "Child Instructor High Experience",
    key: "child_instructor_high_exp",
    value: "4",
  },
  {
    label: "Child Minder",
    key: "child_minder",
    value: "5",
  },
  {
    label: "Nursery Teacher High Experience",
    key: "nursery_teacher_high_exp",
    value: "6",
  },
  {
    label: "Disabled Welfare Service Experience",
    key: "disabled_welfare_service_experience",
    value: "7",
  },
  {
    label: "Instructor Other",
    key: "instructor_other",
    value: "8",
  },
  {
    label: "Physical Therapist",
    key: "physical_therapist",
    value: "9",
  },
  {
    label: "Occupational Therapist",
    key: "occupational_therapist",
    value: "10",
  },
  {
    label: "Speech Therapist",
    key: "speech_therapist",
    value: "11",
  },
  {
    label: "Psychological Guidance Staff",
    key: "psychological_guidance_staff",
    value: "12",
  },
  {
    label: "Nursing Staff",
    key: "nursing_staff",
    value: "13",
  },
  {
    label: "Visit Support Member",
    key: "visit_support_member",
    value: "14",
  },
  {
    label: "Licensed Psychologist",
    key: "licensed_psychologist",
    value: "15",
  },
  {
    label: "Clinical Psychologist",
    key: "clinical_psychologist",
    value: "16",
  },
  {
    label: "Judo Therapisterapist",
    key: "judo_therapist",
    value: "17",
  },
  {
    label: "Acupuncturist",
    key: "acupuncturist",
    value: "18",
  },
  {
    label: "Anma Massage Shiastsu Therapist",
    key: "anma_massage_shiastsu_therapist",
    value: "19",
  },
  {
    label: "Contract Doctor",
    key: "contract_doctor",
    value: "20",
  },
  {
    label: "Nutritionist",
    key: "nutritionist",
    value: "21",
  },
  {
    label: "Cook",
    key: "cook",
    value: "22",
  },
  {
    label: "Other",
    key: "other",
    value: "23",
  },
]

export const DAYS_OPTION = Array.from({ length: 31 }, (_, idx) => ({
  label: `${idx + 1}`,
  value: `${idx + 1}`,
  key: `${idx + 1}`,
}))
export const BUSINESS_HOURS_INITIAL = {
  from: {
    hours: "",
    minutes: "",
  },
  to: {
    hours: "",
    minutes: "",
  },
  meal: null,
  holiday: "",
}

export const MEDICAL_COLLABORATION_SYSTEM_VALUES = {
  I: "MEDICAL_COLLABORATION",
  II: "MEDICAL_COLLABORATION2",
  III: "MEDICAL_COLLABORATION3",
  IV: "MEDICAL_COLLABORATION4",
  V: "MEDICAL_COLLABORATION5",
  VI: "MEDICAL_COLLABORATION6",
}

export const MEDICAL_COLLABORATION_SERVICE_RECORD_SHEET_DISPLAY = {
  [MEDICAL_COLLABORATION_SYSTEM_VALUES.I]: "1",
  [MEDICAL_COLLABORATION_SYSTEM_VALUES.II]: "2",
  [MEDICAL_COLLABORATION_SYSTEM_VALUES.III]: "3",
  [MEDICAL_COLLABORATION_SYSTEM_VALUES.IV]: "4",
  [MEDICAL_COLLABORATION_SYSTEM_VALUES.V]: "5",
  [MEDICAL_COLLABORATION_SYSTEM_VALUES.VI]: "6",
}

export const MEDICAL_COLLABORATION_SYSTEM = [
  {
    label: "Medical collaboration system I",
    value: MEDICAL_COLLABORATION_SYSTEM_VALUES.I,
  },
  {
    label: "Medical collaboration system II",
    value: MEDICAL_COLLABORATION_SYSTEM_VALUES.II,
  },
  {
    label: "Medical collaboration system III",
    value: MEDICAL_COLLABORATION_SYSTEM_VALUES.III,
  },
  {
    label: "Medical collaboration system IV",
    value: MEDICAL_COLLABORATION_SYSTEM_VALUES.IV,
  },
  {
    label: "Medical collaboration system V",
    value: MEDICAL_COLLABORATION_SYSTEM_VALUES.V,
  },
  {
    label: "Medical collaboration system VI",
    value: MEDICAL_COLLABORATION_SYSTEM_VALUES.VI,
  },
]
export const UPDATED_MEDICAL_COLLABORATION_SYSTEM = [
  {
    label: "none",
    value: "NO",
  },
  ...MEDICAL_COLLABORATION_SYSTEM,
]

export const MONTH_DATE_FORMAT = "YYYY年MM月"
export const YEAR_DATE_FORMAT = "YYYY年MM月"
export const PLAN_DISPLAY_CONTENT_MONITORING = [
  {
    label: "Consent date",
    value: "1",
  },
  {
    label: "Consent description",
    value: "2",
  },
  {
    label: "User sign field",
    value: "3",
  },
  {
    label: "User’s seal",
    value: "4",
  },
  {
    label: "Service manager seal",
    value: "6",
  },
  {
    label: "Service manager signature column",
    value: "5",
  },
]
export const WEEK_DIVISION = [
  {
    label: "1st week",
    value: "1",
  },
  {
    label: "2nd week",
    value: "2",
  },
  {
    label: "3rd week",
    value: "3",
  },
  {
    label: "4th week",
    value: "4",
  },
  {
    label: "5th week",
    value: "5",
  },
  {
    label: "6th week",
    value: "6",
  },
]

export const SHIFT_OPTIONS = [
  {
    label: "Morning",
    value: "1",
  },
  {
    label: "Day",
    value: "2",
  },
  {
    label: "Evening",
    value: "3",
  },
]

export const PICK_DROP_VALUES = {
  HOME: "1",
  OTHERS: "2",
}

export const PICK_DROP_OPTIONS = [
  {
    label: "--",
    value: "",
  },
  {
    label: "One's home",
    value: PICK_DROP_VALUES.HOME,
  },
  {
    label: "Others",
    value: PICK_DROP_VALUES.OTHERS,
  },
]

export const PICK_DROP_INT_VALUES = {
  HOME: 1,
  OTHERS: 2,
}

export const PICK_DROP_OPTIONS_INT_VAL = [
  {
    label: "--",
    value: "",
  },
  {
    label: "One's home",
    value: PICK_DROP_INT_VALUES.HOME,
  },
  {
    label: "Others",
    value: PICK_DROP_INT_VALUES.OTHERS,
  },
]

// employment certificate
export const STATUS_OF_USER = [
  {
    label:
      "Working (up to one job change within one month after leaving the job)",
    value: 1,
  },
  {
    label:
      "Working (with 1 job change after being unemployed for more than a month, or with 2 or more job changes)",
    value: 2,
  },
  {
    label: "Not working",
    value: 3,
  },
  {
    label: "Other",
    value: 4,
  },
]

export const METHOD_OF_CONTACT = [
  {
    label: "Email ",
    value: 1,
  },
  {
    label: "Phone call",
    value: 2,
  },
  {
    label: "Others",
    value: 3,
  },
]

export const CREATE_STATUS = [
  {
    value: "1",
    label: "Created1",
  },
  {
    value: "2",
    label: "Saved as draft",
  },
]
export const JAPANESE_FISCAL_MONTH = [4, 5, 6, 7, 8, 9, 10, 11, 12, 1, 2, 3]

export const FAILURE_TYPES = [
  "Physical disability",
  "Intellectual disability",
  "Mental disability",
  "Developmental disability",
  "Higher brain dysfunction",
  "Target people with incurable diseases",
]

export const ADDITIONAL_ITEM_TYPE_VALUES = {
  YES: "YES",
  NO: "NO",
  REGIONAL_COLLABORATION_MEETING_I: "REGIONAL_COLLABORATION_MEETING_I",
  REGIONAL_COLLABORATION_MEETING_II: "REGIONAL_COLLABORATION_MEETING_II",
}

export const ADDITIONAL_ITEM_YES_NO_OPTIONS = [
  {
    label: "No",
    value: ADDITIONAL_ITEM_TYPE_VALUES.NO,
  },
  {
    label: "Yes",
    value: ADDITIONAL_ITEM_TYPE_VALUES.YES,
  },
]

export const REGIONAL_COLLABORATION_MEETING_IMPLEMENTATION_OPTIONS = [
  {
    label: "NO",
    value: ADDITIONAL_ITEM_TYPE_VALUES.NO,
  },
  {
    label: "Regional collaboration meeting implementation addition(I)",
    value: ADDITIONAL_ITEM_TYPE_VALUES.REGIONAL_COLLABORATION_MEETING_I,
  },
  {
    label: "Regional collaboration meeting implementation addition(II)",
    value: ADDITIONAL_ITEM_TYPE_VALUES.REGIONAL_COLLABORATION_MEETING_II,
  },
]

export const NURSING_STAFF_OPTION = [
  {
    label: "No",
    value: "0",
  },
  {
    label: "Treatment improvement addition ( I )",
    value: "1",
  },
  {
    label: "Treatment improvement addition ( II )",
    value: "2",
  },
  {
    label: "Treatment improvement addition ( III )",
    value: "3",
  },
  {
    label: "Treatment improvement addition ( IV )",
    value: "4",
  },
]

export const NURSING_STAFF_RECORD = {
  "0": "No",
  "1": "Treatment improvement addition ( I )",
  "2": "Treatment improvement addition ( II )",
  "3": "Treatment improvement addition ( III )",
  "4": "Treatment improvement addition ( IV )",
}

export const WAITING_STATUS = 0
export const APPROVED_STATUS = 1
export const REJECTED_STATUS = 2

export const ATTENDANCE_UPDATE_REQUEST = 1
export const PAID_LEAVE_REQUEST = 2
export const showOtherPlace = (placeValue = 0) =>
  placeValue ==
  PICK_DROP_OPTIONS_INT_VAL?.[PICK_DROP_OPTIONS_INT_VAL?.length - 1]?.value

export const NHIF_USER_BILLING_OP_OPTIONS = [
  {
    label: "Print users with a total user charge of 0 yen",
    value: "1",
  },
  {
    label: "Display furigana in the parent name",
    value: "2",
  },
  {
    label: "Display furigana in the User's name",
    value: "3",
  },
  {
    label: "Display Users in phonetic order",
    value: "4",
  },
]

export const NHIF_USER_BILLING_SUMMARY_LIST_OPTIONS = [
  {
    label: "Print users with a total user charge of 0 yen",
    value: "1",
  },
  {
    label: "Print a breakdown of total support costs",
    value: "2",
  },
  {
    label:
      "Convert each addition in the breakdown of the total support cost to a monetary amount",
    value: "3",
  },
  {
    label: "Display Users in phonetic order",
    value: "4",
  },
  {
    label: "Display the decided service code",
    value: "5",
  },
]

export const PRINT_CONFIGURATION_OPTIONS = [
  {
    label: "Print one person on one sheet (A4)",
    value: "1",
  },
  {
    label: "Print two people on one sheet (A4)",
    value: "2",
  },
  {
    label: "Print for 3 people on 1 sheet (A4)",
    value: "3",
  },
]

export const CUSTOMER_INVOICE_DISPLAY_SETTINGS = [
  {
    label: "Service type",
    value: "1",
  },
  {
    label: "Billing breakdown",
    value: "2",
  },
  {
    label: "Actual cost details",
    value: "3",
  },
  {
    label: "Transfer destination",
    value: "4",
  },
  {
    label: "Remarks",
    value: "5",
  },
  {
    label: "Print a copy",
    value: "6",
  },
  {
    label: "Print copy only",
    value: "7",
  },
  {
    label: "Leave the issue date blank",
    value: "12",
  },
  {
    label: "Add child's name to billing name",
    value: "9",
  },
  {
    label: "Change to window envelope layout",
    value: "13",
  },
  {
    label:
      "Display the decided user burden amount and local government subsidy amount billed.",
    value: "8",
  },
  {
    label: "Hide actual cost of 0 yen",
    value: "10",
  },
  {
    label: "Display in phonetic order",
    value: "14",
  },
  {
    label: 'Change the word "disability" to "disability"',
    value: "15",
  },
]

export const PROXY_ACKNOWLEDGEMENT_DISPLAY_SETTINGS = [
  {
    label: "Print copy",
    value: "1",
  },
  {
    label: "Only print copy",
    value: "2",
  },
  {
    label: "Empty the date of receipt",
    value: "3",
  },
  {
    label: "Empty the date of issue",
    value: "4",
  },
  {
    label:
      "Reflect the amount billed by the municipality subsidy to the user fee",
    value: "5",
  },

  {
    label: "Display in Hiragana order",
    value: "6",
  },
  {
    label: `Change the word "disability" to "disability."`,
    value: "7",
  },
]
