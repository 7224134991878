import React, { FC } from "react"
import { Button, Pagination as AntPagination, PaginationProps } from "antd"
import { Wrapper } from "./Pagination.style"
import Arrow from "../../assets/icons/arrow.svg"
interface IPaginationProps extends PaginationProps {
  prevText?: string
  nextText?: string
  currentYear?: number
  removeMt?: boolean
}

const itemRender: PaginationProps["itemRender"] = (
  _,
  type,
  originalElement,
) => {
  if (type === "prev") {
    return (
      <Button
        style={{
          transform: "rotate(180deg)",
        }}
      >
        <Arrow />
      </Button>
    )
  }
  if (type === "next") {
    return (
      <Button>
        <Arrow />
      </Button>
    )
  }
  return originalElement
}

const Pagination: FC<IPaginationProps> = ({
  prevText,
  nextText,
  currentYear,
  ...rest
}) => {
  return (
    <Wrapper {...rest}>
      <div className={"wrapper"}>
        <AntPagination
          showQuickJumper={false}
          showSizeChanger={false}
          itemRender={(_, type, originalElement) => {
            let customElement: any
            let customType: any
            if (type === "prev" && prevText) {
              customElement = <Button>{prevText}</Button>
              customType = "customPrev"
            } else if (type === "next" && nextText) {
              customElement = (
                <Button onClick={() => currentYear + 1}>{nextText}</Button>
              )
              customType = "customNext"
            } else {
              customElement = originalElement
              customType = type
            }
            return itemRender(_, customType, customElement)
          }}
          {...rest}
        />
      </div>
    </Wrapper>
  )
}

export { Pagination }
