// utils
import { keys } from "../../../utils/keys"
import { camelToKebabCase } from "../../../utils/camelToKebabCase"
import { InlineStylesMediaQuery } from "../utils/parseStyleProps"

// types
export interface InlineStylesInput {
  selector: string
  styles?: React.CSSProperties
  media?: InlineStylesMediaQuery[]
}

export function cssObjectToString(css: React.CSSProperties) {
  return keys(css)
    .reduce(
      (acc, rule) =>
        css[rule] !== undefined
          ? `${acc}${camelToKebabCase(rule)}:${css[rule]};`
          : acc,
      "",
    )
    .trim()
}

export function stylesToString({ selector, styles, media }: InlineStylesInput) {
  const baseStyles = styles ? cssObjectToString(styles) : ""
  const mediaQueryStyles = !Array.isArray(media)
    ? []
    : media.map(
        (item) =>
          `@media${item.query}{${selector}{${cssObjectToString(item.styles)}}}`,
      )

  return `${
    baseStyles ? `${selector}{${baseStyles}}` : ""
  }${mediaQueryStyles.join("")}`.trim()
}

export function InlineStyles({ selector, styles, media }: InlineStylesInput) {
  return (
    <style
      data-easyy-styles={"inline"}
      dangerouslySetInnerHTML={{
        __html: stylesToString({ selector, styles, media }),
      }}
    />
  )
}
