import { FormikHelpers } from "formik"

export const setOperationInitialFormikValues = <T extends Record<string, any>>(
  setFieldValue: FormikHelpers<T>["setFieldValue"],
  values: T,
  keys: Array<keyof T>,
) => {
  keys.forEach((key: string) => {
    setFieldValue(key, values[key])
  })

  return values
}
