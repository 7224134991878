// packages
import cuid from "cuid"

// types
import { BreakTime } from "../types"
type RecordWithBreakTime = {
  break_start_time?: string
  break_end_time?: string
  break_start_time2?: string
  break_end_time2?: string
  break_start_time3?: string
  break_end_time3?: string
  break_start_time4?: string
  break_end_time4?: string
  break_start_time5?: string
  break_end_time5?: string
}
/**
 *
 * @param values SingleAttendanceData
 * Data for breakTimes i.e. [new_break_start_time1, new_break_end_time2] comes in a object format
 * but we need these data in array format
 * i.e.
 * ``` 
 * type BreakTime = {
   id: string;
   start_time: string;
  end_time: string; } []
 * ```
 * to work properly with it in front-end hence we are formatting it as required.
 * Later while sending it back as a payload we are required to revert back to its original format.
 */
export const breakTimeRecordToArray = (
  values: RecordWithBreakTime,
): BreakTime[] => {
  const key = cuid()

  const startPrefix = "break_start_time"
  const endPrefix = "break_end_time"

  const breakTimeList = [
    {
      id: key,
      start_time: values[startPrefix],
      end_time: values[endPrefix],
    },
  ]

  for (let i = 2; i <= 5; i++) {
    const uniqueKey = cuid()
    if (values[`${startPrefix}${i}`]) {
      breakTimeList.push({
        id: uniqueKey,
        start_time: values[`${startPrefix}${i}`],
        end_time: values[`${endPrefix}${i}`],
      })
    }
  }
  return breakTimeList
}

/**
 *
 * @param breakTime BreakTime[]
 * @returns Like mentioned above we are required to format these array into object before sending it as payload.
 */
export const revertBreakTimeFormatBack = (breakTime: BreakTime[]) => {
  const totalSetOfBreakTime = breakTime.length

  const startPrefix = "break_start_time"
  const endPrefix = "break_end_time"

  const breakTimeWithValues = breakTime?.reduce((acc, item, index) => {
    const breakNumber = index + 1

    const startTime = item.end_time ? item.start_time : null
    const endTime = item.start_time ? item.end_time : null

    if (breakNumber === 1) {
      return {
        ...acc,
        [startPrefix]: startTime,
        [endPrefix]: endTime,
      }
    }

    return {
      ...acc,
      [`${startPrefix}${breakNumber}`]: startTime,
      [`${endPrefix}${breakNumber}`]: endTime,
    }
  }, {} as any)

  if (totalSetOfBreakTime <= 5) {
    for (let i = totalSetOfBreakTime + 1; i <= 5; i++) {
      breakTimeWithValues[`${startPrefix}${i}`] = null
      breakTimeWithValues[`${endPrefix}${i}`] = null
    }
  }

  return breakTimeWithValues
}
