// types
import { EasyyTheme } from "../../../../theme"

// utils
import { rem } from "../../../../utils/converter"

export function fontSizeResolver(value: unknown, theme: EasyyTheme) {
  if (typeof value === "string" && value in theme.fontSizes) {
    return rem(theme.fontSizes[value as keyof typeof theme.fontSizes])
  }

  if (typeof value === "number") {
    return rem(value)
  }

  if (typeof value === "string") {
    return rem(value)
  }

  return value
}
