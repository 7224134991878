import type { CheckboxGroupProps } from "antd/lib/checkbox/Group"
import { useTranslation } from "react-i18next"
import styled from "styled-components"
import { theme } from "../../theme"
import { Button } from "../Button/Button"
import { CheckBoxGroup } from "../CheckBox/CheckBox"
interface FacilityCheckboxProps extends CheckboxGroupProps {
  showBtn?: boolean
  handleCheckUncheckAll?: (type: "check" | "uncheck") => void
  btnHeight?: string
  [x: string]: any
  checkAllText?: string
  uncheckAllText?: string
}

const StyledFacilityCheckbox = styled.div<any>`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;
  .facilities__btn {
    display: flex;
    gap: 10px;
    @media screen and (max-width: ${theme.breakpoints.xxs}) {
      flex-wrap: wrap;
    }
  }
`
const FacilityCheckbox = ({
  showBtn = true,
  options,
  handleCheckUncheckAll,
  btnHeight,
  onChange,
  checkAllText,
  uncheckAllText,
  ...props
}: FacilityCheckboxProps): JSX.Element => {
  const { t } = useTranslation()

  return (
    <StyledFacilityCheckbox {...props}>
      {showBtn && (
        <div className={"facilities__btn"}>
          <Button
            btnText={checkAllText || t("Check all")}
            shape={"round"}
            iconType={"check-all"}
            borderColor={theme.colors.border}
            onClick={() => handleCheckUncheckAll("check")}
            height={btnHeight}
          />
          <Button
            btnText={uncheckAllText || t("Uncheck all")}
            shape={"round"}
            btnBg={theme.colors.disabled}
            borderColor={theme.colors.border}
            onClick={() => handleCheckUncheckAll("uncheck")}
            height={btnHeight}
          />
        </div>
      )}

      <div className={"facilities__checkbox"}>
        <CheckBoxGroup {...props} items={options} onChange={onChange} />
      </div>
    </StyledFacilityCheckbox>
  )
}
FacilityCheckbox.displayName = "FacilityCheckbox"
export { FacilityCheckbox }
