import React from "react"
import { Tag as AntTag } from "antd"
import { TagProps } from "antd/es/tag"
import styled from "styled-components"
import { ConfigProvider } from "antd/lib"

interface AntTagProps extends TagProps {
  defaultBg?: string
  padding?: string
  borderRadius?: string
  textColor?: string
  borderColor?: string
  align?: "center" | "right" | "left"
  fontSize?: string
  whiteSpace?: "pre" | "pre-line" | "pre-wrap"
}

const StyledTag = styled(AntTag)<AntTagProps>`
  background-color: ${(props) => props?.defaultBg || ""};
  color: ${(props) => props?.textColor || ""};
  padding: ${(props) => props?.padding || ""};
  border-radius: ${(props) => props?.borderRadius || ""};
  border-color: ${(props) => props?.borderColor || ""};
  text-align: ${(props) => props?.align || "center"};
  font-size: ${(props) => props?.fontSize || ""};
  min-width: 40px !important;
  height: fit-content;
  white-space: ${(props) => props?.whiteSpace || "auto"};
`
const Tag = ({ title, ...props }: AntTagProps): JSX.Element => {
  return (
    <ConfigProvider>
      <StyledTag {...props}>{title}</StyledTag>
    </ConfigProvider>
  )
}
Tag.displayname = "Tag"
export { Tag }
