import React from "react"
import styled from "styled-components"
import { theme } from "../../theme"

interface SerialNumberBoxProps {
  number?: number
  text?: string
  radius?: string
  bg?: string
}
const Wrapper = styled.div<SerialNumberBoxProps>`
  display: flex;
  align-items: center;
  column-gap: 5px;
  .sn__box {
    width: 30px;
    height: 30px;
    border-radius: 5px;
    background-color: ${theme.colors.action};
    color: ${theme.colors.white};
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .sn__text {
    ${theme.typography["body-medium"]};
  }
`
const SerialNumberBox = ({ number, text, ...props }: SerialNumberBoxProps) => {
  return (
    <Wrapper {...props}>
      <div className={"sn__box"}>{number}</div>
      {text && <div className={"sn__text"}>{text}</div>}
    </Wrapper>
  )
}
SerialNumberBox.displayName = "SerialNumberBox"
export { SerialNumberBox }
