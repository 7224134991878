import { Typography } from "antd"
import React, { useMemo } from "react"
import { useTranslation } from "react-i18next"
import styled from "styled-components"
import wareki from "wareki"
const StyledWrapper = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
  justify-content: center;
  .left {
    display: flex;
    flex: 0.2;
    justify-content: flex-end;
  }
  .right {
    display: flex;
    flex: 1;
    justify-content: flex-end;
    & > * {
      margin: 0;
    }
  }
`

export const ServiceProvisionSheetTitle = ({
  year,
  month,
  title,
}: {
  year: number
  month: any
  title?: string
}): JSX.Element => {
  const { t } = useTranslation()
  const eraMonth = useMemo(() => {
    const era = wareki(`${year}-${month}`, {
      unit: true,
    })
    return `${era}${month?.toString().padStart(2, "0")}月分`
  }, [year, month])
  return (
    <StyledWrapper>
      <div className={"left"}>{eraMonth}</div>
      <div className={"right"}>
        <Typography.Title level={5}>
          {t(title || "Employment Support Service Performance Record Sheet")}
        </Typography.Title>
      </div>
    </StyledWrapper>
  )
}
