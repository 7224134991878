import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { Button } from "antd"
import dayjs, { Dayjs } from "dayjs"
import arraySupport from "dayjs/plugin/arraySupport"

import { MonthPaginationWrapper } from "./MonthPagination.style"
import ArrowLeft from "../../assets/icons/arrow-left.svg"
import ArrowRight from "../../assets/icons/arrow.svg"

dayjs.extend(arraySupport)
interface ICalendarPagination {
  className?: string
  currentYear: number
  currentMonth: number
  onMonthChange?: (year: number, month: number, date?: Dayjs) => void
  onYearChange?: (year: number) => void
  showDate?: boolean
  disabled?: boolean
  prevText?: string
  currentText?: string
  nextText?: string
  change?: "year" | "month"
  width?: string
}

const MonthPagination: React.FC<ICalendarPagination> = ({
  onMonthChange,
  currentYear,
  currentMonth,
  showDate = true,
  className,
  disabled,
  prevText,
  currentText,
  nextText,
  change = "month",
  onYearChange,
  width,
}): JSX.Element => {
  const date = new Date()
  const { t } = useTranslation()
  const [isActiveText, setIsActiveText] = useState("")
  return (
    <MonthPaginationWrapper className={className} $width={width}>
      <div className={"month-pagination-wrapper"}>
        <Button
          onClick={() => {
            if (change === "month") {
              setIsActiveText("last-month")
              //thi will be remove latter
              let year = currentYear
              let month = currentMonth
              if (month === 1) {
                month = 12
                --year
              } else {
                --month
              }
              const currentDate = dayjs([currentYear, currentMonth]).subtract(
                1,
                "month",
              )
              onMonthChange(year, month, currentDate)
            } else {
              onYearChange(currentYear - 1)
            }
          }}
          className={isActiveText == "last-month" ? "active left-btn" : ""}
          disabled={disabled}
        >
          <ArrowLeft />
          <span className={"left-btn-label"}>
            {prevText || t("Last month")}
          </span>
        </Button>
        <Button
          onClick={() => {
            setIsActiveText("this-month")
            const today = dayjs()
            if (change === "month") {
              const month = today.month() + 1
              const year = today.year()
              onMonthChange(year, month, dayjs())
            } else {
              onYearChange(today.year())
            }
          }}
          className={isActiveText == "this-month" ? `active active-btn` : ""}
          disabled={disabled}
        >
          <span className={"current-btn-label"}>
            {showDate ? (
              <>
                {currentYear === date.getFullYear() &&
                currentMonth === date.getMonth()
                  ? t("This Month")
                  : `${currentYear}年${currentMonth?.toLocaleString("en-US", {
                      minimumIntegerDigits: 2,
                      useGrouping: false,
                    })}月`}
              </>
            ) : (
              <>{currentText || t("This Month")}</>
            )}
          </span>
        </Button>
        <Button
          onClick={() => {
            if (change === "month") {
              setIsActiveText("next-month")
              let year = currentYear
              let month = currentMonth
              if (month == 12) {
                ++year
                month = 1
              } else {
                ++month
              }
              const currentDate = dayjs([currentYear, currentMonth]).add(
                1,
                "month",
              )
              onMonthChange(year, month, currentDate)
            } else {
              onYearChange(currentYear + 1)
            }
          }}
          className={isActiveText == "next-month" ? "active right-btn" : ""}
          disabled={disabled}
        >
          <span className={"right-btn-label"}>
            {nextText || t("Next month")}
          </span>
          <ArrowRight />
        </Button>
      </div>
    </MonthPaginationWrapper>
  )
}

export { MonthPagination }
