import type { CollapseProps } from "antd"
import { Collapse } from "antd"
import styled, { css } from "styled-components"
import { theme } from "../../theme"

interface AccordionCardProps extends CollapseProps {
  padding?: string
  boxShadow?: string
  customBorder?: string
  headerBg?: string
  headerBorderRadius?: string
}
const StyledAccordion = styled(Collapse)<AccordionCardProps>`
  box-shadow: ${(props) =>
    props?.boxShadow || `0px 0px 25px 0px ${theme.colors.shadow}`};
  border-radius: 12px;
  background-color: ${theme.colors.white};
  ${(props) => props?.customBorder && `border:${props?.customBorder};`}
  .ant-collapse-header {
    padding: 12px 20px !important;
    position: relative;
    background-color: ${(props) => props?.headerBg || "transparent"};
    border-radius: 12px 12px 0px 0px !important;
    overflow: hidden;
    .ant-collapse-header-text {
      ${theme.typography.header};
    }
    @media screen and (max-width: ${theme.breakpoints.sm}) {
      padding: 12px 16px !important;
    }
  }
  .ant-collapse-content {
    .ant-collapse-content-box {
      /* giving padding to ant-collapse-content was making animation sluggish */
      padding: ${(props) => `${props.padding || "16px 20px"}`};
      @media screen and (max-width: ${theme.breakpoints.sm}) {
        padding: 10px 16px;
      }
    }
  }
  ${(props) =>
    !props?.bordered &&
    css`
      .ant-collapse-item-active {
        .ant-collapse-header {
          &:after {
            content: " ";
            width: 97%;
            margin: auto;
            position: absolute;
            border-bottom: 1px solid ${theme.colors.border};
            bottom: 0;
            left: 0;
            right: 0;
          }
        }
      }
    `}

  @media only print {
    display: none;
  }
`

const AccordionCard = ({
  expandIconPosition,
  padding,
  bordered = false,
  ghost = true,
  ...props
}: AccordionCardProps): JSX.Element => {
  return (
    <>
      <StyledAccordion
        bordered={bordered}
        ghost={ghost}
        expandIconPosition={expandIconPosition || "end"}
        padding={padding}
        expandIcon={({ isActive }) => (
          <img
            src={"/assets/icons/extra-icon.svg"}
            alt={"icon"}
            style={{
              cursor: "pointer",
              transform: isActive ? "rotate(0deg)" : "rotate(180deg)",
              transition: "all 0.3s",
            }}
          />
        )}
        {...props}
      />
    </>
  )
}
AccordionCard.displayName = "AccordionCard"
export { AccordionCard }
