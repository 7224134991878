import { AnyObject } from "antd/es/_util/type"

export const addClearable = <TObj extends AnyObject>(
  options: Array<TObj>,
): TObj[] => {
  const clearableItem = {
    value: null,
    label: "---",
  } as AnyObject

  if (options) {
    return [clearableItem, ...options]
  }

  return [clearableItem]
}
