import { API, removeBlankAttributes } from "@project/common"
export interface IReturnMunicipalSubsidy {
  count?: number
  data?: any
}
const BaseURL = "municipality-invoice-management"
export interface IMunicipalSubsidyParam {
  page?: string | number
  pageSize?: string
  facility_id?: string
  user?: string
  year?: number
  month?: number
}

export const fetchListOfMunicipalSubsidy = async ({
  queryKey,
}): Promise<IReturnMunicipalSubsidy> => {
  const initialParams = {} as IMunicipalSubsidyParam
  initialParams.page = queryKey[1]
  initialParams.pageSize = queryKey[2] || "20"
  initialParams.facility_id = queryKey[3].facility_id || ""
  initialParams.user = queryKey[3].user || ""
  initialParams.year = queryKey[3].year || ""
  initialParams.month = queryKey[3].month || ""

  const params = removeBlankAttributes(initialParams)
  return API.get(`/${BaseURL}`, {
    params,
  })
}

export const getMunicipalSubsidy = async (id: string | string[]) => {
  return API.get(`/${BaseURL}/${id}`)
}

export const createMunicipalSubsidy = async (values): Promise<any> => {
  delete values?.id
  return API.post(`/${BaseURL}`, values)
}

export const updateMunicipalSubsidy = async (values) => {
  const id = values.id
  delete values.id
  return API.put(`/${BaseURL}/${id}`, values)
}

export const deleteMunicipalSubsidy = async (id) => {
  return API.delete(`/${BaseURL}/${id}`)
}
export const getMunicipalUserById = async (id) => {
  return API.get(`/user/${id}`)
}

export const getAllUsers = async ({
  ...args
}: {
  page: number
  pageSize: string
  facilityIds?: string
}) => {
  const params = removeBlankAttributes(args)
  return API.get(`/user`, { params })
}
