import { API } from "@project/common"
import {
  FacilityWorkData,
  FacilityWorkTableType,
  ManufacturingCostStatementData,
} from "../types"

export const getCostStatements = (params: {
  year: number
  month: number
}): Promise<ManufacturingCostStatementData> =>
  API.get(`/manufacturing-cost-statements/${params.year}/${params.month}`)

export const getFacilityWorkData = (params: {
  year: number
  month: number
  facility_id: number
}): Promise<FacilityWorkData> =>
  API.get(
    `/manufacturing-cost-statements/${params.year}/${params.month}/${params.facility_id}`,
  )

export const mutateFacilityWork = (
  params: {
    year: number
    month: number
    facility_id: number
  },
  payload: Partial<FacilityWorkTableType>[],
) =>
  API.put(
    `/manufacturing-cost-statements/${params.year}/${params.month}/${params.facility_id}`,
    payload,
  )
