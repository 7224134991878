import { API, removeBlankAttributes } from "@project/common"
import { NhifSummaryListResponseType } from "../types"

export const getBillingSummaryList = async (queries: {
  year: number
  month: number
  facility: number
  show_zero_billing_amount?: number
  breakdown_total_support_cost?: number
  sort_by_child_name?: boolean
}): Promise<NhifSummaryListResponseType> => {
  const params = removeBlankAttributes(queries)
  return API.get("/nhif-billings/user-billing-summary", { params })
}
