import { API, removeBlankAttributes } from "@project/common"

export interface IReturnProvisionalCity {
  count?: number
  data?: any
}

export interface IProvisionalCityParam {
  page?: string | number
  pageSize?: number | string
  prefecture?: string
  provision_city_name?: string
}

export const fetchProvisionalCity = async (
  qParams: IProvisionalCityParam,
): Promise<IReturnProvisionalCity> => {
  const params = removeBlankAttributes(qParams)
  return API.get(`/provisional-city`, {
    params,
  })
}

export const fetchAllProvisionalCity =
  async (): Promise<IReturnProvisionalCity> => {
    const params = {} as IProvisionalCityParam
    params.page = 1
    params.pageSize = "Infinity"
    return API.get(`/provisional-city`, {
      params,
    })
  }

export const getProvisionalCity = async (id: string | string[]) => {
  return API.get(`/provisional-city/${id}`)
}

export const createProvisionalCity = async (values): Promise<any> => {
  return API.post(`/provisional-city`, values)
}

export const updateProvisionalCity = async (values) => {
  const id = values.id
  delete values.id
  return API.put(`/provisional-city/${id}`, values)
}
export const deleteProvisionalCity = async (id: string | string[]) => {
  return API.delete(`/provisional-city/${id}`)
}
