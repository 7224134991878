import { API } from "@project/common"

export interface TreatmentAdditionMonthlyData {
  year_month: string
  treatment_support_addition: number
  special_support_addition: number
  base_up_addition: number
  total_addition: number
}

export interface FacilityWiseData {
  facility_id: number
  service_type: 1 | 2 | 3
  facility_name: string
  is_status_management_registered: boolean
  registered_addition_type?: string
  total_addition: number
  monthly_data: (TreatmentAdditionMonthlyData | undefined)[]
}

export interface TreatmentImprovementTableResponse {
  year: string
  total_treatment_support_addition: number
  facility_wise_data: (FacilityWiseData | undefined)[]
}

export interface TreatmentImproveData {
  data: TreatmentImprovementTableResponse
}
export const getTreatmentImprovementList = (params: {
  year: string
  facility_ids?: string
}): Promise<TreatmentImproveData> => {
  return API.get(`/treatment-improvement-addition/${params.year}`, {
    params: {
      facilityIds: params?.facility_ids,
    },
  })
}
