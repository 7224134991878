import React from "react"
import { TimePicker, Typography } from "antd"
import styled from "styled-components"
import { TimePickerProps } from "antd/lib/time-picker"
import dayjs from "dayjs"

interface CustomTimePickerProps extends Omit<TimePickerProps, "onChange"> {
  width?: string
  height?: string
  placeholder?: string
  onChange?: (dateString: string) => void
  onPressEnter?: (e: React.KeyboardEvent<HTMLInputElement>) => void
  error?: string | null
}

interface StyledTimePickerProps extends TimePickerProps {
  width?: string
  height?: string
  onPressEnter?: (e: React.KeyboardEvent<HTMLInputElement>) => void
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`

const StyledTimePicker = styled((props: StyledTimePickerProps) => (
  <TimePicker {...props} />
))<StyledTimePickerProps>`
  width: ${(props) => (props.width ? props.width : "100px")};
  height: ${(props) => (props.height ? props.height : "47px")};
`
const ErrorText = styled(Typography.Text)`
  color: red;
  font-size: 13px;
  display: block;
  margin-top: 4px;
  position: absolute;
  top: 45px;
`

const CustomTimePicker: React.FC<CustomTimePickerProps> = ({
  width,
  height,
  placeholder,
  onChange,
  onPressEnter,
  error,
  ...restProps
}) => {
  const format = "HH:mm"

  const handlePressEnter = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      onPressEnter(e)
      onChange(restProps.value?.format(format) || "")
    }
  }

  const handleChange = (value: dayjs.Dayjs | null, dateString: string) => {
    onChange(dateString)
  }

  return (
    <Container>
      <StyledTimePicker
        {...restProps}
        format={format}
        width={width}
        height={height}
        onChange={handleChange}
        onPressEnter={handlePressEnter}
        placeholder={placeholder}
      />
      {error && <ErrorText>{error}</ErrorText>}
    </Container>
  )
}

CustomTimePicker.displayName = "CustomTimePicker"
export { CustomTimePicker }
