type PermissionType = "write" | "both"
const READPERMISSION = "1"
const WRITEPERMISSION = "2"
export const InstructorMasterPermission: Array<string> = [
  "common_facility_permissions",
  "master_management_permissions",
  "instructor_master",
]

export const ShiftMasterPermission: Array<string> = [
  "common_facility_permissions",
  "master_management_permissions",
  "shift_master",
]

export const ActualCostMasterPermission: Array<string> = [
  "common_facility_permissions",
  "master_management_permissions",
  "actual_cost_items_master",
]

export const ProvisionCityMasterPermission: Array<string> = [
  "common_facility_permissions",
  "master_management_permissions",
  "provision_city_master",
]

export const JointFacilityMasterPermission: Array<string> = [
  "common_facility_permissions",
  "master_management_permissions",
  "joint_facility_master",
]

export const ConsultationSupportMasterPermission: Array<string> = [
  "common_facility_permissions",
  "master_management_permissions",
  "consultation_support_office_master",
]
export const WorkDetailsMasterPermission: Array<string> = [
  "common_facility_permissions",
  "master_management_permissions",
  "work_detail_master",
]
export const ShuttleCarMasterPermission: Array<string> = [
  "common_facility_permissions",
  "master_management_permissions",
  "shuttle_car_master",
]

export const ProgressManagementMasterPermission: Array<string> = [
  "common_facility_permissions",
  "master_management_permissions",
  "progress_management_master",
]

export const NewRegistrationPermission: Array<string> = ["auth_new_delete_flag"]

export const ChildCarePermission: Array<string> = [
  "user_facility_permissions",
  "automatic_info_facilty_permissions",
  "child_care",
]

export const UserAttendanceManagementPermission: Array<string> = [
  "user_facility_permissions",
  "automatic_info_facilty_permissions",
  "attendance_management",
]

export const IndividualSupportPlanPermission: Array<string> = [
  "user_facility_permissions",
  "automatic_info_facilty_permissions",
  "individual_support_plan",
]

export const RecipientCertificatePermission: Array<string> = [
  "user_facility_permissions",
  "automatic_info_facilty_permissions",
  "recepient_certificate",
]

export const ServiceProvisionRecordPermission: Array<string> = [
  "user_facility_permissions",
  "facility_form_management_permissions",
  "service_provision_form",
]

export const UserDailyRecordPermission: Array<string> = [
  "user_facility_permissions",
  "automatic_info_facilty_permissions",
  "user_daily_record",
]

export const MonthActivitiesPermission: Array<string> = [
  "user_facility_permissions",
  "facility_operation_permissions",
  "activity_management",
]

export const FacilityInformationPermission: Array<string> = [
  "user_facility_permissions",
  "facility_operation_permissions",
  "facility_information_history",
]

export const StaffAttendanceRecordPermission: Array<string> = [
  "user_facility_permissions",
  "facility_operation_permissions",
  "attendance_record_table",
]

export const BusinessListPermission: Array<string> = [
  "user_facility_permissions",
  "facility_operation_permissions",
  "business_hour_management",
]

export const DailyBusinessReportPermission: Array<string> = [
  "user_facility_permissions",
  "facility_operation_permissions",
  "daily_business_report",
]

export const FacilityAppManagementPermission: Array<string> = [
  "user_facility_permissions",
  "facility_operation_permissions",
  "facility_application_management",
]

export const ActualCostSchedulePermission: Array<string> = [
  "user_facility_permissions",
  "facility_operation_permissions",
  "actual_cost_burden_schedule",
]
export const AttendanceSchedulePermission: string[] = [
  "user_facility_permissions",
  "facility_operation_permissions",
  "attendance_schedule",
]

export const TransportationManagementPermission: Array<string> = [
  "user_facility_permissions",
  "facility_operation_permissions",
  "transportation_management",
]

export const ProgramRecordPermission: Array<string> = [
  "user_facility_permissions",
  "facility_content_management_permissions",
  "activity_record",
]

export const ServiceProvisionRecordSheetPermission: Array<string> = [
  "user_facility_permissions",
  "facility_form_management_permissions",
  "service_provision_form",
]

export const MinutesAdditionPermission: Array<string> = [
  "user_facility_permissions",
  "facility_form_management_permissions",
  "addition_minutes_form",
]

export const ContractReportPermission: Array<string> = [
  "user_facility_permissions",
  "facility_form_management_permissions",
  "contract_report_form",
]

export const SelfEvalFormPermission: Array<string> = [
  "user_facility_permissions",
  "facility_form_management_permissions",
  "self_eval_form",
]

export const AdditionalItemChildPermission: Array<string> = [
  "user_facility_permissions",
  "facility_billing_management_permissions",
  "additional_children_item_management",
]

export const MaximumAmountManagementResultInformationManagementPermission: Array<string> =
  [
    "user_facility_permissions",
    "facility_billing_management_permissions",
    "cap_intelligence_management",
  ]

export const AdditionalItemFacilityPermission: Array<string> = [
  "user_facility_permissions",
  "facility_billing_management_permissions",
  "additional_facility_management",
]

export const AdditionalItemUserPermission: Array<string> = [
  "user_facility_permissions",
  "facility_billing_management_permissions",
  "additional_user_item_management",
]

export const ActualCostBurdenPermission: Array<string> = [
  "user_facility_permissions",
  "facility_billing_management_permissions",
  "actual_cost_burden_table",
]

export const NotesFromParentsPermission: Array<string> = [
  "user_facility_permissions",
  "facility_my_page_settings_permissions",
  "notes_from_parents",
]

export const ParentManagementPermission: Array<string> = [
  "common_facility_permissions",
  "company_info_permissions",
  "parents_management",
]

export const MunicipalSubsidiaryPermission: Array<string> = [
  "user_facility_permissions",
  "facility_billing_management_permissions",
  "municipal_billing_info_management",
]

export const JointFacilityStatusManagementPermission: Array<string> = [
  "common_facility_permissions",
  "invoice_permissions",
  "joint_facility_management",
]

export const MyPagePermission: Array<string> = [
  "common_facility_permissions",
  "my_page_permissions",
  "my_display_setting",
]

export const FacilityApplicationSettingPermission: Array<string> = [
  "common_facility_permissions",
  "my_page_permissions",
  "facility_application_setting",
]

export const FacilityTourAppManagementPermission: Array<string> = [
  "common_facility_permissions",
  "content_management_permissions",
  "facility_tour_management",
]
export const RecurringSchedulePermission: Array<string> = [
  "common_facility_permissions",
  "company_info_permissions",
  "recurring_schedule",
]

export const MealMasterPermission: Array<string> = [
  "common_facility_permissions",
  "master_management_permissions",
  "meal_master",
]

export const AssessmentMasterPermission: Array<string> = [
  "common_facility_permissions",
  "master_management_permissions",
  "assessment_setting",
]

export const FacilityApplicationListPermission: Array<string> = [
  "common_facility_permissions",
  "company_info_permissions",
  "facility_application_list",
]

export const UserManagementPermission: Array<string> = [
  "user_facility_permissions",
  "automatic_info_facilty_permissions",
  "user_management",
]

export const WageManagementPermission: Array<string> = [
  "user_facility_permissions",
  "facility_operation_permissions",
  "wage_management",
]

export const SystemStatusManagementPermission: Array<string> = [
  "user_facility_permissions",
  "facility_operation_permissions",
  "system_status_management",
]

export const SystemMailSettings: Array<string> = [
  "user_facility_permissions",
  "facility_system_setting_permissions",
  "system_mail_settings",
]

export const MonthlyStatusPermission: Array<string> = [
  "user_facility_permissions",
  "facility_form_management_permissions",
  "monthly_status_form",
]

export const TreatmentImprovementPermission: Array<string> = [
  "user_facility_permissions",
  "facility_form_management_permissions",
  "treatment_improvement_form",
]

export const AdditionMinutePermission: Array<string> = [
  "user_facility_permissions",
  "facility_form_management_permissions",
  "addition_minutes_form",
]

export const RevenueManagementPermission: Array<string> = [
  "user_facility_permissions",
  "facility_billing_management_permissions",
  "revenue_management",
]

export const BillingCSVPermission: Array<string> = [
  "user_facility_permissions",
  "facility_billing_management_permissions",
  "bill_csv_management",
]

export const EmploymentCertificateRegistrtionPermission: Array<string> = [
  "user_facility_permissions",
  "facility_operation_permissions",
  "employment_certificate_registration",
]

// if permission is object then further drill down is necessary
const handleObjectPermission = (currentPermission: any, nextKey: string) => {
  return currentPermission[nextKey]
}
// if permission is array then further drill down is necessary treating each element as object
const handleArrayPermission = (
  currentPermission: Array<any>,
  nextKeys: Array<any>,
  permissionType: PermissionType,
) => {
  let newPermissionObject: any
  for (let i = 0; i < currentPermission.length; i++) {
    newPermissionObject = currentPermission[i]
    if (typeof newPermissionObject === "string")
      return handleStringPermission(newPermissionObject, permissionType)
    else if (typeof newPermissionObject === "object") {
      let currentLoop = 0
      while (
        typeof newPermissionObject === "object" &&
        currentLoop < nextKeys.length
      ) {
        newPermissionObject = handleObjectPermission(
          newPermissionObject,
          nextKeys[currentLoop],
        )
        currentLoop++
      }
      if (
        typeof newPermissionObject === "string" &&
        handleStringPermission(newPermissionObject, permissionType)
      )
        return true
    }
  }
  return false
}
// if value is 1 or 2 permission is set for menu
const handleStringPermission = (
  value: string,
  permissionType: PermissionType,
) => {
  switch (permissionType) {
    case "both":
      return value === READPERMISSION || value === WRITEPERMISSION
    case "write":
      return value === WRITEPERMISSION
  }
  return false
}
const hasPermissionForMenu = (
  permissions: any,
  permissionsForMenu: Array<any>,
  permissionType: PermissionType = "both",
) => {
  if (typeof permissions !== "object" || !permissions) return false
  let permissionList = [],
    loopFor = 1

  if (Array.isArray(permissionsForMenu[0])) {
    loopFor = permissionsForMenu.length
    permissionList = permissionsForMenu
  } else {
    permissionList.push(permissionsForMenu)
  }

  let currentPermissionObject: any
  for (let eachPermission = 0; eachPermission < loopFor; eachPermission++) {
    const permissionKeys = permissionList[eachPermission]
    for (let i = 0; i < permissionKeys.length; i++) {
      const currentPermissionKey = permissions[permissionKeys[i]]
      if (!currentPermissionObject)
        currentPermissionObject = currentPermissionKey

      if (
        typeof currentPermissionObject === "string" &&
        handleStringPermission(currentPermissionObject, permissionType)
      )
        return true
      else if (
        Array.isArray(currentPermissionObject) &&
        handleArrayPermission(
          currentPermissionObject,
          permissionKeys.filter((key, index) => index > i),
          permissionType,
        )
      ) {
        return true
      } else if (typeof currentPermissionObject === "object") {
        currentPermissionObject = handleObjectPermission(
          currentPermissionObject,
          i < permissionKeys.length ? permissionKeys[i + 1] : "",
        )
      }
    }
  }
  return false
}
export const hasPermissionForFacility = (
  permissions: any,
  permissionsForMenu: Array<any>,
  permissionType: PermissionType = "both",
  facilityId: number | string,
) => {
  const facilityPermissions = permissions?.user_facility_permissions?.find(
    (facilityPermission) => facilityPermission?.facility_id == facilityId,
  )

  if (!facilityPermissions) return false

  return hasPermissionForMenu(
    { user_facility_permissions: [facilityPermissions] },
    permissionsForMenu,
    permissionType,
  )
}
