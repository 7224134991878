import styled from "styled-components"
import { theme } from "../../theme"

export const LayoutWrapper = styled.div`
  .ant-layout {
    background: ${theme.colors.background};
  }
  #site__sidebar {
    background-color: ${theme.colors.white} !important;
  }

  .main__container {
    display: flex !important;
    flex-direction: column !important;
    min-height: 100vh;

    &--content {
      overflow: initial;
      flex: 1;
    }
    &--footer {
      background-color: ${theme.colors.white};
    }
  }

  @media print {
    .print-exclude {
      display: none;
    }
    .ant-layout {
      margin-left: 0 !important;
      background: ${theme.colors.white};
    }
    .main__container {
      &--content {
        margin: 0 !important;
      }
    }
  }
`
