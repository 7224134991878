import { API } from "@project/common"

export const getAllAssessmentSettings = () => {
  return API.get("/assessment-setting")
}
export const createOrUpdateAssessmentSetting = ({
  values,
  settingId,
}: {
  values: any
  settingId?: number
}) => {
  return API.post(`/assessment-setting/${settingId}`, values)
}
