import axios from "axios"
import * as Sentry from "@sentry/node"
import { auth } from "./firebase"

axios.defaults.responseType = "json"

const API = axios.create({
  baseURL: process.env.NEXT_PUBLIC_APP_ADULT_API_URL,
})

API.interceptors.request.use(
  async (config) => {
    if (typeof sessionStorage !== "undefined") {
      if (sessionStorage.getItem("X-Agent-User")) {
        config.headers["X-Agent-User"] = sessionStorage?.getItem("X-Agent-User")
      }
    }
    const token = await auth.currentUser?.getIdToken(true)

    if (token) {
      config.headers["Authorization"] = "Bearer " + token
    }
    const idTokenResult = await auth.currentUser?.getIdTokenResult(true)

    config.headers["Company-ID"] =
      typeof idTokenResult.claims.companyId === "object"
        ? JSON.stringify(idTokenResult.claims.companyId)
        : idTokenResult.claims.companyId

    if (idTokenResult?.claims?.company_adult) {
      config.headers["Adult-ID"] =
        typeof idTokenResult.claims.adultId === "object"
          ? JSON.stringify(idTokenResult.claims.adultId)
          : idTokenResult.claims.adultId
    }
    return config
  },
  (error) => {
    return Promise.reject(error)
  },
)

API.interceptors.response.use(
  (response) => {
    return response?.data
  },
  (error) => {
    if (error?.response?.status === 401) {
      auth.signOut()
    }
    Sentry.captureException(error)
    return Promise.reject(error?.response)
  },
)

export { API }
