import { API, removeBlankAttributes } from "@project/common"
import dayjs from "dayjs"
import {
  MealAmountPerUserDetailParams,
  MealAmountPerUserParams,
  MealInfoField,
  MealMasterFormField,
} from "../types"
export interface IMealInformation {
  page?: string | number
  month?: number
  year?: number
  user_id?: number
  facility_id?: any
  facility?: any
  pageSize?: number
}
export const createMeal = async (values: MealMasterFormField) =>
  API.post("/meal", values)
export const getAllMealMaster = async ({
  page,
  pageSize,
  facilityIds = "",
}: {
  page: number
  pageSize: number | string
  facilityIds?: string
}) =>
  API.get(`/meal?page=${page}&pageSize=${pageSize}&facilityIds=${facilityIds}`)

export const getSingleMealMaster = async (id: any) => API.get(`/meal/${id}`)
export const updateMealMaster = async ({
  id,
  ...values
}: MealMasterFormField) => API.put(`/meal/${id}`, values)

//meal information (amount per use)
export const getMealsInformation = async ({
  queryKey,
}): Promise<MealAmountPerUserParams> => {
  const params = {} as IMealInformation
  params.page = queryKey[1]
  params.pageSize = 20
  params.month = dayjs(queryKey[2]?.date).month() + 1
  params.year = dayjs(queryKey[2]?.date).year()
  params.user_id = queryKey[2]?.userId
  params.facility = queryKey[2]?.facilityId
  return API.get(`/meal-information/user-expenses`, { params })
}

export const updateMealInformation = async (rest: MealInfoField[]) =>
  API.post(`/meal-information`, rest)

export const getMealDetailInformation = async ({
  userId,
  ...values
}: MealAmountPerUserDetailParams) => {
  const params = removeBlankAttributes(values)
  return API.get(`/meal-information/user-expenses/${userId}`, {
    params,
  })
}

export const deleteMealMaster = async (id) => API.delete(`/meal/${id}`)
