import { API, removeBlankAttributes } from "@project/common"

export const getPrintUserBillingList = async (queries: {
  year: number
  month: number
  facilityIds?: string
  display_settings?: string
  show_zero_invoice_total?: number
}) => {
  const params = removeBlankAttributes(queries)
  return API.get(`/nhif-billings/user-billing-list-by-provision`, { params })
}
