import { keys } from "./keys"

type FilterPropsRes<T extends Record<string, any>> = {
  [Key in keyof T]-?: T[Key] extends undefined ? never : T[Key]
}

export const filterObject = <T extends Record<string, any>>(data: T) => {
  return keys(data).reduce((acc, key: keyof T) => {
    if (data[key] !== null && data[key] !== "" && data[key] !== undefined) {
      acc[key] = data[key]
    }
    return acc
  }, {} as FilterPropsRes<T>)
}

/**
 *
 * @param obj normal object
 * @param picked Array of `object's key` that you wanna pick from `obj` and create a new one
 *
 * @returns New Object that has only those properties who's `keys` were included in `picked`
 */
export const pick = <TObj, TPickedKey extends keyof TObj>(
  obj: TObj,
  picked: TPickedKey[],
) => {
  return picked.reduce(
    (acc, key) => {
      acc[key] = obj[key]
      return acc
    },
    {} as Pick<TObj, TPickedKey>,
  )
}

/**
 *
 * @param obj normal object
 * @param omitted Array of `object's key` that you wanna omit from `obj` and create a new one
 *
 * @returns New Object that has all the properties excluding `keys` included in `omitted` param.
 */

export function omit<TObj extends object, TOmitKey extends keyof TObj>(
  obj: TObj,
  omitted: Array<TOmitKey> | Array<string>,
) {
  const allObjKeys = keys(obj)
  return allObjKeys.reduce(
    (acc, key) => {
      if (omitted.includes(key as any)) {
        return acc
      }
      acc[key as string] = obj[key]
      return acc
    },
    {} as Omit<TObj, TOmitKey>,
  )
}
