import { API } from "@project/common"

export interface IReturnUserActivity {
  count?: number
  data?: any
}

export interface IUserActivityParam {
  page?: string | number
  pageSize?: string
  facility_ids?: string
  from_date?: string
  to_date?: string
}
const BASE_ENDPOINT = "program-record"
export const fetchUserActivity = async ({
  queryKey,
}): Promise<IReturnUserActivity> => {
  const params = {} as IUserActivityParam
  params.page = queryKey[1]
  params.pageSize = queryKey[2] || "20"
  params.facility_ids = queryKey[3].facility_ids || ""
  params.from_date = queryKey[3].from_date || ""
  params.to_date = queryKey[3].to_date || ""
  return API.get(`/${BASE_ENDPOINT}`, {
    params,
  })
}

export const fetchAllUserActivity = async (): Promise<IReturnUserActivity> => {
  const params = {} as IUserActivityParam
  params.page = 1
  params.pageSize = "Infinity"
  return API.get(`/${BASE_ENDPOINT}`, {
    params,
  })
}

export const getUserActivity = async (id: string | string[]) => {
  return API.get(`/${BASE_ENDPOINT}/${id}`)
}

export const createUserActivity = async (values): Promise<any> => {
  delete values?.id
  return API.post(`/${BASE_ENDPOINT}`, values)
}

export const updateUserActivity = async (values) => {
  const id = values.id
  delete values.id
  return API.put(`/${BASE_ENDPOINT}/${id}`, values)
}
export const deleteUserActivity = async (id) => {
  return API.delete(`/${BASE_ENDPOINT}/${id}`)
}
export const releaseProgramRecord = (id) => {
  return API.patch(`/${BASE_ENDPOINT}/${id}/release`)
}
