import { API, removeBlankAttributes } from "@project/common"
import {
  StaffBasicInfoValues,
  StaffMasterListResponse,
  StaffResponse,
} from "../types/staffMaster.types"

export const addNewStaffMaster = (values: StaffBasicInfoValues) => {
  return API.post("/instructors", { ...values })
}
export const fetchInstructors = ({
  ...args
}: {
  page?: number
  pageSize?: number | string
  keyword?: string
  retirement_date?: string
  joining_date?: string
  facility?: string
  occupation?: string
  date_staff_is_active?: string
  work_style?: string
}): Promise<StaffMasterListResponse> => {
  const params: any = removeBlankAttributes(args)
  if (args.facility) {
    // for multiple facility search use facilityIds
    params.facilityIds = args.facility
    delete params.facility
  }
  return API.get(`/instructors`, {
    params,
  })
}

export const getOneInstructor = (
  id: string,
): Promise<{ data: StaffResponse }> => API.get(`/instructors/${id}`)

export const createInstructorInfo = (values: any) =>
  API.post("/instructors/occupation-facility", { ...values })

export const updateInstructor = ({ values, id }: { values: any; id: string }) =>
  API.put(`/instructors/${id}`, { ...values })

export const getOccupations = async () => API.get(`/occupations`)

export const getQualification = () =>
  API.get("/qualifications?page=1&pageSize=Infinity")

export const GetShiftMasterNames = (facilityId: string) => {
  let url = "/attendance-shift"
  if (facilityId) url = `${url}?keyword=${facilityId}&page=1&pageSize=Infinity`
  return API.get(url)
}

export const deleteOneInstructor = (id: string) =>
  API.delete(`/instructors/${id}`)
