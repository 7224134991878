// themes
import { theme } from "../../theme"

// styles
import styled from "styled-components"

export const PrintFriendlyTableStyle = styled.div<{
  $headerBg?: string
  $border?: string
  $visibleInScreenMode?: boolean
  $padding?: string
  $fontSize?: string
}>`
  table {
    border-spacing: 0;
    border-collapse: collapse;
    width: 100%;
    empty-cells: show;
    break-before: avoid;
    -webkit-print-color-adjust: exact !important;
    print-color-adjust: exact !important;
  }

  table > thead > tr > th,
  table > tbody > tr > td,
  table > tfoot > tr > td,
  table > tfoot > tr > th,
  table > thead > tr > td,
  table > tbody > tr > th {
    font-size: ${({ $fontSize }) => $fontSize || ` 8pt`};
    padding: ${({ $padding }) => $padding || `0.5rem`};
    border: ${({ $border }) => $border || `1px solid ${theme.colors.text}`};
    color: ${theme.colors.text};
    font-weight: normal;
    & > * {
      font-size: ${({ $fontSize }) => $fontSize || ` 8pt`};
      font-weight: normal;
    }
  }

  table > thead > tr > th {
    background-color: ${({ $headerBg }) => $headerBg || theme.colors.border};
  }

  table > tbody > tr > td {
    position: relative;
  }
  @media screen {
    display: ${({ $visibleInScreenMode }) =>
      $visibleInScreenMode ? "block" : "none"};
  }

  @media print {
    display: block;
  }

  @media not print {
    .hide-not-print {
      display: none;
    }
  }
`
