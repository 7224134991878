import { Space } from "antd"

import dayjs from "dayjs"
import { useMemo } from "react"
import { useTranslation } from "react-i18next"
import styled from "styled-components"
import {
  generateJapaneseEraAndYears,
  generateMonthsAccordingToEra,
} from "../../utils/dateOption"
import { SelectInput } from "../SelectInput/SelectInput"

const StyledSpace = styled(Space)`
  .selector__container {
    display: flex;
    align-items: center;
    gap: 4px;
    .dob_selector {
      .ant-select-selector {
        border-radius: 6px !important;
      }
      margin-right: 4px;
    }
  }
`

const generateMonthDays = (year, month) => {
  const totalDays = +dayjs(`${year}-${month}`, "YYYY-MM").daysInMonth() || 31
  const days = []
  for (let index = 0; index <= totalDays - 1; index++) {
    days?.push({
      label: index + 1,
      value: `0${index + 1}`.slice(-2),
    })
  }
  return days
}
interface BirthdayPickerProps {
  year?: number | string | null
  month?: number | string | null
  day?: number | string | null
  onChange: (key: string, value: string | number, date?: dayjs.Dayjs) => void
  [x: string]: any
}
export const BirthdayPicker = ({
  month,
  year,
  day,
  onChange,
  ...rest
}: BirthdayPickerProps) => {
  const { t } = useTranslation()
  const eraYears = useMemo(() => generateJapaneseEraAndYears(1944), [])
  const months = useMemo(
    () => generateMonthsAccordingToEra(+year, +month),
    [year, month],
  )

  const getYearValue = (y: number, m: number, d?: number) => {
    if (y === 2019) {
      if (m <= 4) {
        return `2019-1`
      }
      if (m >= 5) {
        return `2019-2`
      }
    } else if (y === 1989) {
      if (d <= 7) {
        return `1989-1`
      }
      if (d > 7) {
        return `1989-2`
      }
    }
    return `${y}`
  }
  return (
    <StyledSpace wrap>
      <div className={"selector__container"}>
        <SelectInput
          value={year ? getYearValue(+year, +month, +day || 1) : ""}
          onChange={(value) => {
            const newYear = value?.split("-")
            const m = +newYear[0] === 2019 ? (+newYear[1] === 2 ? 5 : 1) : month
            const newDay =
              +newYear[0] === 1989 ? (+newYear[1] === 2 ? 8 : 1) : day
            onChange(
              "date_of_birth_year",
              newYear[0],
              dayjs(`${newYear[0]}-${m}-${newDay}`),
            )
          }}
          style={{ width: "104px" }}
          className={"dob_selector"}
          size={"large"}
          options={eraYears || []}
          name={"date_of_birth_year"}
          showSearch
          optionFilterProp={"children"}
          filterOption={(input, option) =>
            (String(option?.label) ?? "").includes(input)
          }
          {...rest}
        />
        <span> {t("年")}</span>
      </div>

      <div className={"selector__container"}>
        <SelectInput
          value={month}
          onChange={(value: string) => {
            const date = dayjs(`${year}-${value}-${day}`)
            onChange("date_of_birth_month", value, date)
          }}
          style={{ width: "60px" }}
          className={"dob_selector"}
          size={"large"}
          options={months}
          showSearch
          name={"date_of_birth_month"}
          {...rest}
        />
        <span>{t("月")}</span>
      </div>
      <div className={"selector__container"}>
        <SelectInput
          value={day}
          onChange={(value) => {
            const date = dayjs(`${year}-${month}-${value}`)
            onChange("date_of_birth_day", value, date)
          }}
          style={{ width: "60px" }}
          className={"dob_selector"}
          size={"large"}
          name={"date_of_birth_day"}
          options={generateMonthDays(year, month)}
          showSearch
          {...rest}
        />
        <span>{t("日")}</span>
      </div>
    </StyledSpace>
  )
}
