import { API, removeBlankAttributes } from "@project/common"
interface IMinutesQuery {
  page?: string | number
  pageSize?: string
  facility?: any
  user_id?: string | number
  start_date?: string | number
  end_date?: string | number
  created?: boolean
  saved_as_draft?: boolean
}
export const fetchMinutesOfMeetingOfPic = ({ queryKey }): Promise<Response> => {
  const params = {} as IMinutesQuery
  params.page = queryKey[1]
  params.pageSize = queryKey[2] || "20"
  params.facility = queryKey[3].facility || []
  params.user_id = queryKey[3].user_id || ""
  params.start_date = queryKey[3].start_date || ""
  params.end_date = queryKey[3].end_date || ""
  params.created = queryKey[3].created || false
  params.saved_as_draft = queryKey[3].saved_as_draft || false
  const query = removeBlankAttributes(params)
  return API.get(`/minutes`, {
    params: query,
  })
}
export const createMinutesOfMeetingOfPic = async (values): Promise<any> => {
  delete values.absent_facility
  delete values.conference_participation_facilities
  delete values.creator_id
  delete values.conference
  return API.post(`/minutes`, values)
}
export const getOneMinutesOfMeetingOfPic = async (id: number) => {
  return API.get(`/minutes/${id}`)
}

export const updateMinutesOfMeetingOfPic = async (values) => {
  delete values.absent_facility
  delete values.conference_participation_facilities
  delete values.creator_id
  delete values.conference
  const id = values.id
  delete values.id
  return API.put(`/minutes/${id}`, values)
}
export const getAllUser = async ({
  ...args
}: {
  page: number
  pageSize: string
  service_used_by_user?: number
}) => {
  const params = removeBlankAttributes(args)
  return API.get(`/user`, { params })
}

export const getActiveUser = async (id) => {
  return API.get(`/user/${id}`)
}
export const getFacility = async () => {
  return API.get(`/facilities`)
}

export const getMinutesNumberOfCreated = (id: string) => {
  return API.get(`/minutes/no_of_created/${id}`)
}

export const getCreatorAndConferenceParticipants = (
  values: any,
): Promise<any> => {
  if (Number(values.queryKey[1]) > 0)
    return API.get(
      `/instructors/facility-staffs?facilityId=${values.queryKey[1]}&page=1&pageSize=Infinity`,
    )
}

export const deleteMinutes = (id) => {
  return API.delete(`/minutes/${id}`)
}
