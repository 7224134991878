import React from "react"
import { Flex, Typography } from "antd"
import { ModalProps } from "antd/es/modal"
import { useTranslation } from "react-i18next"
import { Modal } from "./Modal"
import { Button } from "../Button/Button"
import { theme } from "../../theme"

interface ConfirmationModalProps extends ModalProps {
  radius?: number
  bgColor?: string
  btnRightMargin?: string
  msg?: string
  isLoading?: boolean
  isOkButtonText?: string
}
const ConfirmationModal = ({
  onCancel,
  onOk,
  msg,
  isLoading,
  isOkButtonText,
  ...rest
}: ConfirmationModalProps): JSX.Element => {
  const { t } = useTranslation()
  return (
    <Modal
      {...rest}
      onCancel={onCancel}
      onOk={onOk}
      footer={[
        <Flex
          key={"delete-footer"}
          justify={"flex-end"}
          gap={16}
          wrap={"wrap"}
          style={{
            padding: "8px",
          }}
        >
          <Button
            btnText={t("Cancel")}
            borderColor={theme.colors.border}
            hoverBorderColor={theme.colors.border}
            hoverTextColor={theme.colors.border}
            shape={"round"}
            btnBg={"transparent"}
            onClick={onCancel}
            color={theme.colors.text}
            disabled={isLoading}
          />
          <Button
            btnText={isOkButtonText ? t(isOkButtonText) : t("Delete")}
            hoverBorderColor={theme.colors["error-light"]}
            borderColor={theme.colors.error}
            btnBg={theme.colors.error}
            textColor={theme.colors.border}
            hoverTextColor={theme.colors.white}
            onClick={onOk}
            isLoading={isLoading}
            shape={"round"}
          />
        </Flex>,
      ]}
      bodyPadding={"0px"}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          padding: "20px",
        }}
      >
        <Typography.Title level={4}>
          {msg ? msg : t("Are you sure to delete?")}
        </Typography.Title>
      </div>
    </Modal>
  )
}

export { ConfirmationModal }
