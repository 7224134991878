import {
  ActualCostBurdenPermission,
  ActualCostMasterPermission,
  ActualCostSchedulePermission,
  AdditionalItemFacilityPermission,
  AdditionalItemUserPermission,
  AdditionMinutePermission,
  AssessmentMasterPermission,
  AttendanceSchedulePermission,
  BillingCSVPermission,
  BusinessListPermission,
  ConsultationSupportMasterPermission,
  DailyBusinessReportPermission,
  EmploymentCertificateRegistrtionPermission,
  FacilityApplicationListPermission,
  FacilityAppManagementPermission,
  FacilityInformationPermission,
  IndividualSupportPlanPermission,
  InstructorMasterPermission,
  JointFacilityMasterPermission,
  MealMasterPermission,
  MonthlyStatusPermission,
  MunicipalSubsidiaryPermission,
  ProgramRecordPermission,
  ProgressManagementMasterPermission,
  ProvisionCityMasterPermission,
  RecipientCertificatePermission,
  RecurringSchedulePermission,
  RevenueManagementPermission,
  ServiceProvisionRecordPermission,
  ShiftMasterPermission,
  ShuttleCarMasterPermission,
  StaffAttendanceRecordPermission,
  SystemMailSettings,
  SystemStatusManagementPermission,
  TransportationManagementPermission,
  TreatmentImprovementPermission,
  UserAttendanceManagementPermission,
  UserDailyRecordPermission,
  UserManagementPermission,
  WageManagementPermission,
  WorkDetailsMasterPermission,
} from "./PermissionKeys"
import { hasPermissionForMenu } from "./sidebarUtils"

type PermissionType = "write" | "both"

interface IPageObj {
  routeLike: string
  skipPermissions?: boolean
  premissionType?: PermissionType
  permissionKeys?: Array<any>
  commonToAllFacilities: boolean
}

export const PageObj: Array<IPageObj> = [
  {
    routeLike: "/staff-master",
    premissionType: "both",
    permissionKeys: InstructorMasterPermission,
    commonToAllFacilities: true,
  },
  {
    routeLike: "/staff-master/add",
    premissionType: "write",
    permissionKeys: InstructorMasterPermission,
    commonToAllFacilities: true,
  },
  {
    routeLike: "/staff-master/edit/[id]",
    premissionType: "write",
    permissionKeys: InstructorMasterPermission,
    commonToAllFacilities: true,
  },
  {
    routeLike: "/shift-master",
    premissionType: "both",
    permissionKeys: ShiftMasterPermission,
    commonToAllFacilities: true,
  },
  {
    routeLike: "/shift-master/add",
    premissionType: "write",
    permissionKeys: ShiftMasterPermission,
    commonToAllFacilities: true,
  },
  {
    routeLike: "/shift-master/edit/[id]",
    premissionType: "write",
    permissionKeys: ShiftMasterPermission,
    commonToAllFacilities: true,
  },
  {
    routeLike: "/shift-user-master",
    premissionType: "both",
    permissionKeys: ShiftMasterPermission,
    commonToAllFacilities: true,
  },
  {
    routeLike: "/shift-user-master/add",
    premissionType: "write",
    permissionKeys: ShiftMasterPermission,
    commonToAllFacilities: true,
  },
  {
    routeLike: "/shift-user-master/edit/[id]",
    premissionType: "write",
    permissionKeys: ShiftMasterPermission,
    commonToAllFacilities: true,
  },
  {
    routeLike: "/work-details-master",
    premissionType: "both",
    permissionKeys: WorkDetailsMasterPermission,
    commonToAllFacilities: true,
  },
  {
    routeLike: "/work-details-master/add",
    premissionType: "write",
    permissionKeys: WorkDetailsMasterPermission,
    commonToAllFacilities: true,
  },
  {
    routeLike: "/work-details-master/edit/[id]",
    premissionType: "write",
    permissionKeys: WorkDetailsMasterPermission,
    commonToAllFacilities: true,
  },
  {
    routeLike: "/meal-master",
    premissionType: "both",
    permissionKeys: MealMasterPermission,
    commonToAllFacilities: true,
  },
  {
    routeLike: "/meal-master/add",
    premissionType: "write",
    permissionKeys: MealMasterPermission,
    commonToAllFacilities: true,
  },
  {
    routeLike: "/meal-master/edit/[id]",
    premissionType: "write",
    permissionKeys: MealMasterPermission,
    commonToAllFacilities: true,
  },
  {
    routeLike: "/meal-master/detail/[userId]",
    premissionType: "both",
    permissionKeys: MealMasterPermission,
    commonToAllFacilities: true,
  },
  {
    routeLike: "/actual-items-master",
    premissionType: "both",
    permissionKeys: ActualCostMasterPermission,
    commonToAllFacilities: true,
  },
  {
    routeLike: "/actual-items-master/add",
    premissionType: "write",
    permissionKeys: ActualCostMasterPermission,
    commonToAllFacilities: true,
  },
  {
    routeLike: "/actual-items-master/edit/[id]",
    premissionType: "write",
    permissionKeys: ActualCostMasterPermission,
    commonToAllFacilities: true,
  },
  {
    routeLike: "/provisional-cities",
    premissionType: "both",
    permissionKeys: ProvisionCityMasterPermission,
    commonToAllFacilities: true,
  },
  {
    routeLike: "/provisional-cities/add",
    premissionType: "write",
    permissionKeys: ProvisionCityMasterPermission,
    commonToAllFacilities: true,
  },
  {
    routeLike: "/provisional-cities/edit/[id]",
    premissionType: "write",
    permissionKeys: ProvisionCityMasterPermission,
    commonToAllFacilities: true,
  },
  {
    routeLike: "/progress-management",
    premissionType: "both",
    permissionKeys: ProgressManagementMasterPermission,
    commonToAllFacilities: true,
  },
  {
    routeLike: "/progress-management/add",
    premissionType: "write",
    permissionKeys: ProgressManagementMasterPermission,
    commonToAllFacilities: true,
  },
  {
    routeLike: "/progress-management/edit/[id]",
    premissionType: "write",
    permissionKeys: ProgressManagementMasterPermission,
    commonToAllFacilities: true,
  },
  {
    routeLike: "/joint-facility",
    premissionType: "both",
    permissionKeys: JointFacilityMasterPermission,
    commonToAllFacilities: true,
  },
  {
    routeLike: "/joint-facility/add",
    premissionType: "write",
    permissionKeys: JointFacilityMasterPermission,
    commonToAllFacilities: true,
  },
  {
    routeLike: "/joint-facility/edit/[id]",
    premissionType: "write",
    permissionKeys: JointFacilityMasterPermission,
    commonToAllFacilities: true,
  },
  {
    routeLike: "/consultation-support-office-master",
    premissionType: "both",
    permissionKeys: ConsultationSupportMasterPermission,
    commonToAllFacilities: true,
  },
  {
    routeLike: "/consultation-support-office-master/add",
    premissionType: "write",
    permissionKeys: ConsultationSupportMasterPermission,
    commonToAllFacilities: true,
  },
  {
    routeLike: "/consultation-support-office-master/edit/[id]",
    premissionType: "write",
    permissionKeys: ConsultationSupportMasterPermission,
    commonToAllFacilities: true,
  },
  {
    routeLike: "/shuttle-master",
    premissionType: "both",
    permissionKeys: ShuttleCarMasterPermission,
    commonToAllFacilities: true,
  },
  {
    routeLike: "/shuttle-master/add",
    premissionType: "write",
    permissionKeys: ShuttleCarMasterPermission,
    commonToAllFacilities: true,
  },
  {
    routeLike: "/shuttle-master/edit/[id]",
    premissionType: "write",
    permissionKeys: ShuttleCarMasterPermission,
    commonToAllFacilities: true,
  },
  {
    routeLike: "/assessment-master",
    premissionType: "both",
    permissionKeys: AssessmentMasterPermission,
    commonToAllFacilities: true,
  },
  {
    routeLike: "/users/profile/[user_id]/recurring-schedule-setting-list",
    premissionType: "both",
    permissionKeys: RecurringSchedulePermission,
    commonToAllFacilities: true,
  },
  {
    routeLike:
      "/users/profile/[user_id]/recurring-schedule-setting-list/change-form",
    premissionType: "write",
    permissionKeys: RecurringSchedulePermission,
    commonToAllFacilities: true,
  },
  {
    routeLike: "/user-facility-schedule-registration-status",
    premissionType: "both",
    permissionKeys: FacilityApplicationListPermission,
    commonToAllFacilities: true,
  },
  {
    routeLike: "/users",
    premissionType: "both",
    permissionKeys: UserManagementPermission,
    commonToAllFacilities: false,
  },
  {
    routeLike: "/users/profile/[user_id]",
    premissionType: "both",
    permissionKeys: UserManagementPermission,
    commonToAllFacilities: false,
  },
  {
    routeLike: "/users/information/[id]",
    premissionType: "both",
    permissionKeys: UserManagementPermission,
    commonToAllFacilities: false,
  },
  {
    routeLike: "/users/edit/[id]",
    premissionType: "write",
    permissionKeys: UserManagementPermission,
    commonToAllFacilities: false,
  },

  {
    routeLike: "/users/profile/[user_id]/recipient-certificate-form",
    premissionType: "both",
    permissionKeys: RecipientCertificatePermission,
    commonToAllFacilities: false,
  },
  {
    routeLike: "/users/profile/[user_id]/recipient-certificate-form/add",
    premissionType: "write",
    permissionKeys: RecipientCertificatePermission,
    commonToAllFacilities: false,
  },
  {
    routeLike: "/users/profile/[user_id]/recipient-certificate-form/edit/[id]",
    premissionType: "write",
    permissionKeys: RecipientCertificatePermission,
    commonToAllFacilities: false,
  },
  {
    routeLike: "/list-of-users",
    premissionType: "both",
    permissionKeys: RecipientCertificatePermission,
    commonToAllFacilities: false,
  },
  {
    routeLike: "/user-service-record",
    premissionType: "both",
    permissionKeys: ServiceProvisionRecordPermission,
    commonToAllFacilities: false,
  },
  {
    routeLike: "/user-service-record/details/[us_id]",
    premissionType: "both",
    permissionKeys: ServiceProvisionRecordPermission,
    commonToAllFacilities: false,
  },
  {
    routeLike: "/user-service-record/provision-result",
    premissionType: "both",
    permissionKeys: ServiceProvisionRecordPermission,
    commonToAllFacilities: false,
  },
  {
    routeLike: "/user-service-record/provision-result/bulk-print/[us_ids]",
    premissionType: "both",
    permissionKeys: ServiceProvisionRecordPermission,
    commonToAllFacilities: false,
  },
  {
    routeLike: "/individual-support-plan",
    premissionType: "both",
    permissionKeys: IndividualSupportPlanPermission,
    commonToAllFacilities: false,
  },
  {
    routeLike: "/individual-support-plan/detail/[id]",
    premissionType: "both",
    permissionKeys: IndividualSupportPlanPermission,
    commonToAllFacilities: false,
  },
  {
    routeLike: "/individual-support-plan/add",
    premissionType: "write",
    permissionKeys: IndividualSupportPlanPermission,
    commonToAllFacilities: false,
  },
  {
    routeLike: "/individual-support-plan/edit/[id]",
    premissionType: "write",
    permissionKeys: IndividualSupportPlanPermission,
    commonToAllFacilities: false,
  },

  {
    routeLike: "/draft-individual-support-plan",
    premissionType: "both",
    permissionKeys: IndividualSupportPlanPermission,
    commonToAllFacilities: false,
  },
  {
    routeLike: "/draft-individual-support-plan/detail/[id]",
    premissionType: "both",
    permissionKeys: IndividualSupportPlanPermission,
    commonToAllFacilities: false,
  },
  {
    routeLike: "/draft-individual-support-plan/add",
    premissionType: "write",
    permissionKeys: IndividualSupportPlanPermission,
    commonToAllFacilities: false,
  },
  {
    routeLike: "/draft-individual-support-plan/edit/[id]",
    premissionType: "write",
    permissionKeys: IndividualSupportPlanPermission,
    commonToAllFacilities: false,
  },
  {
    routeLike: "/user-attendance-calendar",
    premissionType: "both",
    permissionKeys: UserAttendanceManagementPermission,
    commonToAllFacilities: false,
  },
  {
    routeLike: "/user-attendance-calendar/daily-list",
    premissionType: "write",
    permissionKeys: UserAttendanceManagementPermission,
    commonToAllFacilities: false,
  },
  {
    routeLike: "/user-attendance-calendar/daily-list/bulk-edit",
    premissionType: "write",
    permissionKeys: UserAttendanceManagementPermission,
    commonToAllFacilities: false,
  },
  {
    routeLike: "/user-attendance-calendar/daily-list/delete-form",
    premissionType: "write",
    permissionKeys: UserAttendanceManagementPermission,
    commonToAllFacilities: false,
  },
  {
    routeLike: "/user-attendance-calendar/absence-record",
    premissionType: "both",
    permissionKeys: UserAttendanceManagementPermission,
    commonToAllFacilities: false,
  },
  {
    routeLike: "/user-attendance-management",
    premissionType: "both",
    permissionKeys: UserAttendanceManagementPermission,
    commonToAllFacilities: false,
  },
  {
    routeLike: "/user-attendance-management/monthly-detail/[id]",
    premissionType: "both",
    permissionKeys: UserAttendanceManagementPermission,
    commonToAllFacilities: false,
  },
  {
    routeLike: "/user-attendance-management/monthly-detail/edit/[id]",
    premissionType: "write",
    permissionKeys: UserAttendanceManagementPermission,
    commonToAllFacilities: false,
  },
  {
    routeLike: "/facility-activity-management",
    premissionType: "both",
    permissionKeys: ProgramRecordPermission,
    commonToAllFacilities: false,
  },
  {
    routeLike: "/facility-activity-management/add",
    premissionType: "write",
    permissionKeys: ProgramRecordPermission,
    commonToAllFacilities: false,
  },
  {
    routeLike: "/facility-activity-management/edit/[id]",
    premissionType: "write",
    permissionKeys: ProgramRecordPermission,
    commonToAllFacilities: false,
  },
  {
    routeLike: "/wages-revenue",
    premissionType: "both",
    permissionKeys: WageManagementPermission,
    commonToAllFacilities: false,
  },
  {
    routeLike: "/wage-management",
    premissionType: "both",
    permissionKeys: WageManagementPermission,
    commonToAllFacilities: false,
  },
  {
    routeLike: "/wage-management/payslip-list/[id]",
    premissionType: "both",
    permissionKeys: WageManagementPermission,
    commonToAllFacilities: false,
  },
  {
    routeLike: "/wage-management/payslip-list/detail/[id]",
    premissionType: "both",
    permissionKeys: WageManagementPermission,
    commonToAllFacilities: false,
  },
  {
    routeLike: "/wage-management/payslip-list/edit/[id]",
    premissionType: "write",
    permissionKeys: WageManagementPermission,
    commonToAllFacilities: false,
  },
  {
    routeLike: "/attendance-schedule",
    premissionType: "both",
    permissionKeys: AttendanceSchedulePermission,
    commonToAllFacilities: false,
  },
  {
    routeLike: "/attendance-schedule/day-list",
    premissionType: "write",
    permissionKeys: AttendanceSchedulePermission,
    commonToAllFacilities: false,
  },
  {
    routeLike: "/attendance-schedule/monthly-schedule",
    premissionType: "write",
    permissionKeys: AttendanceSchedulePermission,
    commonToAllFacilities: false,
  },
  {
    routeLike: "/attendance-record",
    premissionType: "both",
    permissionKeys: StaffAttendanceRecordPermission,
    commonToAllFacilities: false,
  },
  {
    routeLike: "/attendance-record/day-list",
    premissionType: "write",
    permissionKeys: StaffAttendanceRecordPermission,
    commonToAllFacilities: false,
  },
  {
    routeLike: "/attendance-record/staff-monthly-attendance-record",
    premissionType: "write",
    permissionKeys: StaffAttendanceRecordPermission,
    commonToAllFacilities: false,
  },
  {
    routeLike: "/attendance-record/day-list/add/[facility_id'",
    premissionType: "write",
    permissionKeys: StaffAttendanceRecordPermission,
    commonToAllFacilities: false,
  },
  {
    routeLike: "/attendance-record/bulk-edit",
    premissionType: "write",
    permissionKeys: StaffAttendanceRecordPermission,
    commonToAllFacilities: false,
  },
  {
    routeLike: "/attendance-record/day-list/edit/[id]",
    premissionType: "write",
    permissionKeys: StaffAttendanceRecordPermission,
    commonToAllFacilities: false,
  },
  {
    routeLike: "/work-style",
    premissionType: "both",
    permissionKeys: [
      AttendanceSchedulePermission,
      StaffAttendanceRecordPermission,
    ],
    commonToAllFacilities: false,
  },
  {
    routeLike: "/business-day-management",
    premissionType: "both",
    permissionKeys: BusinessListPermission,
    commonToAllFacilities: false,
  },
  {
    routeLike: "/business-day-management/registration",
    premissionType: "write",
    permissionKeys: BusinessListPermission,
    commonToAllFacilities: false,
  },
  {
    routeLike: "/facility-information",
    premissionType: "both",
    permissionKeys: FacilityInformationPermission,
    commonToAllFacilities: false,
  },
  {
    routeLike: "/facility-information/edit/[id]",
    premissionType: "write",
    permissionKeys: FacilityInformationPermission,
    commonToAllFacilities: false,
  },
  {
    routeLike: "/facility-information/system-status/[facility_id]",
    premissionType: "both",
    permissionKeys: SystemStatusManagementPermission,
    commonToAllFacilities: false,
  },
  {
    routeLike: "/users/profile/[user_id]/application-management",
    premissionType: "both",
    permissionKeys: FacilityAppManagementPermission,
    commonToAllFacilities: false,
  },
  {
    routeLike: "/users/profile/[user_id]/application-management/[mode]",
    premissionType: "write",
    permissionKeys: FacilityAppManagementPermission,
    commonToAllFacilities: false,
  },
  {
    routeLike: "/users/profile/[user_id]/application-management/print",
    premissionType: "both",
    permissionKeys: FacilityAppManagementPermission,
    commonToAllFacilities: false,
  },
  {
    routeLike: "/user-transport-management",
    premissionType: "both",
    permissionKeys: TransportationManagementPermission,
    commonToAllFacilities: false,
  },
  {
    routeLike: "/user-transport-management/[date]",
    premissionType: "both",
    permissionKeys: TransportationManagementPermission,
    commonToAllFacilities: false,
  },
  {
    routeLike: "/user-transport-management/[date]/bulk-edit-form",
    premissionType: "write",
    permissionKeys: TransportationManagementPermission,
    commonToAllFacilities: false,
  },
  {
    routeLike: "/user-transport-management/edit/[user_id]/[date]",
    premissionType: "write",
    permissionKeys: TransportationManagementPermission,
    commonToAllFacilities: false,
  },
  {
    routeLike: "/user-transport-management/[date]/printing",
    premissionType: "both",
    permissionKeys: TransportationManagementPermission,
    commonToAllFacilities: false,
  },
  {
    routeLike: "/user-transport-management/[date]/drivers",
    premissionType: "write",
    permissionKeys: TransportationManagementPermission,
    commonToAllFacilities: false,
  },
  {
    routeLike: "/daily-business-report-list",
    premissionType: "both",
    permissionKeys: DailyBusinessReportPermission,
    commonToAllFacilities: false,
  },
  {
    routeLike: "/daily-business-report-list/add",
    premissionType: "write",
    permissionKeys: DailyBusinessReportPermission,
    commonToAllFacilities: false,
  },
  {
    routeLike: "/daily-business-report-list/edit/[id]",
    premissionType: "write",
    permissionKeys: DailyBusinessReportPermission,
    commonToAllFacilities: false,
  },
  {
    routeLike: "/daily-business-report-list/view",
    premissionType: "both",
    permissionKeys: DailyBusinessReportPermission,
    commonToAllFacilities: false,
  },
  {
    routeLike: "/actual-cost-burden-schedule",
    premissionType: "both",
    permissionKeys: ActualCostSchedulePermission,
    commonToAllFacilities: false,
  },
  {
    routeLike: "/actual-cost-burden-schedule/add",
    premissionType: "write",
    permissionKeys: ActualCostSchedulePermission,
    commonToAllFacilities: false,
  },
  {
    routeLike: "/monthly-use-status-chart",
    premissionType: "both",
    permissionKeys: MonthlyStatusPermission,
    commonToAllFacilities: false,
  },
  {
    routeLike: "/treatment-improvement-addition-amount-result-table",
    premissionType: "both",
    permissionKeys: TreatmentImprovementPermission,
    commonToAllFacilities: false,
  },
  {
    routeLike: "/various-additions-management-of-meeting-minutes",
    premissionType: "both",
    permissionKeys: AdditionMinutePermission,
    commonToAllFacilities: false,
  },
  {
    routeLike: "/various-additions-management-of-meeting-minutes/detail/[id]",
    premissionType: "both",
    permissionKeys: AdditionMinutePermission,
    commonToAllFacilities: false,
  },
  {
    routeLike: "/various-additions-management-of-meeting-minutes/bulk-print",
    premissionType: "both",
    permissionKeys: AdditionMinutePermission,
    commonToAllFacilities: false,
  },
  {
    routeLike: "/various-additions-management-of-meeting-minutes/edit/[mm_id]",
    premissionType: "write",
    permissionKeys: AdditionMinutePermission,
    commonToAllFacilities: false,
  },
  {
    routeLike: "/various-additions-management-of-meeting-minutes/register",
    premissionType: "write",
    permissionKeys: AdditionMinutePermission,
    commonToAllFacilities: false,
  },
  {
    routeLike: "/user-daily-record",
    premissionType: "both",
    permissionKeys: UserDailyRecordPermission,
    commonToAllFacilities: false,
  },
  {
    routeLike: "/user-daily-record/[id]",
    premissionType: "both",
    permissionKeys: UserDailyRecordPermission,
    commonToAllFacilities: false,
  },
  {
    routeLike: "/revenue-management",
    premissionType: "both",
    permissionKeys: RevenueManagementPermission,
    commonToAllFacilities: false,
  },
  {
    routeLike: "/revenue-management/graph",
    premissionType: "both",
    permissionKeys: RevenueManagementPermission,
    commonToAllFacilities: false,
  },
  {
    routeLike: "/additional-items-management",
    premissionType: "both",
    permissionKeys: [
      AdditionalItemFacilityPermission,
      AdditionalItemUserPermission,
    ],
    commonToAllFacilities: false,
  },
  {
    routeLike: "/additional-items-management/facility",
    premissionType: "both",
    permissionKeys: AdditionalItemFacilityPermission,
    commonToAllFacilities: false,
  },
  {
    routeLike: "/additional-items-management/facility/[id]",
    premissionType: "write",
    permissionKeys: AdditionalItemFacilityPermission,
    commonToAllFacilities: false,
  },
  {
    routeLike: "/additional-items-management/adult",
    premissionType: "both",
    permissionKeys: AdditionalItemUserPermission,
    commonToAllFacilities: false,
  },
  {
    routeLike: "/additional-items-management/bulk-edit/[id]",
    premissionType: "write",
    permissionKeys: AdditionalItemUserPermission,
    commonToAllFacilities: false,
  },
  {
    routeLike: "/actual-cost-burden-record",
    premissionType: "both",
    permissionKeys: ActualCostBurdenPermission,
    commonToAllFacilities: false,
  },
  {
    routeLike: "/actual-cost-burden-record/list",
    premissionType: "write",
    permissionKeys: ActualCostBurdenPermission,
    commonToAllFacilities: false,
  },
  {
    routeLike: "/actual-cost-burden-record/list/details/[id]",
    premissionType: "write",
    permissionKeys: ActualCostBurdenPermission,
    commonToAllFacilities: false,
  },
  {
    routeLike: "/actual-cost-burden-record/list/bulk-edit/[id]",
    premissionType: "write",
    permissionKeys: ActualCostBurdenPermission,
    commonToAllFacilities: false,
  },
  {
    routeLike: "/actual-cost-burden-record/list/add",
    premissionType: "write",
    permissionKeys: ActualCostBurdenPermission,
    commonToAllFacilities: false,
  },
  {
    routeLike: "/national-health-insurance-billing",
    premissionType: "both",
    permissionKeys: BillingCSVPermission,
    commonToAllFacilities: false,
  },
  {
    routeLike:
      "/national-health-insurance-billing/prints/disabled-user-outpatient-benefits",
    premissionType: "both",
    permissionKeys: BillingCSVPermission,
    commonToAllFacilities: false,
  },
  {
    routeLike: "/national-health-insurance-billing/prints/summary-list",
    premissionType: "both",
    permissionKeys: BillingCSVPermission,
    commonToAllFacilities: false,
  },
  {
    routeLike: "/national-health-insurance-billing/prints/user-billing-list",
    premissionType: "both",
    permissionKeys: BillingCSVPermission,
    commonToAllFacilities: false,
  },
  {
    routeLike: "/national-health-insurance-billing/prints/customer-invoice",
    premissionType: "both",
    permissionKeys: BillingCSVPermission,
    commonToAllFacilities: false,
  },
  {
    routeLike: "/national-health-insurance-billing/prints/receipt-user",
    premissionType: "both",
    permissionKeys: BillingCSVPermission,
    commonToAllFacilities: false,
  },
  {
    routeLike:
      "/national-health-insurance-billing/prints/print-proxy-acknowledgement",
    premissionType: "both",
    permissionKeys: BillingCSVPermission,
    commonToAllFacilities: false,
  },
  {
    routeLike: "/national-health-insurance-billing/prints/address-to-users",
    premissionType: "both",
    permissionKeys: BillingCSVPermission,
    commonToAllFacilities: false,
  },
  {
    routeLike: "/list-of-municipal-subsidy-claims-information-management",
    premissionType: "both",
    permissionKeys: MunicipalSubsidiaryPermission,
    commonToAllFacilities: false,
  },
  {
    routeLike: "/list-of-municipal-subsidy-claims-information-management/add",
    premissionType: "write",
    permissionKeys: MunicipalSubsidiaryPermission,
    commonToAllFacilities: false,
  },
  {
    routeLike:
      "/list-of-municipal-subsidy-claims-information-management/edit/[id]",
    premissionType: "write",
    permissionKeys: MunicipalSubsidiaryPermission,
    commonToAllFacilities: false,
  },
  {
    routeLike: "/auto-email-sending",
    premissionType: "both",
    permissionKeys: SystemMailSettings,
    commonToAllFacilities: false,
  },
  {
    routeLike: "/auto-email-sending/edit/[id]",
    premissionType: "write",
    permissionKeys: SystemMailSettings,
    commonToAllFacilities: false,
  },
  {
    routeLike: "/system-user-account-management",
    premissionType: "write",
    permissionKeys: [],
    commonToAllFacilities: true,
  },
  {
    routeLike: "/system-user-account-management/add",
    premissionType: "write",
    permissionKeys: [],
    commonToAllFacilities: true,
  },
  {
    routeLike: "/system-user-account-management/edit/[id]",
    premissionType: "write",
    permissionKeys: [],
    commonToAllFacilities: true,
  },
  {
    routeLike: "/user-status-list",
    premissionType: "both",
    permissionKeys: UserManagementPermission,
    commonToAllFacilities: false,
  },
  {
    routeLike: "/employment-certificate",
    premissionType: "both",
    permissionKeys: EmploymentCertificateRegistrtionPermission,
    commonToAllFacilities: false,
  },
  {
    routeLike: "/users/work/[user_id]/edit",
    premissionType: "write",
    permissionKeys: EmploymentCertificateRegistrtionPermission,
    commonToAllFacilities: false,
  },
  {
    routeLike: "/users/work/[user_id]/detail",
    premissionType: "write",
    permissionKeys: EmploymentCertificateRegistrtionPermission,
    commonToAllFacilities: false,
  },
  {
    routeLike: "/assessment",
    premissionType: "both",
    permissionKeys: UserManagementPermission,
    commonToAllFacilities: false,
  },
  {
    routeLike: "/assessment/add",
    premissionType: "write",
    permissionKeys: UserManagementPermission,
    commonToAllFacilities: false,
  },
  {
    routeLike: "/assessment/edit/[id]",
    premissionType: "write",
    permissionKeys: UserManagementPermission,
    commonToAllFacilities: false,
  },
  {
    routeLike: "/assessment/details/[id]",
    premissionType: "both",
    permissionKeys: UserManagementPermission,
    commonToAllFacilities: false,
  },
  {
    routeLike: "/monitoring-list",
    premissionType: "both",
    permissionKeys: UserManagementPermission,
    commonToAllFacilities: false,
  },
  {
    routeLike: "/monitoring-list/detail/[id]",
    premissionType: "both",
    permissionKeys: UserManagementPermission,
    commonToAllFacilities: false,
  },
  {
    routeLike: "/monitoring-list/edit/[id]",
    premissionType: "write",
    permissionKeys: UserManagementPermission,
    commonToAllFacilities: false,
  },
  {
    routeLike: "/monitoring-list/add",
    premissionType: "write",
    permissionKeys: UserManagementPermission,
    commonToAllFacilities: false,
  },
  {
    routeLike: "/company-information",
    premissionType: "both",
    permissionKeys: [],
    commonToAllFacilities: true,
  },
  {
    routeLike: "/company-information/edit",
    premissionType: "write",
    permissionKeys: [],
    commonToAllFacilities: true,
  },
  {
    routeLike: "/wage-management/leave-settings/[id]",
    premissionType: "write",
    permissionKeys: WageManagementPermission,
    commonToAllFacilities: false,
  },
]

export const FilterAccessibleFacilitiesByPage = (
  facilities,
  facilitySpecificPermissions,
  route,
) => {
  const thisPageObj = PageObj.find((o) => o.routeLike == route)
  if (!thisPageObj) return facilities

  if (thisPageObj.commonToAllFacilities) {
    const affiliatedFacilities = facilitySpecificPermissions?.map(
      (facilitySpecific) => facilitySpecific?.facility_id,
    )
    return facilities.filter((facility) =>
      affiliatedFacilities.includes(facility?.id),
    )
  }
  return facilities?.filter((facility) => {
    for (let i = 0; i < facilitySpecificPermissions?.length; i++) {
      if (facility?.id == facilitySpecificPermissions[i]?.facility_id) {
        // multiple permissions apply or logic
        if (Array.isArray(thisPageObj.permissionKeys?.[0])) {
          for (let j = 0; j < thisPageObj.permissionKeys.length; j++) {
            if (
              hasPermissionForMenu(
                { user_facility_permissions: facilitySpecificPermissions[i] },
                thisPageObj.permissionKeys[j],
                thisPageObj?.premissionType,
              )
            ) {
              return true
            }
          }
        } else {
          return hasPermissionForMenu(
            { user_facility_permissions: facilitySpecificPermissions[i] },
            thisPageObj?.permissionKeys,
            thisPageObj?.premissionType,
          )
        }
      }
    }
    return false
  })
}
