import { API } from "@project/common"
import { t } from "i18next"
interface IFacilitiesWithCountParams {
  year?: string
  month?: string
}
export const getAllFacilitiesWithChildCount = async (
  params?: IFacilitiesWithCountParams,
) => {
  try {
    const response = await API.get("/facilities_with_user_count", { params })

    return [
      ...(response?.data?.facilities || []),
      {
        id: 0,
        facility_name: t("No contract facility"),
        facility_name_short: t("No contract facility"),
        total: response?.data?.none_contracted_child_count || 0,
      },
    ]
  } catch (error) {
    return []
  }
}

export const fetchFacilities = async () => {
  const response = await API.get(
    "/facilities?pageSize=Infinity&sort=CreateDateTime",
  )
  return {
    ...response,
    data: response?.data?.map((v) => ({
      ...v,
      value: `${v?.id}`,
      label: v?.facility_name_short || v?.facility_name,
    })),
  }
}

export const fetchNonDraftFacilities = async () => {
  const response = await API.get(
    "/accessible-facilities?pageSize=Infinity&sort=CreateDateTime&status=0",
  )
  return {
    ...response,
    data: response?.data?.map((v) => ({
      ...v,
      value: `${v?.id}`,
      label: v?.facility_name_short || v?.facility_name,
    })),
  }
}
