import { API, removeBlankAttributes } from "@project/common"
import dayjs from "dayjs"
export interface IReturnDailyBusinessReportList {
  count?: number
  data?: any
}

export interface IDailyBusinessReportListParam {
  page?: string | number
  facility_id?: string
  from_date?: string
  to_date?: string
  date?: string
  pageSize?: number
  year?: number
  month?: number
}

const BASE_ENDPOINT = "daily-business-report"

export const fetchDailyBusinessReportList = async ({
  queryKey,
}): Promise<IReturnDailyBusinessReportList> => {
  const params = {} as IDailyBusinessReportListParam
  params.page = queryKey[1]
  params.pageSize = 20
  params.facility_id = queryKey[2]?.facility_id
  params.from_date = queryKey[2]?.from_date
    ? dayjs(queryKey[2]?.from_date).format("YYYY-MM-DD")
    : null
  params.to_date = queryKey[2]?.to_date
    ? dayjs(queryKey[2]?.to_date).format("YYYY-MM-DD")
    : null
  params.year = queryKey[2]?.year || null
  params.month = queryKey[2]?.month || null
  return API.get(`/${BASE_ENDPOINT}`, {
    params,
  })
}
export const createDailyBusinessReport = async (values): Promise<any> => {
  delete values?.id
  return API.post(`/${BASE_ENDPOINT}`, values)
}
export const updateDailyBusinessReport = async (values) => {
  const id = values.id
  delete values.id
  return API.put(`/${BASE_ENDPOINT}/${id}`, values)
}

export const getDailyBusinessReport = async ({ queryKey }) => {
  const id = queryKey[1]
  return API.get(`/${BASE_ENDPOINT}/${id}`)
}

export const getAllDailyUser = async () => {
  return API.get(`/user`)
}

export const getDailyBusinessReportView = async ({
  queryKey,
}: {
  queryKey?: any
}) => {
  const facility_id = queryKey[1]?.facility
  const from_date = queryKey[2]?.from_date
    ? dayjs(queryKey[2]?.from_date).format("YYYY-MM-DD")
    : null
  const to_date = queryKey[2]?.to_date
    ? dayjs(queryKey[2]?.to_date).format("YYYY-MM-DD")
    : null

  return API.get(
    `/${BASE_ENDPOINT}/${facility_id}?from_date=${from_date}&to_date=${to_date}`,
  )
}

export const deleteDailyBusinessReport = async (id) => {
  return API.delete(`/${BASE_ENDPOINT}/${id}`)
}

export const getAllWork = async () => {
  return API.get(`/work?page=1&pageSize=Infinity`)
}

export const getAllStaff = () => {
  return API.get(`/instructors?page=1&pageSize=Infinity`)
}

export const getDateInfoDetail = async (queries: {
  date?: string
  facilityIds: number
  year?: number
  month?: number
}) => {
  const params = removeBlankAttributes(queries)
  return API.get(`/${BASE_ENDPOINT}/date-info-detail`, {
    params,
  })
}

export const fetchOneDailyBusinessReportList = async ({
  queryKey,
}): Promise<IReturnDailyBusinessReportList> => {
  const params = {} as any
  const id = queryKey[1]
  params.facility_id = queryKey[2]
  params.from_date = queryKey[3]?.from_date
  params.to_date = queryKey[3]?.from_date
  return API.get(`/${BASE_ENDPOINT}/${id}`, {
    params,
  })
}
