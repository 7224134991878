import React from "react"
import { Tag } from "../Tag/Tag"
import { useTranslation } from "react-i18next"

const ServiceTypeTag = ({ type }: { type: number }): JSX.Element => {
  const { t } = useTranslation()
  switch (type) {
    case 1:
      return <Tag title={t("Employment Type A")} color={"warning"} />
    case 2:
      return <Tag title={t("Employment Type B")} color={"processing"} />
    case 3:
      return <Tag title={t("Employment Transition Support")} color={"error"} />
    default:
      return null
  }
}
ServiceTypeTag.displayName = "ServiceTypeTag"
export { ServiceTypeTag }
