import { Card } from "antd"
import styled from "styled-components"
import { theme } from "../../theme"

export const AntCard = styled(Card)<any>`
  ${(props) => props?.borderStyle && `border:${props?.borderStyle};`}
  box-shadow: ${(props) =>
    props?.boxShadow || `0px 0px 25px 0px ${theme.colors.shadow}`};
  border-radius: 12px;
  .ant-card-head {
    width: 100% !important;
    padding: 0 20px !important;
    margin: auto;
    position: relative;
    min-height: auto !important;
    border-bottom: none !important;
    .ant-card-head-title {
      font-size: 16px;
      font-weight: 500;
      width: 100%;
    }
    .ant-card-head-wrapper {
      min-height: 56px;
      padding: 8px 0;
      border-bottom: 1px solid ${theme.colors.border} !important;
      @media screen and (max-width: ${theme.breakpoints.sm}) {
        min-height: 30px;
      }
    }
    @media screen and (max-width: ${theme.breakpoints.sm}) {
      padding: 0 16px !important;
      .ant-card-head-wrapper {
        display: flex !important;
        flex-direction: column !important;
        align-items: flex-start;
        row-gap: 0.5em;
      }
      .ant-card-extra {
        width: 100%;
      }
    }
  }
  .ant-card-body {
    padding: 16px 20px !important;
    @media screen and (max-width: ${theme.breakpoints.sm}) {
      padding: 10px 16px !important;
    }
  }
  &:where(.css-dev-only-do-not-override-2q8sxy).ant-card:not(
      .ant-card-bordered
    ) {
    box-shadow: unset !important;
  }
  @media screen and (max-width: ${theme.breakpoints.md}) {
    .ant-card-head-title {
      white-space: pre-line !important;
    }
  }
  @media screen and (max-width: ${theme.breakpoints.sm}) {
    box-shadow: ${(props) => props?.boxShadow || ` 4px 4px 16px 0px #3333331f`};
    border: ${(props) =>
      props?.borderStyle || `1px solid ${theme.colors.border}`};
  }

  @media only print {
    box-shadow: unset !important;
    border-radius: 0 !important;
    border: 0 !important;
    .ant-card-body {
      padding: 0 !important;
    }
  }
`
