import { Layout } from "antd"

import { useEffect } from "react"
import { Logo } from "../../components/Logo/Logo"
import { LogoutBtn } from "../../components/LogoutBtn/LogoutBtn"
import { useResponsiveMediaQuery } from "../../hooks"
import { SidebarWrapper } from "./Sidebar.style"
import { SideMenu } from "./SideMenu"
interface SidebarProps {
  setCollapsed: any
  collapsed: boolean
  menuItems: any[]
  logOut: () => void
  sideBarProps: {
    openKeys: any[]
    setOpenKeys: (val: any) => void
    current: string
    setCurrent: (val: string) => void
  }
  handleCollapse: (val?: boolean) => void
}
const Sidebar = ({
  collapsed,
  menuItems,
  logOut,
  sideBarProps,
  handleCollapse,
}: SidebarProps): JSX.Element => {
  const { isTabletOrMobile } = useResponsiveMediaQuery()
  useEffect(() => {
    if (isTabletOrMobile) {
      handleCollapse(true)
    }
  }, [isTabletOrMobile])

  return (
    <SidebarWrapper>
      <Layout.Sider
        trigger={null}
        collapsible
        collapsed={collapsed}
        onCollapse={handleCollapse}
        id={"site__sidebar"}
        width={260}
        style={{
          overflow: "auto",
          height: "100vh",
          position: "fixed",
          left: 0,
          top: 0,
          bottom: 0,
        }}
      >
        <Logo collapsed={collapsed} />
        <SideMenu
          menuItems={menuItems}
          collapsed={collapsed}
          sideBarProps={sideBarProps}
        />
        <LogoutBtn collapsed={collapsed} logOut={logOut} />
      </Layout.Sider>
    </SidebarWrapper>
  )
}
Sidebar.displayName = "Sidebar"
export { Sidebar }
