import styled from "styled-components"
import { theme } from "../../theme"

export const Wrapper = styled.div`
  .faculty-datepicker {
    .ant-picker {
      input {
        &::placeholder {
          color: ${theme.colors.border} !important;
          font-size: 14px !important;
          @media screen and (max-width: ${theme.breakpoints.sm}) {
            font-size: 12px !important;
          }
        }
      }
      @media screen and (max-width: ${theme.breakpoints.md}) {
        font-size: 12px !important;
        width: 100% !important;
      }
    }
    .field-error {
      font-size: 13px;
      color: #eb5757;
      margin-top: 5px;
      /* margin-left: 8px; */
      max-width: 150px;
    }
  }
  @media screen and (max-width: ${theme.breakpoints.md}) {
    font-size: 12px !important;
    width: 100%;
  }
`
