import React from "react"
import styled from "styled-components"
import { theme } from "../../theme"
import { useTranslation } from "react-i18next"
const StyleTableSection = styled.div`
  overflow: auto;
  width: 100%;
  border: 1px solid ${theme.colors.text};
  table {
    table-layout: fixed;
    border-collapse: collapse;
    border-spacing: 0;
    empty-cells: show;
    break-before: avoid;
    break-after: avoid;
    width: 100%;
    min-width: 768px;
  }
  /* table > thead > tr > th {
    background-color: ${theme.colors.border};
  } */

  table > thead > tr > th,
  table > thead > tr > td {
    padding: 10px 2px !important;
    font-weight: 400;
    border: 1px solid ${theme.colors.text};
    span {
      font-size: inherit;
      font-weight: inherit;
    }
  }
  table > thead > tr > .center {
    text-align: center;
  }
  table > thead > tr > .w-20 {
    width: 20px;
  }
  @media print {
    table {
      min-width: 100%;
      width: 100%;
    }
  }
`

interface IProps {
  recipientNumberKey: string
  recipientNumber: string
  userNameKey: string
  userNameValue: string
  officeName: string
  officeNumber: string
  contractAmountKey: string
  contractAmount: string | React.ReactNode
  businessOwner: string | React.ReactNode
  provider: string
  className?: string
}
export const ServiceProvisionSheetTopSummaryTable = ({
  recipientNumberKey,
  recipientNumber,
  userNameKey,
  userNameValue,
  officeName,
  officeNumber,
  contractAmountKey,
  contractAmount,
  businessOwner,
  provider,
  className,
}: IProps): JSX.Element => {
  const { t } = useTranslation()
  return (
    <StyleTableSection className={"top-comp-table"}>
      <table className={"table"}>
        <thead>
          <tr>
            <th className={`head ${className}`}>{t(recipientNumberKey)}</th>
            {recipientNumber
              ?.padEnd(10, " ")
              .split("")
              .map((num, index) => (
                <td
                  key={index}
                  className={`base-cell center w-20 ${className}`}
                >
                  {num}
                </td>
              ))}
            <th className={`head ${className}`}>{userNameKey}</th>
            <td className={`center ${className}`}>{userNameValue}</td>
            <th className={`head ${className}`}>{officeName}</th>
            {officeNumber
              ?.padEnd(10, " ")
              ?.split("")
              .map((num, index) => (
                <td
                  key={index}
                  className={`base-cell center  w-20 ${className}`}
                >
                  {num}
                </td>
              ))}
          </tr>
          <tr>
            <th className={`head ${className}`}>{contractAmountKey}</th>
            <td colSpan={12} className={`base-cell ${className}`}>
              {contractAmount}
            </td>
            <th>{provider}</th>
            <td colSpan={10} className={`left base-cell center ${className}`}>
              {businessOwner}
            </td>
          </tr>
        </thead>
      </table>
    </StyleTableSection>
  )
}
