import { API, removeBlankAttributes } from "@project/common"

export const fetchUserWhoNeedsMonitoring = ({
  ...args
}: {
  page?: number
  pageSize?: number | string
  user_id?: string
  facility?: string
  service?: string
}): Promise<Response> => {
  const params = removeBlankAttributes(args)
  return API.get(`/user-support/users-who-needs-monitoring`, {
    params,
  })
}
