import React, { ReactNode, useEffect, useState } from "react"
import Head from "next/head"
import * as yup from "yup"
import { useTranslation } from "react-i18next"
import { Button } from "antd"
import { useFormik } from "formik"
import Link from "next/link"
import {
  Container,
  InputFieldWrapper,
  LoginWrapper,
  TextFieldWrapper,
  Wrapper,
} from "./Login.style"

import { Footer } from "../UnAuthorizedLayout/Footer"
import { InputField } from "../../components"
import { EyeFilled, EyeInvisibleFilled } from "@ant-design/icons"

interface LoginType {
  email: string
  password: string
}

interface LoginComponentProps {
  handleLogin: (values: LoginType) => void
  loading: boolean
  logo: ReactNode
}
const LoginComponent = ({
  logo,
  handleLogin,
  loading,
}: LoginComponentProps) => {
  const { t } = useTranslation()
  const [isPasswordVisible, setIsPasswordVisible] = useState(false)
  const togglePasswordVisibility = () => {
    setIsPasswordVisible(!isPasswordVisible)
  }

  useEffect(() => {}, [])
  const validationSchema = yup.object().shape({
    email: yup
      .string()
      .email(t("email_validation"))
      .required(t("email_validation")),
    password: yup.string().required(t("password_validation")),
  })
  const formik = useFormik<LoginType>({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema,
    onSubmit: handleLogin,
  })
  return (
    <>
      <Head>
        <title>
          {t("施設管理画面ログイン")}
          {" | "}
          {t("easyy admin")}
        </title>
      </Head>
      <Wrapper>
        <Container style={{ flex: 1 }}>
          <div className={"top-content-wrapper"}>
            <div className={"logo-wrapper"}>{logo}</div>
            <h3>{t("Facility management login")}</h3>
          </div>
          <LoginWrapper>
            <TextFieldWrapper>
              <form onSubmit={formik.handleSubmit}>
                <InputFieldWrapper>
                  <InputField
                    name={"email"}
                    error={formik.touched.email && formik.errors.email}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    label={t("Email")}
                    placeholder={t("Enter Email")}
                    className={"text-field"}
                    height={"48px"}
                    width={"368px"}
                    borderRadius={"8px"}
                    bg={"transparent"}
                  />
                </InputFieldWrapper>

                <InputFieldWrapper>
                  <div className={"password-wrapper"}>
                    <InputField
                      name={"password"}
                      type={isPasswordVisible ? "text" : "password"}
                      error={formik.touched.password && formik.errors.password}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      label={t("Password")}
                      placeholder={t("Enter Password")}
                      className={"text-field"}
                      height={"48px"}
                      width={"368px"}
                      borderRadius={"8px"}
                      bg={"transparent"}
                    />
                    <span
                      className={"visible-icon"}
                      onClick={togglePasswordVisibility}
                    >
                      {isPasswordVisible ? (
                        <EyeFilled />
                      ) : (
                        <EyeInvisibleFilled />
                      )}
                    </span>
                  </div>

                  <p className={"password-info-text"}>
                    {t("Password must be more than 6 characters")}
                  </p>
                </InputFieldWrapper>

                <div className={"login-button-wrapper"}>
                  <Button htmlType={"submit"} loading={loading} shape={"round"}>
                    {t("Login")}
                  </Button>
                </div>

                <div className={"forget-password-wrapper"}>
                  <p>{t("Forgot your password ? ")}</p>
                  <Link href={"/forgot-password"}>{t("Reset password")}</Link>
                </div>
              </form>
            </TextFieldWrapper>
          </LoginWrapper>
        </Container>
        <Footer />
      </Wrapper>
    </>
  )
}
LoginComponent.displayName = "LoginComponent"
export { LoginComponent }
