import styled from "styled-components"
import { theme } from "../../theme"
export const PageTitleWrapper = styled.div<any>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: 16px;
  margin-bottom: ${({ marginBottom }) =>
    marginBottom ? marginBottom : "16px"};
  flex-wrap: wrap;
  gap: 10px;
  row-gap: 10px !important;
  .page__title--right {
    display: flex;
    align-items: center;
    gap: 10px;
    .icon__container {
      background-color: ${theme.colors?.white};
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      height: 50px;
      width: 50px;
      box-shadow: 0px 0px 25px 0px ${theme?.colors?.shadow};
      min-width: 50px;
    }
    .text__container {
      margin: 0 !important;
      font-weight: 400;
      font-size: 16px;
      line-height: 23.17px;
    }
  }
  /* .sub_title_content {
    row-gap: 10px !important;
    column-gap: 10px !important;
  } */
  @media screen and (max-width: ${theme.breakpoints.sm}) {
    @media screen and (max-width: ${theme.breakpoints.md}) {
      flex-wrap: wrap;
      row-gap: 0.3em;
    }
    @media screen and (max-width: ${theme.breakpoints.xs}) {
      flex-wrap: wrap;
      row-gap: 0.3em;
      button {
        padding: 5px !important;
      }
    }
  }
  @media only print {
    display: none;
  }
`
