// utils
import { rem } from "../../../../utils/converter"

export function sizeResolver(value: unknown) {
  if (typeof value === "number") {
    return rem(value)
  }

  return value
}
