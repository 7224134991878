const CSV = () => (
  <svg
    width={"12"}
    height={"15"}
    viewBox={"0 0 12 15"}
    fill={"none"}
    xmlns={"http://www.w3.org/2000/svg"}
  >
    <path
      d={
        "M7.33366 0.833984H2.00033C1.6467 0.833984 1.30756 0.97446 1.05752 1.22451C0.807468 1.47456 0.666992 1.8137 0.666992 2.16732V12.834C0.666992 13.574 1.26033 14.1673 2.00033 14.1673H10.0003C10.7403 14.1673 11.3337 13.574 11.3337 12.834V4.83398L7.33366 0.833984ZM10.0003 12.834H2.00033V2.16732H6.66699V5.50065H10.0003V12.834ZM6.60033 9.16732L8.53366 12.1673H7.33366L6.00033 9.90065L4.66699 12.1673H3.46699L5.40033 9.16732L3.46699 6.16732H4.66699L6.00033 8.43398L7.33366 6.16732H8.53366L6.60033 9.16732Z"
      }
      fill={"#191919"}
    />
  </svg>
)

export default CSV
