import { CloseOutlined } from "@ant-design/icons"
import {
  Modal as AntModal,
  ModalProps as AntModalProps,
  ConfigProvider,
} from "antd"
import { ReactNode } from "react"
import styled from "styled-components"
import { theme } from "../../theme"
interface ModalProps extends AntModalProps {
  footerContent?: ReactNode
  headerBg?: string
  titleColor?: string
  titleHight?: string
  hederPadding?: string
  titleFontSize?: string
  bodyPadding?: string
  headerBorderBottom?: string
  borderRadius?: string
  bgColor?: string
  color?: string
  headerCloseable?: boolean
  headerPadding?: string
  footerPadding?: string
  dailyBusinessBody?: boolean
  height?: number
}
const StyledModal = styled(AntModal)<ModalProps>`
  .ant-modal-content {
    padding: 0 !important;
    border-radius: ${(props) => props?.borderRadius || "0px"} !important;
    height: ${(props) => ` ${props?.height}px` || "auto"};
  }
  .ant-modal-header {
    border-radius: 0 !important;
    border-top-left-radius: ${(props) =>
      props?.borderRadius || "0px"} !important;
    border-top-right-radius: ${(props) =>
      props?.borderRadius || "0px"} !important;
    border-bottom: ${(props) =>
      props?.headerBorderBottom || `1px solid ${theme.colors.text}`};
    background-color: ${({ bgColor }) => bgColor};
    padding: ${({ headerPadding }) => headerPadding || "10px"};
    .modal__header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: ${(props) => props?.titleHight || ""};
      color: ${({ color }) => color};
      span {
        font-size: ${(props) => props?.titleFontSize || "14px"};
        font-style: normal;
        font-weight: 500;
      }
    }
  }
  .ant-modal-body {
    padding: ${(props) => props?.bodyPadding || "30px"} !important;
    .radio-content {
      padding: ${({ dailyBusinessBody }) => dailyBusinessBody && "20px"};
      margin-bottom: ${({ dailyBusinessBody }) => dailyBusinessBody && "10px"};
      .title {
        margin-bottom: 10px;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
      }
      .ant-radio-group {
        display: flex;
        flex-direction: column;
        gap: 10px;
      }
    }
    .check-box-content {
      padding: ${({ dailyBusinessBody }) =>
        dailyBusinessBody && "20px 20px 10px 20px"};
      .check-box-content {
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .title {
        margin-bottom: 10px;
        white-space: pre-line;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
      }
    }
    @media (max-width: ${theme.breakpoints.md}) {
      padding: 20px !important;
    }
    @media (max-width: ${theme.breakpoints.sm}) {
      padding: 10px !important;
    }
  }

  .ant-modal-footer {
    padding: ${({ footerPadding }) => footerPadding};
    border-top: 1px solid #d2d1d1d1;
    .modal__footer {
      display: flex;
      justify-content: flex-end;
    }
  }
`
const Modal = ({
  headerBg,
  titleColor,
  onCancel,
  title,
  children,
  bgColor,
  color,
  headerCloseable,
  headerPadding,
  footerPadding,
  dailyBusinessBody,
  ...props
}: ModalProps): JSX.Element => {
  return (
    <>
      <ConfigProvider
        theme={{
          components: {
            Modal: {
              headerBg: headerBg || theme.colors.secondary,
              titleColor: titleColor || theme.colors.white,
              titleFontSize: 20,
            },
          },
        }}
      >
        <StyledModal
          {...props}
          bgColor={bgColor}
          color={color}
          destroyOnClose
          onCancel={onCancel}
          headerPadding={headerPadding}
          footerPadding={footerPadding}
          dailyBusinessBody={dailyBusinessBody}
          centered
          title={
            title ? (
              <div className={"modal__header"}>
                <span>{title}</span>
                {headerCloseable ? null : (
                  <div
                    onClick={(e: any) => onCancel(e)}
                    style={{
                      cursor: "pointer",
                    }}
                  >
                    <CloseOutlined />
                  </div>
                )}
              </div>
            ) : null
          }
          className={"easyy__adult_modal"}
          closable={false}
        >
          {children}
        </StyledModal>
      </ConfigProvider>
    </>
  )
}
Modal.displayName = "Modal"
export { Modal }
