import React, { useEffect, useState } from "react"
import { Typography } from "antd"
import { useTranslation } from "react-i18next"
import { EditOutlined } from "@ant-design/icons"
import { FooterWrapper } from "./PlanDetailContent.style"

import { PlanOperationData, PlanType } from "../../types"
import { getCurrentJapaneseEra } from "../../utils"
import { Modal, SignatureCanvas } from "../../components"
import { DEFAULT_DATE_SIGNATURE_PAD_IMAGE } from "../../constants"

export const PlanContentFooter = ({
  defaultData,
  operationData,
  planId,
  saveSignaturesMutation,
  isSignatureSaving,
  openSignatureModal,
  setOpenSignatureModal,
}: {
  defaultData: any
  operationData?: PlanOperationData
  planType: PlanType
  planId?: number
  isSignatureSaving: boolean
  saveSignaturesMutation: (values) => void
  openSignatureModal?: boolean
  setOpenSignatureModal: React.Dispatch<React.SetStateAction<boolean>>
}): JSX.Element => {
  const { t } = useTranslation()
  const [signatures, setSignatures] = useState({
    digital_signature_image: "", //parent signature
    user_digital_signature_image: "",
    manager_digital_signature_image: "",
    explanation_date_digital_signature_image: "",
    consent_date_digital_signature_image: "",
  })
  const [signProps, setSignProps] = useState<{
    sign: string
    dateSign: string
    signType: "date" | "name" | "both"
    signOf: string
  }>({
    sign: "",
    dateSign: "",
    signType: "name",
    signOf: "",
  })
  useEffect(() => {
    setSignatures({
      digital_signature_image:
        defaultData?.userSupport?.digital_signature_image || "",
      user_digital_signature_image:
        defaultData?.userSupport?.user_digital_signature_image || "",
      manager_digital_signature_image:
        defaultData?.userSupport?.manager_digital_signature_image || "",
      explanation_date_digital_signature_image:
        defaultData?.userSupport?.explanation_date_digital_signature_image ||
        "",
      consent_date_digital_signature_image:
        defaultData?.userSupport?.consent_date_digital_signature_image || "",
    })
  }, [defaultData])

  const renderSignature = (url) => (
    <>{url ? <img src={url} alt={"signature"} id={"sign-img"} /> : ""}</>
  )
  const saveSignatures = (key: string, val: string) => {
    saveSignaturesMutation({
      ...signatures,
      [key]: val,
      user_id: +defaultData?.userSupport?.user_id,
      planId: +planId,
      facility_id: +defaultData?.userSupport?.facility_id,
    })
  }

  return (
    <>
      <FooterWrapper>
        <div className={"left__container"}>
          {operationData?.display_content?.includes("3") && (
            <Typography.Paragraph
              style={{
                margin: 0,
              }}
            >
              {t(operationData?.statementText?.consentStatement)}
            </Typography.Paragraph>
          )}
          {operationData?.display_content?.includes("1") && (
            <>
              {operationData?.consent_date?.length ? (
                <>
                  {operationData?.consent_date?.includes("2") ? (
                    <>
                      <div className={"content__container consent_date"}>
                        <div className={"content"}>
                          <div className={"content--image"}>
                            <Typography.Paragraph
                              style={{
                                margin: 0,
                              }}
                            >
                              {t("Consent date")}
                            </Typography.Paragraph>
                            {renderSignature(
                              signatures?.consent_date_digital_signature_image,
                            )}
                          </div>

                          <div className={"content--icon"}>
                            <EditOutlined
                              onClick={() => {
                                setSignProps({
                                  ...signProps,
                                  signType: "date",
                                  signOf:
                                    "consent_date_digital_signature_image",
                                  dateSign:
                                    defaultData?.userSupport
                                      ?.consent_date_digital_signature_image,
                                })
                                setSignatures({
                                  ...signatures,
                                  consent_date_digital_signature_image:
                                    defaultData?.userSupport
                                      ?.consent_date_digital_signature_image,
                                })
                                setOpenSignatureModal(true)
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      <div className={"content__container explanation_date"}>
                        <div className={"content"}>
                          <div className={"content--image"}>
                            <Typography.Paragraph
                              style={{
                                margin: 0,
                              }}
                            >
                              {t("Explanation date")}
                            </Typography.Paragraph>
                            {renderSignature(
                              signatures?.explanation_date_digital_signature_image,
                            )}
                          </div>
                          <div className={"content--icon"}>
                            <EditOutlined
                              onClick={() => {
                                setSignProps({
                                  ...signProps,
                                  signType: "date",
                                  signOf:
                                    "explanation_date_digital_signature_image",
                                  dateSign:
                                    defaultData?.userSupport
                                      ?.explanation_date_digital_signature_image,
                                })
                                setSignatures({
                                  ...signatures,
                                  explanation_date_digital_signature_image:
                                    defaultData?.userSupport
                                      ?.explanation_date_digital_signature_image,
                                })
                                setOpenSignatureModal(true)
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    <div className={"content__container"}>
                      <div className={"date"}>
                        <Typography.Text>
                          {t("Explanation date")}
                        </Typography.Text>
                        <div className={"date--info"}>
                          <span>{getCurrentJapaneseEra()}</span>
                          <span>{t("Year")}</span>
                          <span>{t("Month")}</span>
                          <span>{t("Day")}</span>
                        </div>
                      </div>
                      <div className={"date"}>
                        <Typography.Text>{t("Consent date")}</Typography.Text>
                        <div className={"date--info"}>
                          <span>{getCurrentJapaneseEra()}</span>
                          <span>{t("Year")}</span>
                          <span>{t("Month")}</span>
                          <span>{t("Day")}</span>
                        </div>
                      </div>
                    </div>
                  )}
                </>
              ) : (
                <div className={"content__container"}>
                  <div className={"date"}>
                    <Typography.Text>{t("Consent date")}</Typography.Text>
                    <div className={"date--info"}>
                      <span>{getCurrentJapaneseEra()}</span>
                      <span>{t("Year")}</span>
                      <span>{t("Month")}</span>
                      <span>{t("Day")}</span>
                    </div>
                  </div>
                </div>
              )}
            </>
          )}

          {operationData?.display_content?.includes("4") && (
            <div className={"content__container"}>
              <div className={"content"}>
                <div className={"content--image"}>
                  <Typography.Paragraph
                    style={{
                      margin: 0,
                    }}
                  >
                    {t("User name")}
                  </Typography.Paragraph>
                  {renderSignature(signatures?.user_digital_signature_image)}
                </div>
                <div className={"content--icon"}>
                  <EditOutlined
                    onClick={() => {
                      setSignProps({
                        ...signProps,
                        signType: "name",
                        signOf: "user_digital_signature_image",
                        sign: defaultData?.userSupport
                          ?.user_digital_signature_image,
                      })
                      setSignatures({
                        ...signatures,
                        user_digital_signature_image:
                          defaultData?.userSupport
                            ?.user_digital_signature_image,
                      })
                      setOpenSignatureModal(true)
                    }}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
        <div className={"right__container"}>
          <div className={"right__container--content"}>
            {operationData?.parent_sign?.includes("1") && (
              <Typography.Paragraph
                style={{
                  margin: 0,
                }}
              >
                {t(operationData?.statementText?.consentStatementForChild)}
              </Typography.Paragraph>
            )}

            <div>{defaultData?.userSupport?.facility?.facility_name}</div>
            <div className={"content--support"}>
              <div className={"sign__field"}>
                <div>{t("Adult development support manager")}</div>
                {operationData?.display_content?.includes("8") ? (
                  <>
                    {renderSignature(
                      signatures?.manager_digital_signature_image,
                    )}
                    <EditOutlined
                      className={"signatures__EditOutlined icon"}
                      onClick={() => {
                        setSignProps({
                          ...signProps,
                          signOf: "manager_digital_signature_image",
                          sign: defaultData?.userSupport
                            ?.manager_digital_signature_image,
                        })
                        setSignatures({
                          ...signatures,
                          manager_digital_signature_image:
                            defaultData?.userSupport
                              ?.manager_digital_signature_image,
                        })
                        setOpenSignatureModal(true)
                      }}
                    />
                  </>
                ) : (
                  <>
                    <p className={"user-dev-support-manager"}>
                      {defaultData?.userSupport?.staff?.staff_name
                        ? defaultData?.userSupport?.staff?.staff_name
                        : ""}{" "}
                    </p>
                  </>
                )}
              </div>
              {operationData?.display_content?.includes("7") && (
                <div>{t("mark")}</div>
              )}
            </div>
          </div>
        </div>
      </FooterWrapper>
      {/* signature modal */}
      {openSignatureModal && (
        <Modal
          destroyOnClose
          open={openSignatureModal}
          title={t("Confirmation signature")}
          onCancel={() => {
            setOpenSignatureModal(false)
          }}
          footer={null}
          bodyPadding={"0px"}
        >
          <SignatureCanvas
            sign={signProps?.sign}
            dateSign={signProps?.dateSign}
            signType={signProps?.signType}
            onSave={(value) => {
              saveSignatures(signProps?.signOf, value)
            }}
            isLoading={isSignatureSaving}
            DEFAULT_DATE_SIGNATURE_PAD_IMAGE={DEFAULT_DATE_SIGNATURE_PAD_IMAGE}
          />
        </Modal>
      )}
    </>
  )
}
