// import { japaneseAlphaRegex } from "./validation"

//  const USER_OPTIONS = startChar
//    ? [
//        ...user?.filter(
//          (user) =>
//            user.furigana?.startsWith(startChar) ||
//            user.label?.startsWith(startChar),
//        ),
//      ]
//    : user
export const filterWithHiragana = ({
  options,
  furigana,
}: {
  options: any[]
  furigana: string | null
}) => {
  // const regex = japaneseAlphaRegex(furigana) //for future reference

  const filteredData = furigana
    ? options.filter(
        (data) =>
          data?.furigana?.startsWith(furigana) ||
          data?.label?.startsWith(furigana),
      )
    : options
  return filteredData || []
}
