import { RightOutlined } from "@ant-design/icons"
import styled from "styled-components"
import { theme } from "../../theme"
export interface LabelTextProps {
  borderColor?: string
  label: string
  width?: string
  className?: string
}

const StyledLeftBorder = styled.div`
  ${theme.typography.header};
  border-left: 3px solid ${theme.colors.action};
  padding-left: 6px;
`
const StyledLeftArrow = styled.div<any>`
  display: flex;
  align-items: center;

  span {
    ${theme.typography["body-regular"]};
    font-weight: 500 !important;
  }
  svg {
    height: 10px;
    color: ${theme.colors.border};
  }
  .label-text {
    ${(props) => (props?.width ? `width:${props?.width};` : "136px")}
  }
  @media screen and (max-width: ${theme.breakpoints.md}) {
    margin-bottom: 4px !important;
  }
`
const LabelTextWithLeftBorder = ({ label }: LabelTextProps): JSX.Element => {
  return <StyledLeftBorder>{label}</StyledLeftBorder>
}
LabelTextWithLeftBorder.displayName = "LabelTextWithLeftBorder"

const LabelTextWithLeftArrow = ({
  label,
  width,
  className,
}: LabelTextProps): JSX.Element => {
  return (
    <StyledLeftArrow className={className} width={width}>
      <RightOutlined />
      <span className={"label-text"}> {label}</span>
    </StyledLeftArrow>
  )
}
LabelTextWithLeftArrow.displayName = "LabelTextWithLeftArrow"
export { LabelTextWithLeftArrow, LabelTextWithLeftBorder }
