// Easyy theme
import { EasyyTheme } from "../../../theme"

// utility functions
import { keys } from "../../../utils/keys"
import { resolvers } from "../resolvers"

// types
import { EasyyBreakPoint, EasyyBreakPointsValues, StyleProp } from "../types"
import { SystemPropData } from "../styleProps/style-props-data"

// types
export interface InlineStylesMediaQuery {
  query: string
  styles: React.CSSProperties
}

export interface SortMediaQueriesResult
  extends Omit<ParseStylePropsResult, "media"> {
  media: InlineStylesMediaQuery[]
}

interface ParseStylePropsOptions {
  styleProps: Record<string, StyleProp<any>>
  theme: EasyyTheme
  data: Record<string, SystemPropData>
}

export interface ParseStylePropsResult {
  hasResponsiveStyles: boolean
  inlineStyles: React.CSSProperties
  styles: React.CSSProperties
  media: Record<string, React.CSSProperties>
}

// This function is used to convert the media query string to a actual value only i.e. without "px" and "(min-width: " and ")"
function replaceMediaQuery(query: string) {
  return query.replace("(min-width: ", "").replace("px)", "")
}

/**
 *
 * @param param example: `{ media: { "(min-width: 1200px)": { margin: "40px" } } }`
 * @returns In this example, it will return `{ media: [{ query: "(min-width: 1200px)", styles: { margin: "40px" } }] }`
 */
export function sortMediaQueries({
  media,
  ...props
}: ParseStylePropsResult): SortMediaQueriesResult {
  const breakpoints = keys(media)
  const sortedMedia = breakpoints
    .sort((a, b) => Number(replaceMediaQuery(a)) - Number(replaceMediaQuery(b)))
    .map((query) => ({ query, styles: media[query] }))

  return { ...props, media: sortedMedia }
}

/**
 *
 * @param styleProp example:
 * case 1: {bg : "pink", display : "flex", m :{xs: 'auto', md: 'lg'}}
 * @returns In this example, for display and bg it will return false
 * for m it will return true
 */
function hasResponsiveStyles(styleProp: StyleProp<unknown>) {
  if (typeof styleProp !== "object" || styleProp === null) {
    return false
  }

  const breakpoints = Object.keys(styleProp)

  if (breakpoints.length === 1 && breakpoints[0] === "base") {
    return false
  }

  return true
}

function getBaseValue(value: StyleProp<unknown>) {
  if (typeof value === "object" && value !== null) {
    if ("base" in value) {
      return value.base
    }

    return undefined
  }

  return value
}

/**
 *
 * @param value example: breakpoints with values: {xs: 'auto', md: 'lg'}
 * @returns In this example, it will return ["xs", "md"]
 */
function getBreakpointKeys(value: StyleProp<unknown>) {
  if (typeof value === "object" && value !== null) {
    return keys(value).filter((key) => key !== "base")
  }

  return []
}

/**
 *
 * @param value example: breakpoints with values: {xs: 'auto', md: 'lg'}
 * @param breakpoint example: "md" | "xs"
 * @returns In this example, if breakpoint is "md" then it will return "lg" and if breakpoint is "xs" then it will return "auto"
 */
function getBreakpointValue(value: StyleProp<unknown>, breakpoint: string) {
  if (typeof value === "object" && value !== null && breakpoint in value) {
    return value[breakpoint as keyof typeof value]
  }

  return value
}

/**
 *
 * @param breakpoint
 * @returns if breakpoint used is available in theme.breakpoints we are sending its respective value
 * or else we are simply return breakpoint itself.
 */
function getBreakpoint(
  value: EasyyBreakPoint,
  breakpoints: EasyyBreakPointsValues,
): string {
  if (value in breakpoints) {
    return breakpoints[value]
  }
  return value
}

export function parseStyleProps({
  // This is the style prop object that is passed to
  // the component that is specific to easyy component props like:
  // m, mt, mb, ml, mr, mx, my, p, pt, pb, pl, pr, px, py, bg, c, opacity, etc
  // This is how this object looks like:
  // {
  //  bg : "pink",
  //  display : "flex",
  //  m :{xs: 'auto', md: 'lg'}
  //  pl : "23px"
  //  px : "23px"
  // }
  styleProps,
  data,
  theme,
}: ParseStylePropsOptions): SortMediaQueriesResult {
  return sortMediaQueries(
    keys(styleProps).reduce<{
      hasResponsiveStyles: boolean
      inlineStyles: Record<string, unknown>
      styles: Record<string, unknown>
      media: Record<string, Record<string, unknown>>
    }>(
      (acc, styleProp) => {
        if (
          (styleProp as string) === "hiddenFrom" ||
          (styleProp as string) === "visibleFrom"
        ) {
          return acc
        }

        const propertyData = data[styleProp]
        const properties = Array.isArray(propertyData.property)
          ? propertyData.property
          : [propertyData.property]

        const baseValue = getBaseValue(styleProps[styleProp])

        if (!hasResponsiveStyles(styleProps[styleProp])) {
          properties.forEach((property) => {
            acc.inlineStyles[property] = resolvers[propertyData.type](
              baseValue,
              theme,
            )
          })

          return acc
        }

        acc.hasResponsiveStyles = true

        const breakpoints = getBreakpointKeys(styleProps[styleProp])

        properties.forEach((property) => {
          if (baseValue) {
            acc.styles[property] = resolvers[propertyData.type](
              baseValue,
              theme,
            )
          }
          // incase of mx/marginInline( marginRight and marginLeft), we want media query like this :
          // -> (min-width: 1200px) { margin-right: 40px; margin-left: 40px; }
          //
          // that's why we are looping over properties i.e. in this case [marginRight, marginLeft]
          // and then looping over breakpoints i.e. in this case ["xs", "md"]
          //
          // and then adding the value of marginRight and marginLeft for each breakpoint
          // as you can see where we are spreading (...acc.media[bp]) we are adding the value of marginRight and marginLeft in the same media query
          // which becomes like this:
          // -> (min-width: 1200px) { margin-right: 40px; margin-left: 40px; }
          breakpoints.forEach((breakpoint) => {
            const bp = `(min-width: ${getBreakpoint(
              breakpoint,
              theme.breakpoints,
            )})`
            acc.media[bp] = {
              ...acc.media[bp],
              [property]: resolvers[propertyData.type](
                getBreakpointValue(styleProps[styleProp], breakpoint),
                theme,
              ),
            }
          })
        })

        return acc
      },
      {
        hasResponsiveStyles: false,
        styles: {},
        inlineStyles: {},
        media: {},
      },
    ),
  )
}
