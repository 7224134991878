import { theme } from "@project/common"
import { createGlobalStyle } from "styled-components"

const GlobalStyles: any = createGlobalStyle`
  * {
  margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-size: 14px;
    font-family: "Noto Sans JP", serif;

  }

  button {
    cursor: pointer;
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  div .ant-popover{
    width: 67%;
    max-width: 325px;
  }
.service__record_sheet_modal{
  .ant-modal-content{
    .ant-modal-close{
      span{
          color:#FFF;
      }
    }
    .ant-modal-header{
      background: #06BCD4 !important;
      .ant-modal-title{
        color:#FFF;
      }
    }
  }
}

.ant-notification-notice-with-icon .ant-notification-notice-message {
    margin-bottom: 4px;
    margin-left: 27px;
    font-size: 16px;
}
.ant-notification-notice-wrapper{
  .ant-notification-notice-with-icon .anticon {
    height: 25px;
    width: 22px;
    display: grid;
    place-content: center;
  }
  .ant-notification-notice-close{
    height: 25px !important;
  }
}


.ant-modal-wrap.instructor__info-modal {
@media screen and (max-width: 767px) and  (max-height:400px) {
  position: absolute !important;
    top:20px  !important;
    overflow: unset !important;
  }
}
.staff-modal > div > .ant-modal-content > .ant-modal-body{
  padding: 0px !important ;

}
.ant-modal-wrap.instructor__occu_info-modal {
  @media screen and (max-width: 767px) and  (max-height:400px) {
    position: absolute !important;
    top:20px  !important;
    overflow: unset !important;
  }

}

.padding_zero{
  padding:0 !important
}
/* date picker global styled start HERE */
.eassy_adult_date_picker{
   .ant-picker-panel-container {
        box-shadow: none;
        border-radius: 12px;
        border: 1px solid #D9DEE0;

        .ant-picker-cell-inner{
        height: 100% !important;
        width: 100% !important;
        display: flex  !important;
        justify-content: center !important;
        align-items: center !important;
      }
      }
      .ant-picker-cell {
        border: 1px solid #D9DEE0;
      }
      .ant-picker-cell:not(.ant-picker-cell-in-view) {
        background: #F1F4F5;
      }
      .ant-picker-cell:not(.ant-picker-cell-in-view) .ant-picker-cell-inner {
        visibility: hidden;
      }
      .ant-picker-cell-selected {
        background: #ECDCDD;
      }
      .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner {
        background: transparent;
        color: #000000;
      }
      .ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
        border: none;
      }

      .ant-picker-header {
        position: relative;
        padding:6px !important;
        .ant-picker-header-view{
          button{
            vertical-align: middle !important;
          }
        }
      }
      /* .ant-picker-header-prev-btn {
        position: absolute;
        left: 10px;
        margin-top: -5px !important;
        display: none;
      }
      .ant-picker-header-next-btn {
        position: absolute;
        right: 10px;
        margin-top: -5px !important;
        display: none;
      } */
      .ant-picker-header, .ant-picker-footer-extra:not(:last-child) {
        border-bottom: none;
      }
      .ant-picker-footer {
        border-top: none !important;
      }
      .faculty-datepicker-custom-footer {
        display: flex;
        justify-content: space-between;
        padding: 16px 0;
        align-items: center;
      }
      .faculty-datepicker-custom-footer-first {
        width: 50%;
        display: flex;
        justify-content: space-between;
      }
      .ant-picker-year-btn, .ant-picker-month-btn {
        position: relative;
        border: 1px solid #D2D1D1 !important;
        border-radius: 5px;
        padding: 4px 10px 4px 6px !important;
        line-height: 1.5 !important;
        width: 80px;
      }
      .ant-picker-month-btn {
        min-width: 75px;
      }
      .ant-picker-year-btn:after, .ant-picker-month-btn:after {
        content: '${"\\2304"}';
        position: absolute;
        right: 4px;
        top: 0px;
      }

      .ant-picker-cell-today:not(.ant-picker-cell-selected) {
        background: #fbf5ee;
      }
    .ant-picker > .ant-picker-input >input::placeholder{
        font-size:12px
    }
      @media (max-width: 768px) {
        .faculty-datepicker-mobile-open:after {
            content: "";
            position: fixed;
            top: 0;
            left: 0;
            width: 100vw;
            height: 100vh;
            background: rgba(0,0,0,0.6);
            z-index: 99;
        }
        .ant-picker-dropdown {
            position: fixed;
            top: calc(50vh - 220px) !important;
            left: calc(50vw - 140px) !important;
        }
      }

}
@media screen and (max-height:  768px) {
      .date-picker-popup-height-responsive{
        .ant-picker-panel-container{
          .ant-picker-header{
            padding:0 6px !important
          }
          .ant-picker-body{
            padding:0px 18px !important;
            .ant-picker-content{
              display: table;
              table-layout: fixed;
              width: 100%;
              height:150px;
              position: relative;
              thead{
                tr{
                  th{
                      height: fit-content !important;
                  }
                }
              }
              tbody{
                tr{
                  td{
                    height: 10% !important;
                  }
                }
              }
            }
          }
          .ant-picker-footer{
            .faculty-datepicker-custom-footer{
              padding:0 !important
            }
          }
        }
      }
 }

 /* date picker global styled end HERE */

@media screen  and (max-width: 368){
  font-size: 12px !important;
}

.employment_certificate_modal{
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 6px
}
.eassy-toast-icon{
  .ant-notification-notice-message{
    padding: 0px 25px 0px 0px !important;
  }
  svg{
   font-size:24px !important;
  }
}

.hide-in-print-mode {
  @media print {
      display: none !important;
  }
}

.leave-request-root-container{
  width: 381px !important;
  max-width: 381px !important;
  .ant-popover-content {
      .ant-popover-inner {
        border: 1px solid ${theme.colors.border};
        border-radius: 12px;
        padding: 16px 24px;
      }
    }
}
.login-error-notification{
  .ant-notification-notice-message{
  padding-right:36px !important;
  }

}
`

export { GlobalStyles }
