// easyy theme
import { EasyyTheme } from "../../../theme"

// types
import { EasyyStyleProp } from "../types"
interface GetBoxStyleOptions {
  theme: EasyyTheme
  styleProps: React.CSSProperties
  style?: EasyyStyleProp
}

function mergeStyles(
  styles: EasyyStyleProp | undefined,
  theme: EasyyTheme,
): React.CSSProperties {
  if (Array.isArray(styles)) {
    return [...styles].reduce<Record<string, any>>(
      (acc, item) => ({ ...acc, ...mergeStyles(item, theme) }),
      {},
    )
  }

  if (styles == null) {
    return {}
  }

  return styles
}

export function getBoxStyle({
  theme,
  style,
  styleProps,
}: GetBoxStyleOptions): React.CSSProperties {
  const _style = mergeStyles(style, theme)
  return { ..._style, ...styleProps }
}
