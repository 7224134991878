import React from "react"
import styled from "styled-components"
import { theme } from "../../theme"

const Wrapper = styled.footer`
  background-color: ${theme.colors.white};
  height: 40px;
  padding: 0 36px;
  display: grid;
  align-items: center;
  grid-template-columns: 1fr 1fr 1fr;
  @media screen and (max-width: ${theme.breakpoints.md}) {
    grid-template-columns: 1fr;
    padding: 10px 36px;
  }
  ul {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 2em;
    list-style: none;
    li {
      font-weight: 400;
      font-size: 14px !important;
      @media screen and (max-width: ${theme.breakpoints.sm}) {
        font-size: 12px !important;
      }
    }
  }
  .content-flex-end {
    display: flex;
    justify-content: end;
    @media screen and (max-width: ${theme.breakpoints.md}) {
      justify-content: center;
    }
  }
`
const Footer = (): JSX.Element => {
  return (
    <Wrapper>
      <div></div>
      <ul></ul>
      <div className={"content-flex-end"}>{"Copyright © Onelife.Inc"}</div>
    </Wrapper>
  )
}
Footer.displayName = "Footer"
export { Footer }
