export { GlobalStyles } from "./globalstyles"
export * from "./filterWithHiragana"
export * from "./queryStrToNumArray"
export * from "./getFuriFilteredOptions"
export * from "./transformDriverStaffs"
export * from "./breakRecordToArray"
export * from "./setOperationInitialFormikValues"
export * from "./lowercaseFirstChar"
export const bodyTemperatureOptions = (start: number, end: number) => {
  const result = []
  result.push({ label: "--", value: "" })
  for (let i = start; i <= end; i++) {
    result.push({
      label: `${i}`,
      value: `${i}`,
    })
  }
  return result
}

//get the failure type index
export const getFailureTypesIndex = (data: any) => {
  const failureTypeIndex = []
  if (data?.physical_disability_flag) {
    failureTypeIndex.push(0)
  }
  if (data?.intellectual_disability_flag) {
    failureTypeIndex.push(1)
  }
  if (data?.mental_disability_flag) {
    failureTypeIndex.push(2)
  }
  if (data?.development_disability_flag) {
    failureTypeIndex.push(3)
  }
  if (data?.brain_disfunction_flag) {
    failureTypeIndex.push(4)
  }
  if (data?.incurable_disease_flag) {
    failureTypeIndex.push(5)
  }
  return failureTypeIndex || []
}
