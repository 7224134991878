import React from "react"
import styled from "styled-components"
import { Button } from "../Button/Button"

const FiscalPaginationWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 5px 0px;
  .year-pagination-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    button {
      background: white;
      color: black;
      border: 1px solid #0782c8;
      border-radius: 10px;
      height: 40px;

      &.active {
        background: rgba(7, 130, 200, 0.2);
      }
    }
    .common-btn-styling-right {
      display: flex;
      gap: 7px;
    }
  }
  @media (max-width: 900px) {
    .year-pagination-wrapper {
      display: flex;
    }
  }
  @media print {
    display: none;
  }
`
interface IFiscalPagination {
  setDate: (v: any) => void
  currentDate: number | null
  activeText: string
  setActiveText: (v: any) => void
  currentMonth: number | null
  setCurrentMonth: (v: any) => void
  setCurrentDate: (v: any) => void
  setParams: (v: any) => void
  params: any
}

const FiscalYearPagination: React.FC<IFiscalPagination> = ({
  currentDate,
  activeText,
  setActiveText,
  currentMonth,
  setCurrentMonth,
  setCurrentDate,
  setDate,
  setParams,
  params,
}) => {
  const handleNextButtonClick = () => {
    setActiveText("nextYear")
    if (currentMonth === 12) {
      setCurrentDate(currentDate + 1)
      setDate(String(currentDate + 1))
      setCurrentMonth(1)
      setParams({ ...params, date: currentDate + 1, month: 1 })
    } else {
      setCurrentMonth(currentMonth + 1)
      setParams({ ...params, date: currentDate, month: currentMonth + 1 })
    }
  }
  const handlePreviousButtonClick = () => {
    setActiveText("previousYear")
    if (currentMonth === 1) {
      setCurrentDate(currentDate - 1)
      setDate(String(currentDate - 1))
      setCurrentMonth(12)
      setParams({ ...params, date: currentDate - 1, month: 12 })
    } else {
      setCurrentMonth(currentMonth - 1)
      setParams(currentMonth - 1)
      setParams({ ...params, date: currentDate, month: currentMonth - 1 })
    }
  }
  const handleCurrentButtonClick = () => {
    setActiveText("currentYear")
    const currentYear = new Date().getFullYear()
    const currentMonths = new Date().getMonth() + 1
    const myYear = currentMonths > 4 ? currentYear - 1 : currentYear
    setCurrentMonth(currentMonths)
    setCurrentDate(myYear)
    setDate(String(myYear))
    setParams({ ...params, date: myYear, month: currentMonths })
  }

  return (
    <FiscalPaginationWrapper>
      <div className={"year-pagination-wrapper"}>
        <Button
          btnText={"前年"}
          onClick={() => handlePreviousButtonClick()}
          className={`left-btn common-btn-styling ${
            activeText === "previousYear" ? "active active-btn" : ""
          }`}
          iconType={"backward-arrow"}
        />

        <Button
          btnText={"今年"}
          onClick={() => handleCurrentButtonClick()}
          className={` active-btn common-btn-styling ${
            activeText === "currentYear" ? "active active-btn" : ""
          }`}
        />

        <Button
          btnText={"翌年"}
          onClick={() => handleNextButtonClick()}
          className={`right-btn common-btn-styling-right ${
            activeText === "nextYear" ? "active active-btn" : ""
          }`}
          iconType={"frontward-arrow"}
        />
      </div>
    </FiscalPaginationWrapper>
  )
}

export { FiscalYearPagination }
