import styled from "styled-components"
import { theme } from "../../theme"

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`

export const Container = styled.div`
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  .top-content-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    h3 {
      margin: 0;
      font-size: 18px;
      font-weight: 700;
      padding: 0;
      margin-top: 8px;
    }
    .logo-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 90px;
      width: 90px;
      background: ${theme.colors.white} !important;
      border-radius: 50%;
    }
  }
`
export const LoginWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid #d2d1d1;
  background: #ffffff;
  padding: 2em;
  border-radius: 16px;
  height: auto;
  width: fit-content;
  margin-top: 16px;
  @media screen and (max-width: ${theme.breakpoints.sm}) {
    width: 95%;
  }
`
export const TextFieldWrapper = styled.div`
  .forget-password-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 12px;
    p {
      margin: auto 0px;
      font-size: 14px;
      padding: 0;
    }
    a {
      text-decoration: underline;
      margin-left: 5px;
      font-size: 14px;
      color: #0782c8;
    }
  }
  .login-button-wrapper {
    button {
      width: 100%;
      height: 48px;
      background-color: ${theme.colors.action};
      border: none;
      /* padding: 10px 20px 10px 20px !important; */
      border-radius: 8px;
      color: ${theme.colors.white};
      font-size: 18px;
    }
  }
  .password-info-text {
    font-size: 10px;
    color: #191919;
  }
  .text-field {
    label {
      font-weight: 100 !important;
      font-size: 14px !important;
      color: #191919 !important;
    }
    input {
      ::-ms-input-placeholder {
        color: #191919 !important;
        font-size: 18px !important;
      }
      ::placeholder {
        color: #191919 !important;
        font-size: 18px !important;
      }
    }
  }
`
export const InputFieldWrapper = styled.div`
  margin-bottom: 16px;

  .password-info-text {
    margin-top: 6px;
  }

  .password-wrapper {
    position: relative;
  }
  .visible-icon {
    position: absolute;
    top: 40px;
    right: 10px;
    cursor: pointer;
  }
`
