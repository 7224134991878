import React from "react"
import {
  Breadcrumb as AntBreadcrumb,
  BreadcrumbProps,
  ConfigProvider,
} from "antd"
import styled from "styled-components"
import { RightOutlined } from "@ant-design/icons"
import { theme } from "../../theme"

interface Props extends BreadcrumbProps {
  linkColor?: string
  lastItemColor?: string
  separatorColor?: string
  itemColor?: string
  iconFontSize?: number
  linkHoverColor?: string
  separatorMargin?: number
}

//styles
const StyledAntBreadcrumb = styled(AntBreadcrumb)<Props>`
  .ant-breadcrumb-link {
    font-size: 12px !important;
    a {
      font-size: 12px !important;
      text-decoration: underline !important;
      &:hover {
        background-color: transparent !important;
      }
    }
  }
  .ant-breadcrumb-separator {
    svg {
      height: 10px !important;
      color: ${theme.colors["sub-text"]} !important;
    }
  }
  @media print {
    display: none;
  }
`

const Breadcrumb = ({
  linkColor,
  lastItemColor,
  separatorColor,
  itemColor,
  iconFontSize,
  linkHoverColor,
  separatorMargin,
  ...props
}: Props): JSX.Element => {
  return (
    <ConfigProvider
      theme={{
        components: {
          Breadcrumb: {
            linkColor: linkColor || theme.colors.link,
            lastItemColor: lastItemColor || theme.colors.text,
            separatorColor,
            itemColor,
            iconFontSize,
            linkHoverColor: linkHoverColor || theme.colors.action,
            separatorMargin: separatorMargin || 3,
          },
        },
      }}
    >
      <StyledAntBreadcrumb {...props} separator={<RightOutlined />} />
    </ConfigProvider>
  )
}
Breadcrumb.displayName = "Breadcrumb"
export { Breadcrumb }
