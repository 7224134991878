import React from "react"

// components
import { InlineStyles } from "./common/InlineStyles"

// utils
import { getBoxStyle } from "./utils/extractStyles"
import { parseStyleProps } from "./utils/parseStyleProps"
import { extractStyleProps } from "./utils/extractStyleProps"
import { useRandomClassName } from "./utils/userRandomClassname"

// easyy theme
import { theme } from "../../theme"

// constants
import { STYlE_PROPS_DATA } from "./styleProps/style-props-data"

// types
import { EasyyStyleProp, EasyyStyleProps } from "./types"
export type BoxProps = EasyyStyleProps & {
  /** Class added to the root element, if applicable */
  className?: string

  /** Inline style added to root component element, can subscribe to theme defined on EasyyProvider */
  style?: EasyyStyleProp
}
export type BoxCompProp = BoxProps & {
  component?: any
  className?: string
  children: React.ReactNode
  onClick?: React.MouseEventHandler<HTMLDivElement>
}

export const _Box = React.forwardRef<HTMLDivElement, BoxCompProp>(
  ({ component, style, className, ...others }, ref) => {
    const Element = component || "div"
    const responsiveClassName = useRandomClassName()
    const { styleProps, rest } = extractStyleProps(others)

    const parsedStyleProps = parseStyleProps({
      styleProps,
      theme,
      data: STYlE_PROPS_DATA,
    })

    const props = {
      ...rest,
      style: getBoxStyle({
        theme,
        style,
        styleProps: parsedStyleProps.inlineStyles,
      }),
      className: `${className || ""} ${
        // this unique class is very important for our inline media queries to work,
        // we use it as a selector to properly select the element and apply the styles
        parsedStyleProps.hasResponsiveStyles ? responsiveClassName : ""
      }`,
      ref,
    }

    return (
      <>
        {parsedStyleProps.hasResponsiveStyles && (
          <InlineStyles
            selector={`.${responsiveClassName}`}
            styles={parsedStyleProps.styles}
            media={parsedStyleProps.media}
          />
        )}
        <Element {...props} />
      </>
    )
  },
)

_Box.displayName = "@common/Box"
export const Box = _Box
