import React, { ReactNode } from "react"
import Head from "next/head"
import * as yup from "yup"
import { useTranslation } from "react-i18next"
import { Button } from "antd"
import { useFormik } from "formik"
import Link from "next/link"
import {
  Container,
  InputFieldWrapper,
  LoginWrapper,
  TextFieldWrapper,
  Wrapper,
} from "./Login.style"

import { Footer } from "../UnAuthorizedLayout/Footer"
import { InputField } from "../../components"

interface ResetPasswordType {
  new_password: string
  confirm_password: string
}

const ResetPassword = ({
  logo,
  loading,
  handleSubmit,
  isSuccess,
  isValidLink,
}: {
  logo: ReactNode
  isSuccess: boolean
  loading: boolean
  isValidLink: boolean
  handleSubmit: (values: any) => void
}) => {
  const { t } = useTranslation()
  const validationSchema = yup.object().shape({
    new_password: yup
      .string()
      .required(t("Please enter"))
      .min(6, t("6 or more alphanumeric characters")),
    confirm_password: yup
      .string()
      .required(t("Please enter"))
      .oneOf([yup.ref("new_password")], t("Your password does not match")),
  })
  const formik = useFormik<ResetPasswordType>({
    initialValues: {
      new_password: "",
      confirm_password: "",
    },
    validationSchema,
    onSubmit: (values) => handleSubmit(values),
  })
  return (
    <>
      <Head>
        <title>
          {t("施設管理画面ログイン")}
          {" | "}
          {t("easyy admin")}
        </title>
      </Head>
      <Wrapper>
        <Container style={{ flex: 1 }}>
          <div className={"top-content-wrapper"}>
            <div className={"logo-wrapper"}>{logo}</div>
            <h3>{t("Reset your password")}</h3>
          </div>
          <LoginWrapper>
            {isSuccess ? (
              <>
                {isValidLink
                  ? t("Link has been expired")
                  : t(
                      "パスワード変更が完了いたしました。下記よりログインしてください。",
                    )}
              </>
            ) : (
              <TextFieldWrapper>
                <form onSubmit={formik.handleSubmit}>
                  <InputFieldWrapper>
                    <InputField
                      name={"new_password"}
                      type={"password"}
                      error={
                        formik.touched.new_password &&
                        formik.errors.new_password
                      }
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      label={t("Password")}
                      placeholder={t("Enter Password")}
                      className={"text-field"}
                      height={"48px"}
                      width={"368px"}
                      borderRadius={"8px"}
                      bg={"transparent"}
                    />
                    <p className={"password-info-text"}>
                      {t("Password must be more than 6 characters")}
                    </p>
                  </InputFieldWrapper>

                  <InputFieldWrapper>
                    <InputField
                      name={"confirm_password"}
                      type={"password"}
                      error={
                        formik.touched.confirm_password &&
                        formik.errors.confirm_password
                      }
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      label={t("Confirm Password")}
                      placeholder={t("Enter Password")}
                      className={"text-field"}
                      height={"48px"}
                      width={"368px"}
                      borderRadius={"8px"}
                      bg={"transparent"}
                    />
                    <p className={"password-info-text"}>
                      {t("Password must be more than 6 characters")}
                    </p>
                  </InputFieldWrapper>

                  <div className={"login-button-wrapper"}>
                    <Button
                      htmlType={"submit"}
                      loading={loading}
                      shape={"round"}
                    >
                      {t("Reset")}
                    </Button>
                  </div>

                  <div className={"forget-password-wrapper"}>
                    <Link href={"/login"}>{t("Go to Login")}</Link>
                  </div>
                </form>
              </TextFieldWrapper>
            )}
          </LoginWrapper>
        </Container>
        <Footer />
      </Wrapper>
    </>
  )
}
ResetPassword.displayName = "ResetPassword"
export { ResetPassword }
