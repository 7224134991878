import { InitialLoader } from "@project/common"
import { SIDE_BAR_PROPS, SIDE_TOGGLE_PROPS } from "@project/common/src/types"
import { createContext, useContext, useEffect, useState } from "react"

type ContextProps = {
  loading: boolean
  user: any | null
  authenticated: boolean
  setUser: any
  isOwner: boolean
  permissions: any
  facilities: any
  otherFacilities: any
  companyName: string
  refetchOtherFacilities?: () => void
  facilitiesWithChildCount: any
  collapsed: boolean
  setCollapsed: (val: boolean) => void
  handleCollapse: (val?: boolean) => void
  notificationCount: number
  refetchNotificationCount: () => void
  unseenNotificationData: any
  refetchFacilities?: () => void
  queryClient?: any
  companyId: number
  sideBarProps: SIDE_BAR_PROPS
  sideBarToggleProps: SIDE_TOGGLE_PROPS
}

export const AuthContext = createContext<Partial<ContextProps>>({})

type AuthProviderProps = {
  loading: boolean
  user: any | null
  setUser: any
  isOwner: boolean
  children: React.ReactNode
  permissions: any
  facilities: any
  otherFacilities: any
  companyName: string
  companyId: number
  refetchOtherFacilities?: () => void
  collapsed: boolean
  setCollapsed: (val: boolean) => void
  facilitiesWithChildCount: any
  notificationCount?: number
  refetchNotificationCount?: () => void
  unseenNotificationData?: any
  refetchFacilities?: () => void
  queryClient?: any
  sideBarProps: SIDE_BAR_PROPS
  sideBarToggleProps: SIDE_TOGGLE_PROPS
}

export const AuthProvider = (props: AuthProviderProps) => {
  const {
    loading,
    user,
    setUser,
    children,
    isOwner,
    permissions,
    facilities,
    otherFacilities,
    companyName,
    companyId,
    refetchOtherFacilities,
    facilitiesWithChildCount,
    notificationCount,
    unseenNotificationData,
    refetchNotificationCount,
    refetchFacilities,
    collapsed,
    setCollapsed,
    queryClient,
    sideBarProps,
    sideBarToggleProps,
  } = props
  const [isClient, setIsClient] = useState(false)

  useEffect(() => {
    setIsClient(true)
  }, [])
  return (
    <AuthContext.Provider
      value={{
        loading,
        user,
        authenticated: user !== null,
        setUser,
        isOwner,
        permissions,
        facilities,
        otherFacilities,
        companyName,
        companyId,
        refetchOtherFacilities,
        facilitiesWithChildCount,
        notificationCount,
        refetchNotificationCount,
        unseenNotificationData,
        refetchFacilities,
        collapsed,
        setCollapsed,
        queryClient,
        sideBarProps,
        sideBarToggleProps,
      }}
    >
      {isClient ? (
        children
      ) : (
        <InitialLoader
          logoImg={
            <img src={"/assets/logo.svg"} height={60} width={60} alt={"logo"} />
          }
        />
      )}
    </AuthContext.Provider>
  )
}

export const useAuthContext = () => {
  const context = useContext(AuthContext)
  if (context === undefined) {
    throw new Error("useAuthContext must be used within a AuthProvider")
  }
  return context
}
