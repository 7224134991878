import React from "react"
import styled from "styled-components"
import { theme } from "../../theme"

const StyledTableWrapper = styled.div<{ minWidth: string }>`
  /* min-width: ${(props) => props?.minWidth}; */
  overflow-x: auto;
  table#sprc_summaryTable {
    width: 100%;
    border-collapse: collapse !important;
    tbody {
      tr {
        td {
          border: 1px solid ${theme.colors.text};
          font-size: 12px;
        }
        td.key__cell {
          max-width: fit-content;
          padding: 10px;
          text-align: center;
        }
        td.ws {
          min-width: 40px;
          padding: 2px;
          text-align: center;
        }
      }
    }
  }
`
type ICell = {
  content: React.ReactNode | string
  className?: string
}
interface IProps {
  cells: ICell[]
  minWidth?: string
}
export const ServiceProvisionSheetFooterContent = ({
  cells,
  minWidth,
}: IProps): JSX.Element => {
  return (
    <StyledTableWrapper minWidth={minWidth}>
      <table id={"sprc_summaryTable"}>
        <tbody>
          <tr>
            {cells?.map((cell, index) => (
              <td className={cell?.className} key={index}>
                {cell?.content}
              </td>
            ))}
          </tr>
        </tbody>
      </table>
    </StyledTableWrapper>
  )
}
