import { getApps, initializeApp } from "firebase/app"
import { getAuth, sendPasswordResetEmail } from "firebase/auth"
import { getAnalytics } from "firebase/analytics"

if (!getApps.length) {
  initializeApp({
    apiKey: process.env.NEXT_PUBLIC_FIREBASE_API_KEY,
    authDomain: process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.NEXT_PUBLIC_FIREBASE_DATABASE_URL,
    projectId: process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID,
    storageBucket: process.env.NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.NEXT_PUBLIC_FIREBASE_MESSAGE_SENDER_ID,
    appId: process.env.NEXT_PUBLIC_FIREBASE_APP_ID,
    measurementId: process.env.NEXT_PUBLIC_FIREBASE_MEASUREMENT_ID,
  })
}

const tenantId = process.env.NEXT_PUBLIC_ADULT_TENANT_ID
const auth = getAuth()
auth.tenantId = tenantId
let analytics

if (typeof window !== "undefined") {
  analytics = getAnalytics()
}

export { auth, analytics, sendPasswordResetEmail }
