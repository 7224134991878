import dayjs from "dayjs"

export const calculateHMTimeDiff = (start_time: string, end_time: string) => {
  const startTime = start_time ? start_time?.split(":") : []
  const endTime = end_time ? end_time?.split(":") : []
  const totalDiffSecond = dayjs()
    .hour(+endTime[0])
    .minute(+endTime[1])
    .diff(dayjs().hour(+startTime[0]).minute(+startTime[1]), "second")
  const hours = Math.floor(totalDiffSecond / 3600)
  const minutes = Math.floor((totalDiffSecond % 3600) / 60)
  return { hours, minutes }
}
