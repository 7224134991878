import { API, removeBlankAttributes } from "@project/common"
import { REOCCURRING_INITIAL_VALUES, UserManagementOperation } from "../types"
import dayjs from "dayjs"
import { PAGE_SIZE } from "../constants"

export const fetchAllUsersByContract = async ({
  queryKey,
}: {
  queryKey: any
}) => {
  const updatedParams = {
    facilityIds: queryKey[1],
    year: dayjs().year(),
    month: dayjs().month() + 1,
    page: 1,
    pageSize: "Infinity",
  }
  const params = removeBlankAttributes(updatedParams)
  const response = await API.get("/user", { params })
  return response
}

export const fetchAllUsers = async ({
  page,
  userManagementOperation,
}: {
  page?: number
  pageSize?: number | string
  userManagementOperation?: UserManagementOperation
}) => {
  const yearMonth = userManagementOperation?.contract_year_month.split("-")
  const updatedParams = {
    ...userManagementOperation,
    service_used_by_user:
      userManagementOperation?.service_used_by_user?.join(","),
    facilityIds: userManagementOperation?.contract_facility?.join(","),
    year: yearMonth[0],
    month: yearMonth[1],
    page,
    pageSize: PAGE_SIZE,
  }
  const params = removeBlankAttributes(updatedParams)
  const response = await API.get("/user", { params })
  return response
}

export const getAllFacilitiesWithUserCount = async ({
  page,
  pageSize,
  userManagementOperation,
}: {
  page?: number
  pageSize?: number | string
  userManagementOperation?: UserManagementOperation
}) => {
  const updatedParams = {
    keyword: userManagementOperation?.keyword,
    consultation_support_office_id:
      userManagementOperation?.consultation_support_office_id,
    provision_city_id: userManagementOperation?.provision_city_id,
    contract_facility_ids:
      userManagementOperation?.contract_facility?.join(","),
    child_service_ids: userManagementOperation?.service_used_by_user?.join(","),
    contract_year: userManagementOperation?.contract_year_month.split("-")[0],
    contract_month: userManagementOperation?.contract_year_month.split("-")[1],
    pageSize,
    page,
  }
  const params = removeBlankAttributes(updatedParams)
  const response = await API.get("/facilities_with_user_count", { params })
  return response
}
export const getOneUser = (user_id: any) => {
  if (+user_id > 0) return API.get(`/user/${user_id}`)
}

//contract facility
export const getUserActiveContract = (user_id: any, extra?: any) => {
  const params = extra ? removeBlankAttributes(extra) : {}
  return API.get(`/facility-contract/user-active-contract/${user_id}`, {
    params,
  })
}

//re-occurring user facility
export const getAllUserFacilityRepeatSchedule = (user_id: string | number) => {
  return API.get(`/user-facility-repeat-schedules/list/${user_id}`)
}

export const createUserFacilityRepeatSchedule = (
  values: REOCCURRING_INITIAL_VALUES,
) => {
  return API.post("/user-facility-repeat-schedules", values)
}

export const getOneUserFacilityRepeatSchedule = (schedule_id: any) => {
  return API.get(`/user-facility-repeat-schedules/${schedule_id}`)
}

export const updateOneUserFacilityRepeatSchedule = ({
  values,
  schedule_id,
}: {
  values: REOCCURRING_INITIAL_VALUES
  schedule_id: any
}) => {
  return API.put(`/user-facility-repeat-schedules/${schedule_id}`, values)
}

//fetch all user without pagination
export const fetchInfinityUsers = (params: any) => {
  return API.get("/user", { params })
}

export const deleteUserFacilityRepeatSchedule = (schedule_id: any) => {
  return API.delete(`/user-facility-repeat-schedules/${schedule_id}`)
}

//all contract facility of a user for year
export const getUserYearContract = (
  user_id: string | number,
  year: string | number,
) => {
  return API.get(`/facility-contract/user-contracts-year/${user_id}/${year}`)
}

// user detail
export const getUserDetail = (user_id) => API.get(`/user/detail/${user_id}`)
