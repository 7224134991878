import { API, removeBlankAttributes } from "@project/common"
import dayjs from "dayjs"
export const getAllActualCost = ({
  page,
  pageSize,
}: {
  page?: number
  pageSize?: any
}) => {
  return API.get(`/actual-cost?page=${page}&pageSize=${pageSize}`)
}

export const createActualCostBurdenRecord = async (values: any) => {
  return API.post(`/actual-cost-burden-records`, values)
}
export const updateOneActualCostBurdenRecord = async ({
  id,
  ...values
}: any) => {
  return API.put(`/actual-cost-burden-records/${id}`, values)
}
export const getAllActualCostBurdenRecord = ({
  page,
  pageSize = 10,
  date,
  facilityIds,
  year,
  month,
}: {
  date?: string | null
  facilityIds?: string | null
  page?: number
  pageSize?: number
  year?: number
  month?: number
}) => {
  const params = removeBlankAttributes({
    page,
    pageSize,
    date,
    facilityIds,
    year,
    month,
  })
  return API.get("/actual-cost-burden-records", { params })
}

export const updateActualCostBurdenRecord = async (values: any) => {
  return API.put(`/actual-cost-burden-records`, values)
}

export const getOneActualCostBurdenRecord = (
  id: string,
  facilityIds?: string,
  date?: string,
) => {
  const params = removeBlankAttributes({ facilityIds, date })
  return API.get(`/actual-cost-burden-records/${id}`, { params })
}

export const getActualCostBurdenRecord = (
  id: string,
  date?: string,
  page?: any,
) => {
  const formatDate = dayjs(date).format("YYYY-MM-DD")
  const params = removeBlankAttributes({ formatDate, page })
  return API.get(`/actual-cost-burden-records/${id}`, { params })
}

export const addActualCostBurdenRecordUser = async (values: any) => {
  delete values?.burden_user_record_id
  return API.post(
    `actual-cost-burden-records/${values?.burden_record_id}/add-user`,
    values,
  )
}
export const updateOneActualCostBurdenRecordUser = ({
  burden_user_record_id,
  ...values
}: any) => {
  return API.put(
    `/actual-cost-burden-records/users/${burden_user_record_id}`,
    values,
  )
}

export const deleteActualCostBurdenRecordUser = (id) => {
  return API.delete(`actual-cost-burden-records/users/${id}`)
}
export const bulkUpdateActualCostBurdenUserRecord = (values: any) => {
  return API.post(`actual-cost-burden-records/users/bulk-edit`, values)
}

export const bulkDeleteActualCostBurdenRecords = (ids: any) => {
  return API.delete(
    `actual-cost-burden-records/bulk-delete?ids=${ids.join(",")}`,
  )
}
