import { API } from "@project/common"
import { ProgressManagementFormFields } from "../types/ProgressManagement.types"

export const createProgressManagement = async (
  values: ProgressManagementFormFields,
) => {
  return API.post("/progress", values?.progress_name)
}

export const getAllProgressManagement = async ({ page, pageSize }: any) => {
  return API.get(`/progress?page=${page}&pageSize=${pageSize}`)
}

export const getSingleProgressManagement = async (id: number) => {
  return API.get(`/progress/${id}`)
}

export const updateProgressManagement = async ({ id, progress_name }: any) => {
  return API.put(`/progress/${id}`, progress_name)
}
export const deleteOneProgress = async (id: number) => {
  return API.delete(`/progress/${id}`)
}
