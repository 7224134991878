// types
import { EasyyTheme } from "../../../../theme"

// utils
import { rem } from "../../../../utils/converter"

export function spacingResolver(value: unknown, theme: EasyyTheme) {
  if (typeof value === "number") {
    return rem(value)
  }

  if (typeof value === "string") {
    if (!(value in theme.spacing)) {
      return rem(value)
    }

    return rem(theme.spacing[value as keyof EasyyTheme["spacing"]])
  }

  return value
}
