import { EasyyTheme } from "../../../../theme"

const parseThemeColor = ({
  color,
  theme,
}: {
  color: unknown
  theme: EasyyTheme
}) => {
  const easyyColors = theme.colors

  if (typeof color !== "string") {
    return easyyColors.accent
  }
  const isThemeColor = typeof color === "string" && color in easyyColors
  const colorName = isThemeColor
    ? easyyColors[color as keyof typeof easyyColors]
    : color

  return colorName
}

export function colorResolver(color: unknown, theme: EasyyTheme) {
  const parsedColor = parseThemeColor({ color, theme })

  return parsedColor
}

export function textColorResolver(color: unknown, theme: EasyyTheme) {
  return colorResolver(color, theme)
}
