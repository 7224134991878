import { API, removeBlankAttributes } from "@project/common"

const BASE_URL = "attendance-user-shift"
export const saveShiftUserMaster = async (values: any) => {
  return API.post(`/${BASE_URL}`, values)
}

export const fetchShiftUserMaster = (id: string | string[] | number) =>
  API.get(`/${BASE_URL}/${id}`)

export const updateShiftUserMaster = ({ id, ...values }: any) => {
  return API.put(`/${BASE_URL}/${id}`, { ...values })
}
export const deleteShiftUserMaster = (id) => {
  return API.delete(`/${BASE_URL}/${id}`)
}

export const fetchAllShiftUserMaster = ({
  page,
  pageSize,
  keyword,
  facilityIds,
  employment_type_id,
}: {
  page: number
  pageSize: number | string
  keyword?: string
  facilityIds?: Array<number>
  employment_type_id?: Array<number>
}) => {
  const params = removeBlankAttributes({
    facilityIds: facilityIds?.toString(),
    employment_type_id: employment_type_id?.toString(),
    keyword,
  })

  return API.get(`/${BASE_URL}?page=${page}&pageSize=${pageSize}`, {
    params,
  })
}
