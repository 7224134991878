import { Layout } from "antd"
import { t } from "i18next"
import styled from "styled-components"
import { theme } from "../../theme"
const { Footer: AntFooter } = Layout

const FooterStyled = styled(AntFooter)`
  &.faculty-dashboard-footer {
    height: 40px;
    background: ${theme.colors.white};
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    & > * {
      font-size: 14px !important;
    }
    .line-break {
      display: none;
      @media (max-width: 768px) {
        display: block;
      }
    }
    @media print {
      display: none !important;
      padding: 0px;
      height: 0px;
    }
  }
  .faculty-dashboard-footer-info-highlight-blue {
    color: ${theme.colors.link};
    font-size: inherit;
  }

  .hide-dot-on-mobile {
    @media (max-width: 768px) {
      display: none;
    }
  }

  .faculty-dashboard-footer-copyright {
    font-size: clamp(0.75rem, 0.5115rem + 1.0178vw, 1rem);
  }
  @media (max-width: 768px) {
    &.faculty-dashboard-footer {
      flex-direction: column;
    }
    .faculty-dashboard-footer-info {
      text-align: center;
      font-size: inherit !important;
    }
    .faculty-dashboard-footer-copyright {
      margin-top: 10px;
      padding-bottom: 1rem;
    }
  }
`

const AuthorizedFooter = () => {
  return (
    <FooterStyled className={"faculty-dashboard-footer"}>
      <span className={"faculty-dashboard-footer-info"}>
        {`${t("Contact1")}: `}
        <span className={"faculty-dashboard-footer-info-highlight-blue"}>
          {"easyy-support2@onelife.co.jp"}
        </span>{" "}
        <br className={"line-break"} />
      </span>
      <span className={"faculty-dashboard-footer-copyright"}>
        {`Copyright © Onelife.Inc`}
      </span>
    </FooterStyled>
  )
}
AuthorizedFooter.displayName = "AuthorizedFooter"

export { AuthorizedFooter }
