const BulkPrint = () => (
  <svg
    width={"18"}
    height={"17"}
    viewBox={"0 0 18 17"}
    fill={"none"}
    xmlns={"http://www.w3.org/2000/svg"}
  >
    <path
      d={"M16.8333 8.54297V16.043H1V8.54297"}
      stroke={"currentColor"}
      strokeWidth={"1.5"}
      strokeLinecap={"round"}
      strokeLinejoin={"round"}
    />
    <path
      d={"M13.4987 1.04297H4.33203V12.7096H13.4987V1.04297Z"}
      stroke={"currentColor"}
      strokeWidth={"1.5"}
      strokeLinecap={"round"}
      strokeLinejoin={"round"}
    />
    <path
      d={"M6.83203 3.54297H10.9987"}
      stroke={"currentColor"}
      strokeWidth={"1.5"}
      strokeLinecap={"round"}
      strokeLinejoin={"round"}
    />
    <path
      d={"M6.83203 6.04297H10.9987"}
      stroke={"currentColor"}
      strokeWidth={"1.5"}
      strokeLinecap={"round"}
      strokeLinejoin={"round"}
    />
    <path
      d={"M6.83203 8.54297H10.9987"}
      stroke={"currentColor"}
      strokeWidth={"1.5"}
      strokeLinecap={"round"}
      strokeLinejoin={"round"}
    />
  </svg>
)

export default BulkPrint
