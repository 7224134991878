import { Calendar } from "antd"
import styled from "styled-components"
import { theme } from "../../theme"
export const CalendarWrapper = styled.div`
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  @media screen and (max-width: ${theme.breakpoints.xxl}) {
    overflow-x: auto;
  }
`
export const AntCalendarWrapper = styled(Calendar)<any>`
  /* min-width: ${(props) => props?.$minWidth || "100%"}; */
  width: 100%;
  max-width: 100% !important;
  .ant-picker-panel {
    .ant-picker-body {
      padding: 0 !important;
      table.ant-picker-content {
        height: auto;
        min-width: 100%;
        max-width: 100%;
        thead {
          tr {
            th {
              border: 1px solid ${theme.colors.border};
              background-color: ${(props) =>
                props?.$headBg || theme.colors.disabled};
              text-align: center;
              height: 35px;
              padding-inline-end: 0 !important;
              &:first-child {
                background-color: ${theme.colors["error-light"]};
              }
              &:last-child {
                background-color: ${theme.colors["t-head"]};
              }
            }
          }
        }
        tbody {
          tr {
            page-break-inside: avoid !important;
            td {
              border: 1px solid ${theme.colors.border};
              padding: 0 !important;
              height: auto;
              vertical-align: top !important;
              cursor: default !important;
              position: relative;
              .content {
                .month_day {
                  display: flex;
                  justify-content: flex-end;
                  .date {
                    width: 35px;
                    height: 35px;
                    background-color: ${(props) =>
                      props?.$dateBg || theme.colors.border};
                    border-bottom-left-radius: 10px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    color: ${theme.colors.text};
                  }
                  .full_width {
                    width: 100%;
                    height: 35px;
                    background-color: ${(props) =>
                      props?.$dateBg || theme.colors["t-head"]};
                    border-bottom: 1px solid ${theme.colors.border};
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-weight: 400;
                  }
                }
                .info {
                  text-align: start;
                  min-height: 100px;
                }
              }
              .holiday__content {
                background-color: ${theme.colors["error-light"]};
                position: absolute;
                inset: 0;
                pointer-events: none;
              }
            }
            /* &:last-child td {
              border: 0 !important;
            } */
          }
        }
      }
    }
  }

  @media print {
    min-width: 100% !important;
    max-width: 100% !important;
    overflow: auto !important;
  }
  @media screen and (max-width: ${theme.breakpoints.xxl}) {
    min-width: 1200px;
    max-width: 100% !important;
  }
`

export const SecondaryAntCalendarWrapper = styled(Calendar)<any>`
  min-width: ${(props) => props?.$minWidth || "1200px"};
  .ant-picker-panel {
    .ant-picker-body {
      padding: 0 !important;
      table.ant-picker-content {
        height: auto;
        min-width: 100%;
        max-width: 100%;
        tbody {
          tr {
            page-break-inside: avoid !important;
            td {
              padding: 0 !important;
              height: auto;
              vertical-align: top !important;
              cursor: default !important;
              position: relative;

              .content {
                min-height: 100px;
                position: relative;
                padding: 0 5px;
                .month_day {
                  display: flex;
                  justify-content: flex-end;
                  padding-right: 8px !important;
                }
                .default-border {
                  border-top: 2px solid #f0f0f0;
                }
                .active-border {
                  border-top: 2px solid ${theme.colors.primary};
                  background-color: #e6f7ff !important;
                }
                .border-white {
                  border-top: 2px solid ${theme.colors.background};
                }
              }
              .holiday__content {
                background-color: ${theme.colors["error-light"]};
                position: absolute;
                inset: 0;
                pointer-events: none;
                padding: 0 0px !important;
              }
            }
          }
        }
      }
    }
  }
  @media print {
    min-width: 100% !important;
    max-width: 100% !important;
    overflow: auto !important;
  }
  @media screen and (max-width: ${theme.breakpoints.xxl}) {
    min-width: 1200px;
  }
`
