import React from "react"
import styled from "styled-components"
import { useTranslation } from "react-i18next"
const MainWrapper = styled.div<any>`
  display: flex;
  flex-direction: column;
  width: 265px;
  height: 113px;
  border: ${({ borderColor }) =>
    borderColor ? `1px solid ${borderColor}` : `1px solid #EEE`};
  border-radius: 6px;
  color: #191919;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
  @media screen and (min-width: 541px) {
    width: calc(25% - 10px);
    margin-right: 0px !important;
    justify-content: center;
  }
  @media screen and (max-width: 540px) {
    width: calc(50% - 10px);
    margin-right: 0px !important;
    justify-content: center;
  }

  .title {
    height: 55px;
    border-bottom: 1px solid #deecff;
    background-color: ${({ bgColor }) => (bgColor ? bgColor : "#EEEEEE")};
    display: flex;
    align-items: center;
    padding-left: 15px;
  }
  .content {
    display: flex;
    padding: 10px 20px;
    height: 58px;
    align-items: center;
    justify-content: space-between;
    @media screen and (max-width: 375px) {
      padding: 10px 10px;
    }
    .times {
      font-size: 32px;
      @media screen and (max-width: 280px) {
        font-size: 10px;
      }
    }
    .people {
      color: #191919;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-transform: capitalize;
      margin-top: 5px;
      @media screen and (max-width: 280px) {
        font-size: 10px;
      }
    }
  }
  .title,
  .content {
    @media screen and (max-width: 280px) {
      font-size: 12px !important;
      font-weight: 500;
    }
  }

  h1 {
    @media screen and (max-width: 280px) {
      font-size: 26px !important;
      font-weight: 500;
    }
  }
`
interface IProps {
  titleName: string
  count: number | string
  peopleCount: number
  bgColor?: string
  borderColor?: string
}
const ReportCard = ({
  titleName,
  count,
  peopleCount,
  bgColor,
  borderColor,
}: IProps) => {
  const { t } = useTranslation()
  return (
    <MainWrapper bgColor={bgColor} borderColor={borderColor}>
      <div className={"title"}>{titleName}</div>
      <div className={"content"}>
        <h1 className={"times"}>{count}</h1>
        <p className={"people"}>
          {t("/ {{peopleCount}} people", {
            peopleCount: peopleCount,
          })}
        </p>
      </div>
    </MainWrapper>
  )
}

export { ReportCard }
