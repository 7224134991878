import styled from "styled-components"
import { theme } from "../..//theme"
import { ReactNode } from "react"
const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
  position: relative;
  .circle {
    position: relative;
    font-weight: bold;
    font-size: 35px;
    display: inline-block;
    width: 100px;
    height: 100px;
    text-align: center;
    border-radius: 50%;
    cursor: pointer;
    margin: 0px 15px;
    background: #fff;
    &::before {
      position: absolute;
      content: "";
      width: 110px;
      height: 110px;
      z-index: -1;
      border-radius: 50%;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      animation: rotate 5s linear infinite;
      border-image: linear-gradient(red, green);
      border: 3px dashed ${theme?.colors?.primary};
    }
    @keyframes rotate {
      to {
        transform: translate(-50%, -50%) rotate(360deg);
      }
      from {
        transform: translate(-50%, -50%) rotate(-360deg);
      }
    }
  }
  .img_container {
    position: absolute;
  }
`
export const InitialLoader = ({
  logoImg,
}: {
  logoImg: ReactNode
}): JSX.Element => {
  return (
    <LoaderWrapper>
      <div className={"circle"}></div>
      <div className={"img_container"}>{logoImg}</div>
    </LoaderWrapper>
  )
}
