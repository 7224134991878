import styled from "styled-components"
import { theme } from "../../theme"

export const Wrapper = styled.div<any>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: ${(props) => (props?.removeMt ? "0 0" : "0px 0px")};
  @media (max-width: ${theme.breakpoints.sm}) {
    overflow-x: auto;
    width: 100%;
  }

  overflow-y: hidden;

  p {
    color: ${theme.colors.text};
    font-weight: 400;
    font-size: 14px;
    line-height: 23px;
    margin: auto;
  }
  .ant-pagination {
    display: flex;
    border-radius: 8px;
    border: 1px solid ${theme.colors.border};
    li {
      margin: 0px;
      min-width: 36px;
      height: 34px;
      border-radius: 0px;
      button {
        min-width: 36px;
        height: 34px;
      }
      a {
        padding-top: 2px;
        height: 40px;
        color: black !important;
        font-size: 14px;
      }
      &.ant-pagination-item-active {
        background: ${theme.colors["action-400"]};
        border: 0;
        a {
          color: black !important;
        }
      }
      &:first-child {
        button {
          border-radius: 0;
          border-top-right-radius: 8px;
          border-bottom-right-radius: 8px;
          border: 0;
        }
      }
      &:last-child {
        button {
          border-radius: 0;
          border-top-right-radius: 8px;
          border-bottom-right-radius: 8px;
          border: 0;
        }
      }
      &:not(:last-child) {
        border-right: 1px solid ${theme.colors.border};
      }
    }
  }
  @media screen and (max-width: ${theme.breakpoints.md}) {
    margin: ${(props) => (props?.removeMt ? "0 0" : "10px 0px")};
  }
  @media print {
    display: none;
  }

  .wrapper {
    margin-left: auto;
  }
`
