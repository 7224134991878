import { API, removeBlankAttributes } from "@project/common"
import { NhifAddressToUsersResposeDataType } from "../types"

export const getNhifPrintAddressToUsers = async ({
  facilityIds,
  adult_ids,
  year,
  month,
  phonetic_order,
}: {
  facilityIds: string | null
  adult_ids?: string
  year?: number
  month?: number
  phonetic_order?: number | null
}): Promise<NhifAddressToUsersResposeDataType> => {
  const params = removeBlankAttributes({
    facilityIds,
    adult_ids,
    year,
    month,
    phonetic_order,
  })
  return API.get("/nhif/common-list", { params })
}
