import { Popconfirm, Space } from "antd"
import React, { FC } from "react"
import { useTranslation } from "react-i18next"

interface Props {
  id: string
  onConfirm: () => void
  title?: string
  subTitle: string
  isLoading?: boolean
  disabled?: boolean
  okText?: string
  cancelText?: string
}

export const TableDeleteAction: FC<Props> = ({
  onConfirm,
  title,
  subTitle,
  isLoading,
  disabled,
  okText,
  cancelText,
}) => {
  const { t } = useTranslation()

  return (
    <Space size={"middle"}>
      <Popconfirm
        placement={"top"}
        title={title != null ? t(title) : t("Are you sure to delete?")}
        onConfirm={onConfirm}
        okText={okText || t("Yes")}
        cancelText={cancelText || t("No")}
        okButtonProps={{
          loading: isLoading,
        }}
      >
        <span
          title={t("Delete")}
          style={{
            color: "red",
            textDecoration: "underline",
            cursor: "pointer",
            opacity: disabled ? 0.5 : 1,
            pointerEvents: disabled ? "none" : "auto",
          }}
        >
          {t(subTitle)}
        </span>
      </Popconfirm>
    </Space>
  )
}
