import { Drawer } from "antd"
import React from "react"
import styled from "styled-components"
import { LogoutBtn } from "../../components"
import { theme } from "../../theme"
import { SIDE_BAR_PROPS } from "../../types"
import { SideMenu } from "./SideMenu"
interface DrawerSidebarProps {
  isToggled: boolean
  setIsMenuToggle: React.Dispatch<React.SetStateAction<boolean>>
  menuItems: any[]
  logOut: () => void
  sideBarProps: SIDE_BAR_PROPS
}

const StyledDrawer = styled(Drawer)<DrawerSidebarProps>`
  .ant-drawer-body {
    padding: 0 !important;
    background-color: ${theme.colors.primary};
  }
`

const DrawerSidebar = ({
  isToggled,
  setIsMenuToggle,
  menuItems,
  logOut,
  sideBarProps,
}: DrawerSidebarProps): JSX.Element => {
  return (
    <StyledDrawer
      placement={"left"}
      width={"70%"}
      onClose={() => setIsMenuToggle(false)}
      open={isToggled}
      destroyOnClose
      closable={false}
      maskStyle={{
        background: "transparent",
        cursor: "pointer",
      }}
      style={{
        padding: "0px !important",
      }}
    >
      <SideMenu menuItems={menuItems} sideBarProps={sideBarProps} />
      <LogoutBtn logOut={logOut} collapsed={false} />
    </StyledDrawer>
  )
}
DrawerSidebar.displayName = "DrawerSidebar"
export { DrawerSidebar }
