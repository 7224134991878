import { API, removeBlankAttributes } from "@project/common"
import { STAFF__INITIAL_ATTENDANCE } from "../types/StaffAttendance.types"
import { FULL_DATE_FORMAT_EN } from "../constants"
import dayjs from "dayjs"

export const getMonthlyAttendanceStaffResult = ({
  year,
  month,
  facility,
  staff_id,
}: {
  year: any
  month: any
  facility: any
  staff_id: any
}) => {
  const params = removeBlankAttributes({ year, month, facility, staff_id })
  return API.get("/staff-attendance/monthly", { params })
}
export const createOrUpdateAttendanceStaffResult = (
  values: STAFF__INITIAL_ATTENDANCE[],
) => {
  return API.post("/staff-attendance", values)
}
export const getDailyAttendanceStaffResult = (args: any) => {
  const params = removeBlankAttributes({
    ...args,
    date: args?.date
      ? dayjs(args?.date).format(FULL_DATE_FORMAT_EN)
      : dayjs().format(FULL_DATE_FORMAT_EN),
    staff_id: args?.staff_id || null,
  })
  return API.get("/staff-attendance/daily", { params })
}
export const removeAttendanceStaffResult = (values: {
  staff_id: any
  facility_id: any
  date: any
}) => {
  return API.post("/staff-attendance/remove-attendance", values)
}
export const getSchedulableStaffs = (params: { facility: any; date: any }) => {
  return API.get("/staff-attendance/schedulable-staffs", { params })
}
