import React from "react"
import styled from "styled-components"
import { useTranslation } from "react-i18next"
import { theme } from "../../theme"
const HeaderWrapper = styled.header`
  height: 57px;
  background-color: ${theme.colors.secondary};
  display: flex;
  align-items: center;
  padding: 12px 36px;
  gap: 1.2em;
  .logo {
    background-color: #ffffff;
    width: 42.5px;
    height: 42.5px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 20.5px;
      object-fit: cover;
    }
  }
  .tag__text {
    color: ${theme.colors.white};
    font-size: 18px;
    line-height: 26.06px;
    font-weight: 500;
  }
`
const Header = (): JSX.Element => {
  const { t } = useTranslation()
  return (
    <HeaderWrapper>
      <div className={"logo"}>
        <img src={"./assets/logo.svg"} alt={"logo"} />
      </div>
      <div className={"tag__text"}>{t("就労支援")}</div>
    </HeaderWrapper>
  )
}
Header.displayName = "Header"
export { Header }
