// utils
import { keys } from "../../../utils/keys"

// types
import { EasyyStyleProps } from "../types"
type FilterPropsRes<T extends Record<string, any>> = {
  [Key in keyof T]-?: T[Key] extends undefined ? never : T[Key]
}

// we are filtering out undefined | null values from the props
export function filterProps<T extends Record<string, any>>(props: T) {
  return keys(props).reduce<FilterPropsRes<T>>((acc, key: keyof T) => {
    if (props[key] !== undefined) {
      acc[key] = props[key]
    }
    return acc
  }, {} as FilterPropsRes<T>)
}

export function extractStyleProps<T extends Record<string, any>>(
  others: EasyyStyleProps & T,
): { styleProps: EasyyStyleProps; rest: T } {
  const {
    m,
    mx,
    my,
    mt,
    mb,
    ml,
    mr,
    p,
    px,
    py,
    pt,
    pb,
    pl,
    pr,
    bg,
    c,
    opacity,
    ff,
    fz,
    fw,
    lts,
    ta,
    lh,
    fs,
    tt,
    td,
    w,
    miw,
    maw,
    h,
    mih,
    mah,
    bgsz,
    bgp,
    bgr,
    bga,
    pos,
    top,
    left,
    bottom,
    right,
    inset,
    display,
    flex,
    gap,
    align,
    justify,
    wrap,
    direction,
    hiddenFrom,
    visibleFrom,
    lightHidden,
    darkHidden,
    ...rest
  } = others

  const styleProps = filterProps({
    m,
    mx,
    my,
    mt,
    mb,
    ml,
    mr,
    p,
    px,
    py,
    pt,
    pb,
    pl,
    pr,
    bg,
    c,
    opacity,
    ff,
    fz,
    fw,
    lts,
    ta,
    lh,
    fs,
    tt,
    td,
    w,
    miw,
    maw,
    h,
    mih,
    mah,
    bgsz,
    bgp,
    bgr,
    bga,
    pos,
    top,
    left,
    bottom,
    right,
    inset,
    display,
    flex,
    gap,
    align,
    justify,
    wrap,
    direction,
    hiddenFrom,
    visibleFrom,
    lightHidden,
    darkHidden,
  })

  return { styleProps, rest: rest as unknown as T }
}
