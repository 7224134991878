import styled from "styled-components"
import { theme } from "../../theme"

interface Props {
  isMobile?: boolean
}

export const HeaderWrapper = styled.div<Props>`
  .mobile_extra_header {
    background-color: ${theme?.colors.secondary};
    padding: 8px 16px;
    display: flex;
    justify-content: space-between;
    .left {
      display: flex;
      flex-direction: column;
      row-gap: 0.3em;
      .date {
        color: ${theme.colors.white};
        font-size: 14px;
      }
    }
    .right {
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
      color: ${theme.colors.white};
      font-size: 14px;
    }
  }
  .header__container {
    background-color: ${(props) =>
      props?.isMobile ? `${theme.colors.primary}` : `${theme.colors.accent}`};
    height: 48px !important;
    padding: 0 !important;
    .logo__content {
      display: flex;
      height: 100%;
      gap: 1em;
      padding-left: ${(props) => (props?.isMobile ? `10px` : `20px`)};
      &--logo {
        img {
          width: 40px;
          object-fit: contain;
        }
      }
      span {
        color: ${(props) =>
          props?.isMobile ? `${theme.colors.text}` : `${theme.colors.white}`};
        font-weight: 500;
        font-size: 0.88em;
      }
    }
    &--small {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 100%;
      padding: 0 5px;
      .mobile__menu-toggler {
        height: 40px;
        width: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        background-color: ${theme.colors.white};
        color: ${theme.colors.action};
        font-size: 1.2em;
        cursor: pointer;
      }
    }
    &--big {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0;
      position: relative;
      width: 100% !important;
      height: 100% !important;
      .faculty-sidebar-trigger {
        position: absolute;
        top: 15px;
        width: 50px !important;
        background: ${theme.colors.white};
        color: ${theme.colors.text};
        border-radius: 0px 8px 8px 0px;
        box-shadow: 0px 0px 15px 0px #0000001a;
        cursor: pointer;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: 0.2s;
      }
      .faculty-sidebar-trigger-rotate {
        transform: rotate(180deg);
      }

      .faculty-dashboard-header-user-info {
        height: 100% !important;
        background-color: ${(props) =>
          props?.isMobile
            ? `${theme.colors.primary}`
            : `${theme.colors.white}`};
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding: ${(props) =>
          props?.isMobile ? `8px 10px 8px 0` : `8px 20px 8px 0`};
        line-height: 1;
        gap: 1em;
        flex: 1;
        font-size: 0.88em;
        font-weight: 400;
        span {
          font-size: 0.88em;
          font-weight: 400;
        }
        .faculty-dashboard-header-user-info-role {
          height: 24px;
          display: flex;
          align-items: center;
          padding: 2px 8px;
          background-color: ${theme?.colors.accent} !important;
          border-radius: 5px;
          font-size: 0.88em;
          font-weight: 400;
        }
        .faculty-dashboard-header-user-info-name {
          display: flex;
          align-items: center;
          font-size: 0.88em;
          font-weight: 400;
          background-color: #eafeff;
          padding: 4px 12px 4px 12px;
          border-radius: 20px;
        }

        .faculty-dashboard-header-user-info-person {
          display: flex;
          align-items: center;
          font-size: 0.88em;
          font-weight: 400;
        }
        .faculty-dashboard-header-user-info-logout {
          text-decoration: underline;
          cursor: pointer;
          font-size: 0.88em;
          font-weight: 400;
        }
        .mobile__menu-toggler {
          height: 40px;
          width: 40px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 50%;
          background-color: ${theme.colors.white};
          color: ${theme.colors.action};
          font-size: 1.2em;
          cursor: pointer;
        }
      }
    }
  }
  @media print {
    display: none;
  }
`
export const UserIconStyle = styled.div`
  cursor: pointer;
  position: relative;

  .faculty-dashboard-header-top-announcement-count {
    text-decoration: none;
    height: 14px;
    width: 14px;
    border-radius: 50%;
    background: #e00000;
    font-size: 11px !important;
    font-weight: bold;
    color: #ffffff;
    vertical-align: middle;
    margin: auto auto;
    display: flex;
    justify-content: center;
    position: absolute;
    top: -10%;
    left: 6px;
    cursor: pointer;
  }
`
