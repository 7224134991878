import { Radio as AntRadio, ConfigProvider } from "antd"
import { RadioGroupProps, RadioProps } from "antd/es/radio"
import styled, { css } from "styled-components"
import { theme } from "../../theme"
import { Option } from "../../types"
import { useTranslation } from "react-i18next"
interface Props extends RadioProps {
  label?: string
  dotSize?: number
  radioSize?: number
  dotColor?: string
  variant?: "adult" | "consumer"
  noLabel?: boolean
}
interface GroupProps extends RadioProps {
  options: Option[]
  variant?: "adult" | "consumer"
}
const StyledRadio = styled(AntRadio)<Props>`
  .ant-radio-checked .ant-radio-inner {
    border-color: ${theme.colors.border};
    background-color: ${theme.colors.white};
    &::after {
      background-color: ${({ variant }) =>
        variant === "consumer" ? theme.colors.cyan1 : theme.colors.action};
      -webkit-print-color-adjust: exact !important;
      print-color-adjust: exact !important;
    }
  }
  .ant-radio-disabled {
    .ant-radio-inner {
      border-color: ${theme.colors.border};
      background-color: ${theme.colors.background};

      &::after {
        background-color: ${(props) =>
          props?.dotColor || theme.colors["sub-text2"]};
        -webkit-print-color-adjust: exact !important;
        print-color-adjust: exact !important;
      }
    }
  }

  ${(props) =>
    props?.disabled &&
    css`
      pointer-events: none;
    `}
  ${(props) =>
    props?.noLabel &&
    css`
      margin-inline-end: 0 !important;
      span + * {
        padding-inline-start: 0 !important;
        padding-inline-end: 0 !important;
      }
    `}
`
const StyledRadioGroup = styled(AntRadio.Group)<RadioGroupProps>`
  display: flex;
  flex-wrap: wrap;
  row-gap: 10px;

  .custom-label {
    width: 100%;
    margin-top: 10px;
  }
`

const Radio = ({ label, dotSize, radioSize, ...props }: Props): JSX.Element => {
  const { t } = useTranslation()
  return (
    <ConfigProvider
      theme={{
        components: {
          Radio: {
            dotSize: dotSize || 10,
            radioSize: radioSize || 25,
          },
        },
      }}
    >
      <StyledRadio {...props}>{t(label)}</StyledRadio>
    </ConfigProvider>
  )
}

const RadioGroup = ({
  options,
  variant,
  ...props
}: GroupProps): JSX.Element => {
  const { t } = useTranslation()

  return (
    <StyledRadioGroup {...props}>
      {options?.map((option, index) =>
        option?.type === "label" ? (
          <div key={`label-${index}`} className={"custom-label"}>
            {t(option?.label)}
          </div>
        ) : (
          <Radio
            key={option.value.toString()}
            label={t(option?.label)}
            value={option?.value}
            variant={variant}
            disabled={option?.disabled}
          />
        ),
      )}
    </StyledRadioGroup>
  )
}

RadioGroup.displayName = "RadioGroup"
export { Radio, RadioGroup }
