import React from "react"
import { Tabs as AntTabs } from "antd"
import type { TabsProps as AntTabsProps } from "antd"
import styled from "styled-components"
import { theme } from "../../theme"
interface TabsProps extends AntTabsProps {
  padding?: string
  border?: string
}

const StyledAntTabs = styled(AntTabs)<TabsProps>`
  .ant-tabs-nav {
    margin: 0 !important;
    border-radius: 8px;

    .ant-tabs-tab {
      border: ${(props) => props?.border || `1px solid ${theme.colors.border}`};
      padding: 13px 18px 13px 18px !important;
      border-bottom: 0 !important;
      height: 48px;
      border-radius: 8px 8px 0px 0px;
      z-index: 9999;
    }
    .ant-tabs-tab-active {
      border-top: 1px solid #1677ff !important;
      border-left: 1px solid #1677ff !important;
      border-right: 1px solid #1677ff !important;
      background-color: ${theme.colors.white};
    }
    &::before {
      border-color: ${(props) =>
        props?.border || `1px solid ${theme.colors.action}`};
      border-radius: 8px;
      /* width: 99.5%; */
    }
  }
  .ant-tabs-content-holder {
    border: ${(props) => props?.border || `1px solid ${theme.colors.action}`};
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
    border-top-right-radius: 8px;
    padding: ${(props) => props?.padding || "20px 0"};
    background-color: ${theme.colors.white};
    margin-top: -2px;
  }
`

const Tabs = ({ border, ...props }: TabsProps): JSX.Element => {
  return (
    <StyledAntTabs
      tabBarGutter={8}
      {...props}
      type={"card"}
      animated
      border={border}
      size={"small"}
    />
  )
}
Tabs.displayName = "Tabs"
export { Tabs }
