import { API, removeBlankAttributes } from "@project/common"
import {
  ActualPlanSearchParams,
  IndividualSupportDetailResponse,
  IndividualSupportPlanResponse,
  PlanSignatureResponseObj,
  PlanType,
} from "../types/supportPlan.types"

export const getAllChildSupportPlans = ({
  quires: quires,
  type,
}: {
  quires: ActualPlanSearchParams
  type: PlanType
}): Promise<{
  count: number
  data: IndividualSupportPlanResponse[]
}> => {
  const params: any = removeBlankAttributes({
    ...quires,
    display_settings: Array.isArray(quires?.display_settings)
      ? quires?.display_settings?.join(",")
      : quires?.display_settings,
    facilityIds: Array.isArray(quires?.facilityIds)
      ? quires?.facilityIds?.join(",")
      : quires?.facilityIds,
    status: Array.isArray(quires?.status)
      ? quires?.status?.join(",")
      : quires?.status,
    service: quires?.service
      ? Array.isArray(quires?.service)
        ? quires?.service?.join(",")
        : quires?.service
      : null,
  })
  const URL = type === "non-draft" ? "/user-support" : "/user-support-draft"
  return API.get(URL, {
    params,
  })
}

export const getOneUserPlanByUserId = async ({
  plan_id,
  user_id,
  create_count,
  type,
  operation,
}: {
  plan_id: number
  user_id: number
  create_count: number
  type: PlanType
  operation?: string
}) => {
  const URL =
    type === "non-draft" && (operation === "edit" || operation === "copy")
      ? `/user-support/${plan_id}/user/${user_id}`
      : operation === "add"
        ? `/user-support-draft/user/${user_id}`
        : `/user-support-draft/${plan_id}/user/${user_id}`
  const from = type !== "non-draft" ? "draft" : null
  const params = removeBlankAttributes({ create_count, from })
  return API.get(URL, {
    params,
  })
}
export const getIndividualSupportPlanById = async ({
  id,
  type,
}: {
  id: string
  type: string
}): Promise<{
  data: IndividualSupportDetailResponse
}> => {
  const URL = type === "non-draft" ? `/user-support` : `/child-support-draft`
  return API.get(`${URL}/${id}`)
}
export const addNewPlan = async ({
  values,
  type,
}: {
  values: any
  type: PlanType
}) => {
  const URL = type === "non-draft" ? "/user-support" : "/user-support-draft"
  return API.post(URL, values)
}

export const updatePlan = async ({ values, type, create_count }: any) => {
  const id = values.id
  delete values.id
  const URL = type === "non-draft" ? `/user-support` : `/user-support-draft`
  return API.put(`${URL}/${id}`, values, {
    params: {
      create_count,
    },
  })
}

export const getOneIndividualSupportPlan = async ({
  id,
  type,
}: {
  id: number
  type: PlanType
}) => {
  const URL = type === "draft" ? "/user-support-draft" : "/user-support"
  return API.get(`${URL}/${id}`)
}

// monitoring individual detail signature update

export const updatePlanSignature = ({
  values,
  type,
}: {
  values: PlanSignatureResponseObj
  type: PlanType
}) => {
  const data = removeBlankAttributes(values)
  const URL =
    type === "non-draft"
      ? `/user-support/${values?.planId}/update_signature`
      : `/user-support-draft/${values?.planId}/update_signature`
  return API.put(`${URL}`, data)
}

export const deleteUserPlanById = async ({
  id,
  type,
}: {
  id: number
  type: PlanType
}) => {
  const URL =
    type === "non-draft" ? `/user-support/${id}` : `/user-support-draft/${id}`
  return API.delete(URL)
}
