export const pdfFilenameExtract = (file: string): string => {
  if (!file) {
    return ""
  }
  // Extract the filename part after the last '/' character
  const fileNameWithExtra = file.substring(file.lastIndexOf("/") + 1)

  if (!fileNameWithExtra) {
    return ""
  }
  // Remove the unwanted part using a regular expression
  const cleanedFileName = fileNameWithExtra.replace(/_pdf%7C[\d]+/, "")

  // Make sure the filename ends with .pdf
  return cleanedFileName.endsWith(".pdf")
    ? cleanedFileName
    : `${cleanedFileName}.pdf`
}
