import { API, filterObject } from "@project/common"
import { AdditionalItemData } from "../types/additionalItemManagement.types"

export const getAllAdditionalItem = (params: {
  facility: string
  year: string
  month: string
  user_id: string | number
}): Promise<AdditionalItemData> =>
  API.get("/additional_items", {
    params: filterObject(params),
  })
