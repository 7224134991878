import { LoadingOutlined } from "@ant-design/icons"
import { Select, SelectProps } from "antd"
import { DefaultOptionType } from "antd/es/select"
import styled from "styled-components"
import ArrowDownIcon from "../../assets/icons/arrow-down.svg"
import { theme } from "../../theme"
import { ErrorMassage } from "../ErrorMassage/ErrorMassage"
import { useTranslation } from "react-i18next"

interface SelectInputProps
  extends Omit<SelectProps<any, DefaultOptionType>, "options"> {
  width?: string
  height?: string
  name: string
  error?: string | boolean
  options?: Array<{ label: string | number; value: string | number | boolean }>
  dropdownPadding?: string
  shortSelector?: boolean | undefined
  showMessage?: boolean
  minWidth?: string
  maxWidth?: string
}

const Container = styled.div<{ shortSelector: boolean }>`
  width: auto;
  display: flex;
  flex-direction: column;
  @media screen and (max-width: ${theme.breakpoints.sm}) {
    width: ${(props) => (props?.shortSelector ? "auto" : "100% ")} !important;
  }
`
const StyledSelect = styled(Select)<SelectInputProps>`
  width: ${(props) => props?.width || "200px"};
  height: ${(props) => props?.height || "40px"};

  min-width: ${(props) => props?.minWidth || "auto"};
  max-width: ${(props) => props?.maxWidth || "auto"};
  .ant-select-selector {
    ${(props) => props?.error && `  border: 1px solid red !important;`};
  }
  @media screen and (max-width: ${theme.breakpoints.sm}) {
    width: ${(props) =>
      props?.shortSelector ? props?.width : "100% "} !important;
  }
`
const SelectInput = ({
  showMessage = true,
  ...props
}: SelectInputProps): JSX.Element => {
  const { t } = useTranslation()
  const getOptionsWithTranslatedLabel = (
    options: Array<{
      label: string | number
      value: string | number | boolean
    }>,
  ) => {
    return options?.map((option) => {
      option.label = t(option.label.toString())
      return option
    })
  }

  return (
    <Container shortSelector={props?.shortSelector || false}>
      <StyledSelect
        optionFilterProp={"children"}
        suffixIcon={props.loading ? <LoadingOutlined /> : <ArrowDownIcon />}
        options={getOptionsWithTranslatedLabel(props.options)}
        {...props}
      />
      {showMessage && props?.error && typeof props?.error === "string" && (
        <ErrorMassage type={"error"} message={props?.error} />
      )}
    </Container>
  )
}
SelectInput.displayName = "SelectInput"

export { SelectInput }
