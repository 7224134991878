import React from "react"

// packages
import { Flex } from "antd"

// commons | components
import { Box } from "../Box"
import { SelectInput } from "../SelectInput/SelectInput"

// utils
import { CALENDAR_HOURS_MINUTES, getHrMin } from "../../utils"

// types
export type SelectTimeProps = {
  value: `${string}:${string}`
  onTimeChange: (val: string) => void
  disabled?: boolean
  handleBlur?: () => void
  error?: string
}

// !## MAIN COMPONENT
export const TimePicker: React.FC<SelectTimeProps> = ({
  error,
  value: currentValue,
  disabled,
  handleBlur,
  onTimeChange,
}) => {
  const handleCurrValChange = (newVal: string) => {
    onTimeChange(newVal)
  }

  return (
    <Box
      display={"flex"}
      direction={"column"}
      justify={"flex-start"}
      align={"flex-start"}
    >
      <Flex align={"center"} gap={"8px"}>
        <Box maw={58} miw={58}>
          <SelectInput
            name={"hr"}
            width={"58px"}
            placeholder={"--"}
            value={getHrMin(currentValue)?.hr}
            options={CALENDAR_HOURS_MINUTES.hours}
            disabled={disabled}
            onBlur={() => handleBlur && handleBlur()}
            onChange={(hr) => {
              if (!getHrMin(currentValue)?.min) {
                handleCurrValChange(`${hr}:00`)
                return
              }

              if (!hr) {
                handleCurrValChange(`${hr}:`)
                return
              }

              handleCurrValChange(`${hr}:${getHrMin(currentValue)?.min}`)
            }}
          />
        </Box>
        <span>{":"}</span>
        <Box maw={58} miw={58}>
          <SelectInput
            name={"min"}
            width={"58px"}
            placeholder={"--"}
            onBlur={() => handleBlur && handleBlur()}
            value={getHrMin(currentValue)?.min}
            disabled={disabled || !getHrMin(currentValue)?.hr}
            options={CALENDAR_HOURS_MINUTES.minutes}
            onChange={(min) => {
              handleCurrValChange(`${getHrMin(currentValue)?.hr}:${min}`)
            }}
          />
        </Box>
      </Flex>
      {error ? (
        <Box component={"span"} c={"error"} fz={"xs"}>
          {error}
        </Box>
      ) : null}
    </Box>
  )
}
