import React from "react"
import { useTranslation } from "react-i18next"
import styled from "styled-components"
import { theme } from "../../theme"

const Wrapper = styled.div`
  position: absolute;
  border-top: 1px solid ${theme.colors?.primary};
  border-bottom: 1px solid ${theme.colors?.primary};
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  padding-left: 0.5em;
  width: 100%;
  gap: 0.4em;
  bottom: 0;
  left: 0;
  right: 0;
  span {
    font-size: 14px !important;
  }
`
const LogoutBtn = ({
  collapsed,
  logOut,
}: {
  collapsed: boolean
  logOut: () => void
}): JSX.Element => {
  const { t } = useTranslation()
  return (
    <Wrapper
      style={{
        justifyContent: collapsed ? "center" : "flex-start",
        cursor: "pointer",
      }}
      onClick={logOut}
    >
      <img src={"/assets/icons/logout.svg"} alt={"icon"} />
      {!collapsed && <span>{t("Logout")}</span>}
    </Wrapper>
  )
}
LogoutBtn.displayName = "LogoutBtn"
export { LogoutBtn }
