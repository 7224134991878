import { API } from "@project/common"
import { UserAbsenceRecordSearch } from "../types/UserAttendanceAbsenceRecord.types"

export const createOrUpdateUserDailyAttendance = (values) => {
  // following values contain facility_id in payload which causes permission issue
  if (typeof values?.system_status_management != "undefined") {
    delete values.system_status_management
  }
  if (typeof values?.user_daily_record_request != "undefined") {
    delete values.user_daily_record_request
  }
  return API.post(`/user-attendance`, values)
}

export const getUserAttendanceDailyList = ({
  facilityIds,
  service,
  date,
}: {
  facilityIds?: string
  service?: string
  date: string
}) => {
  const queries = []
  queries.push(`date=${date}`)
  if (facilityIds) {
    queries.push(`facilityIds=${facilityIds}`)
  }
  if (service) {
    queries.push(`service=${service}`)
  }
  return API.get(`/user-attendance/daily?${queries.join("&")}`)
}

export const quickUserAttendance = (values) =>
  API.post(`/user-attendance/quick-attendance`, values)

export const getSchedulableUsersForAttendance = ({ facility, date }) =>
  API.get(`/user-attendance/schedule-users?date=${date}&facility=${facility}`)

export const addSchedulableUsersForAttendance = (values) =>
  API.post(`/user-attendance/schedule-users`, values)

export const getUsersAbsenceRecord = (
  filterOptions: UserAbsenceRecordSearch,
) => {
  const query = []
  if (filterOptions?.user_id) {
    query.push(`user_id=${filterOptions.user_id}`)
  }
  if (filterOptions.from_date) {
    query.push(`from_date=${filterOptions.from_date.format("YYYY-MM-DD")}`)
  }
  if (filterOptions.to_date) {
    query.push(`to_date=${filterOptions.to_date.format("YYYY-MM-DD")}`)
  }
  if (filterOptions.facilityIds.length) {
    query.push(`facilityIds=${filterOptions.facilityIds.join(",")}`)
  }
  if (filterOptions.service.length) {
    query.push(`service=${filterOptions.service.join(",")}`)
  }
  if (filterOptions.attendance_type.length) {
    query.push(`attendance_type=${filterOptions.attendance_type.join(",")}`)
  }
  if (filterOptions.page) {
    query.push(`page=${filterOptions.page}`)
  }
  if (filterOptions.pageSize) {
    query.push(`pageSize=${filterOptions.pageSize}`)
  }
  return API.get(`/user-attendance/absence-information?${query?.join("&")}`)
}
export const deleteDailyAttendance = (values: {
  facility_id: number
  user_id: number
  date: string
}) => {
  return API.post(`/user-attendance/delete-attendance`, values)
}
export const bulkCreateOrUpdateUserAttendance = (values: any) => {
  return API.post(`/user-attendance/bulk-update`, values)
}
