import React, { ReactNode } from "react"

interface ContentProps {
  background?: string
  children?: ReactNode
}
const ContentContainer = ({
  background = "#FFFFFF",
  children,
}: ContentProps): JSX.Element => {
  return (
    <section
      style={{
        background,
      }}
    >
      {children}
    </section>
  )
}
ContentContainer.displayName = "ContentContainer"
export { ContentContainer }
