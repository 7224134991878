import {
  API,
  DEV_ENVIRONMENTS,
  LanguageOption,
  analytics,
  auth,
  catchExceptionCallback,
  i18next,
} from "@project/common"
import * as Sentry from "@sentry/node"
import { App, ConfigProvider } from "antd"
import jaJP from "antd/lib/locale/ja_JP"
import { logEvent, setCurrentScreen } from "firebase/analytics"
import { onAuthStateChanged, signOut } from "firebase/auth"
import { AppProps } from "next/app"
import Head from "next/head"
import { useRouter } from "next/router"
import NextNProgress from "nextjs-progressbar"
import { useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { QueryClient, QueryClientProvider } from "react-query"
import { ReactQueryDevtools } from "react-query/devtools"
//custom
import { AuthProvider } from "../context/AuthContext"
import {
  fetchFacilities,
  fetchJointFacility,
  fetchNonDraftFacilities,
} from "../services"
import { GlobalStyles } from "../utils"
import { FilterAccessibleFacilitiesByPage } from "../utils/pageAccessUtils"
import { menuItems } from "../utils/sidebarUtils"

const queryClient = new QueryClient({ defaultOptions: {} })

if (process.env.NEXT_PUBLIC_SENTRY_DSN) {
  Sentry.init({
    enabled: process.env.NODE_ENV !== "development",
    environment: `adult-${process.env.NODE_ENV}`,
    dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
  })
}

function MyApp({ Component, pageProps }: AppProps) {
  const isCollapsed =
    typeof window !== "undefined"
      ? localStorage.getItem("easyy_collapse_menu") === "true"
      : false
  const { t } = useTranslation()
  const routers = useRouter()
  const [loading, setLoading] = useState(true)
  const [user, setUser] = useState(null)
  const [isOwner, setIsOwner] = useState(false)
  const [permissions, setPermissions] = useState(null)
  const [facilities, setFacilities] = useState([])
  const [otherFacilities, setOtherFacilities] = useState(null)
  const [facilitiesWithChildCount, setFacilitiesWithChildCount] = useState([])
  const [companyName, setCompanyName] = useState("")
  const [companyId, setCompanyId] = useState(0)
  const allFacilities = useRef(null)
  //for side menu bar logic start
  const [collapsed, setCollapsed] = useState(isCollapsed)
  const defaultKey = []
  let defaultCurrent = "home"
  if (collapsed) {
    menuItems?.map(
      (v) =>
        v?.children?.map((sub) => {
          if (sub?.link?.startsWith(routers?.pathname)) {
            defaultKey.push(sub?.key)
            defaultCurrent = sub?.key
          }
        }),
    )
  }
  const [openKeys, setOpenKeys] = useState(defaultKey || [])
  const [current, setCurrent] = useState(defaultCurrent)

  //for side menu bar logic end
  const dispatchUser = () => {
    signOut(auth)
    setUser(null)
  }

  const handleCollapse = (value: boolean) => {
    const val = value ?? !collapsed
    localStorage.setItem("easyy_collapse_menu", val ? "true" : "false")
    setCollapsed(val)
  }
  useEffect(() => {
    if (process.env.NODE_ENV === "production") {
      const logAnalyticsEvent = (url: string) => {
        setCurrentScreen(analytics, url)
        logEvent(analytics, "screen_view", {
          firebase_screen: url,
          firebase_screen_class: "easyy-adult",
        })
      }

      routers.events.on("routeChangeComplete", (url) => {
        logAnalyticsEvent(url)
      })

      logAnalyticsEvent(window.location.pathname)
      return () => {
        routers.events.off("routeChangeComplete", logAnalyticsEvent)
      }
    }
  }, [])

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      try {
        if (user) {
          const idToken = await user!.getIdTokenResult()
          if (
            idToken.claims["company_admin"] ||
            idToken.claims["isCompanyOwner"] ||
            idToken.claims["company_staff"]
          ) {
            setUser(user)
            if (
              idToken.claims["company_admin"] ||
              idToken.claims["isCompanyOwner"]
            ) {
              setIsOwner(true)
            }
            const permissionResponse = await API.get(
              "/company-users/my-permissions",
            )
            setPermissions(permissionResponse?.data)
            const facilitiesResponse = await fetchNonDraftFacilities()
            allFacilities.current = facilitiesResponse?.data
            setFacilities(facilitiesResponse?.data)
            // const facilityOtherResponse = await fetchJointFacility()
            setOtherFacilities({})
            // const facilityWithCount = await getAllFacilitiesWithChildCount()
            setFacilitiesWithChildCount(null)
            const companyResponse = await API.get(
              `/company/${idToken.claims.companyId}`,
            )
            setCompanyName(companyResponse.data?.company_name)
            setCompanyId(idToken.claims.companyId)
          }
        }
      } catch (error) {
        // undefined error such as cancellation of request due to navigation is causing sign out in safari
        // so logout user only if error is defined
        error && dispatchUser()
        let message = error
        if (error?.status == 423) {
          message = t(
            "The account has been locked. Please contact administration",
          )
        }
        if (error?.status == 401) {
          message = "Unauthorised User"
        }
        catchExceptionCallback(message)
        Sentry.captureException(error)
      } finally {
        setLoading(false)
      }
    })

    return () => unsubscribe && unsubscribe()
  }, [])

  useEffect(() => {
    if (!isOwner && allFacilities.current && permissions) {
      setFacilities(
        FilterAccessibleFacilitiesByPage(
          allFacilities.current,
          permissions?.user_facility_permissions,
          routers.pathname,
        ),
      )
    }
  }, [routers.pathname, permissions, allFacilities.current, isOwner])

  return (
    <>
      <Head>
        <title>{t("Employment support system")}</title>
        <meta
          name={"viewport"}
          content={
            "width=device-width, minimum-scale=1.0, maximum-scale=1.0, user-scalable=no"
          }
        />
      </Head>
      <GlobalStyles />
      <QueryClientProvider client={queryClient}>
        <NextNProgress
          color={"#77D5F7"}
          height={8}
          showOnShallow={true}
          options={{
            showSpinner: false,
          }}
        />
        <App>
          <AuthProvider
            loading={loading}
            user={user}
            isOwner={isOwner}
            setUser={setUser}
            permissions={permissions}
            facilities={facilities}
            refetchFacilities={async () => {
              const facilitiesResponse = await fetchFacilities()
              setFacilities(facilitiesResponse?.data)
            }}
            otherFacilities={otherFacilities}
            companyName={companyName}
            refetchOtherFacilities={async () => {
              const other = await fetchJointFacility({ queryKey: {} })
              setOtherFacilities(other?.data)
            }}
            facilitiesWithChildCount={facilitiesWithChildCount}
            collapsed={collapsed}
            setCollapsed={setCollapsed}
            queryClient={queryClient}
            companyId={companyId}
            sideBarProps={{ openKeys, setOpenKeys, current, setCurrent }}
            sideBarToggleProps={{ collapsed, setCollapsed, handleCollapse }}
          >
            <ConfigProvider locale={i18next?.language === "ja" && jaJP}>
              <Component {...pageProps} />
              {DEV_ENVIRONMENTS.includes(
                process.env.NEXT_PUBLIC_ENVIRONMENT,
              ) ? (
                <LanguageOption />
              ) : (
                <></>
              )}
            </ConfigProvider>
          </AuthProvider>
        </App>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </>
  )
}

export default MyApp
