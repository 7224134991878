import { API } from "@project/common"

export const getUserMonthFacilitySchedule = ({
  user_id,
  year,
  month,
}: {
  user_id: string | number
  year: string | number
  month: string | number
}) => {
  return API.get(`/user-facility-schedules/monthly/${user_id}/${year}/${month}`)
}
export const createOrUpdateUserFacilitySchedules = (values: any) => {
  return API.post(`/user-facility-schedules`, values)
}
