import { CloseOutlined, MenuOutlined } from "@ant-design/icons"
import { Affix, Layout } from "antd"
import dayjs from "dayjs"
import { useTranslation } from "react-i18next"
import { useQuery } from "react-query"
import { FULL_DATE_FORMATE } from "../../../../adult/constants"
import { API } from "../../api"
import UserIcon from "../../assets/icons/AlertBell.svg"
import CollapseIcon from "../../assets/icons/CollapseIcon.svg"
import PersonIcon from "../../assets/icons/Person.svg"
import MLogo from "../../assets/m-logo.svg"
import { Tag } from "../../components"
import { theme } from "../../theme"
import { HeaderWrapper, UserIconStyle } from "./AuthorizedHeader.style"
import { useRouter } from "next/router"

const { Header } = Layout
interface AuthorizedHeaderProps {
  setCollapsed: React.Dispatch<React.SetStateAction<any>>
  collapsed: boolean
  setIsMenuToggle: React.Dispatch<React.SetStateAction<any>>
  isToggled?: boolean
  isTabletOrMobile?: boolean
  logOut: () => void
  headerInfo?: {
    isOwner?: string | undefined | null
    companyName?: string | undefined | null
  }
}

const MobileLogo = (): JSX.Element => {
  return (
    <div
      className={"logo__content"}
      style={{
        display: "flex",
        alignItems: "center",
      }}
    >
      <MLogo />
      <span>{"就労支援"}</span>
    </div>
  )
}

const AuthorizedHeader = ({
  collapsed,
  setCollapsed,
  setIsMenuToggle,
  isToggled,
  isTabletOrMobile,
  logOut,
  headerInfo,
}: AuthorizedHeaderProps): JSX.Element => {
  const { t } = useTranslation()
  const router = useRouter()
  const currentDate = dayjs().format(FULL_DATE_FORMATE)
  const userRole = headerInfo?.isOwner
    ? t("System Administrator")
    : t("Company Staff")

  const companyName = headerInfo?.companyName || ""

  const { data: response } = useQuery<any>({
    queryKey: ["company-notifications"],
    queryFn: async () => {
      const response = await API.get("/company-notifications/count-unseen")
      return response
    },
    refetchOnWindowFocus: false,
  })

  return (
    <HeaderWrapper isMobile={isTabletOrMobile}>
      {isTabletOrMobile && (
        <div className={"mobile_extra_header"}>
          <div className={"left"}>
            <span className={"date"}>{currentDate}</span>
            <Tag
              title={userRole}
              defaultBg={theme.colors.accent}
              borderColor={theme.colors.accent}
              align={"center"}
              padding={"2px 3px"}
            />
          </div>
          <div className={"right"}>{companyName}</div>
        </div>
      )}

      <Header className={"header__container"}>
        {!isTabletOrMobile ? (
          <div className={"header__container--big"}>
            <Affix>
              <div
                className={"faculty-sidebar-trigger"}
                onClick={() => {
                  localStorage?.setItem(
                    "easyy_collapse_menu",
                    JSON.stringify(!collapsed),
                  )
                  setCollapsed(!collapsed)
                }}
              >
                <CollapseIcon
                  className={collapsed ? "faculty-sidebar-trigger-rotate" : ""}
                />
              </div>
            </Affix>

            <div className={"faculty-dashboard-header-user-info"}>
              {!isTabletOrMobile ? (
                <>
                  <span>{currentDate}</span>
                  <span className={"faculty-dashboard-header-user-info-role"}>
                    {userRole}
                  </span>
                  <span className={"faculty-dashboard-header-user-info-person"}>
                    <PersonIcon />
                    &nbsp;&nbsp;{companyName}
                  </span>
                  <UserIconStyle>
                    <span
                      className={"faculty-dashboard-header-user-info-name"}
                      onClick={() => router.push("/notification")}
                    >
                      <UserIcon />
                      {response?.data?.unSeenNotificationCount > 0 && (
                        <span
                          className={
                            "faculty-dashboard-header-top-announcement-count"
                          }
                        >
                          {response?.data?.unSeenNotificationCount}
                        </span>
                      )}
                      &nbsp;&nbsp;{t("Notification")}
                    </span>
                  </UserIconStyle>
                  <div
                    className={"faculty-dashboard-header-user-info-logout"}
                    onClick={logOut}
                  >
                    {t("Logout")}
                  </div>
                </>
              ) : (
                <div
                  onClick={setIsMenuToggle}
                  className={"mobile__menu-toggler"}
                >
                  {isToggled ? <CloseOutlined /> : <MenuOutlined />}
                </div>
              )}
            </div>
          </div>
        ) : (
          <div className={"header__container--small"}>
            <MobileLogo />
            <div onClick={setIsMenuToggle} className={"mobile__menu-toggler"}>
              {isToggled ? <CloseOutlined /> : <MenuOutlined />}
            </div>
          </div>
        )}
      </Header>
    </HeaderWrapper>
  )
}
AuthorizedHeader.displayName = "AuthorizedHeader"
export { AuthorizedHeader }
