import { Layout as AntLayout } from "antd"
import { ReactNode, useState } from "react"

import { useResponsiveMediaQuery } from "../../hooks/useResponsiveMediaQuery"
import { SIDE_BAR_PROPS, SIDE_TOGGLE_PROPS } from "../../types"
import { AuthorizedFooter } from "./AuthorizedFooter"
import { AuthorizedHeader } from "./AuthorizedHeader"
import { DrawerSidebar } from "./DrawerSidebar"
import { LayoutWrapper } from "./layout.style"
import { Sidebar } from "./Sidebar"

interface LayoutProps {
  menuItems: any[]
  children: ReactNode
  logOut: () => void
  headerInfo?: any
  sideBarProps: SIDE_BAR_PROPS
  sideBarToggleProps: SIDE_TOGGLE_PROPS
}

const Layout = ({
  children,
  menuItems,
  logOut,
  headerInfo,
  sideBarProps,
  sideBarToggleProps: { collapsed, setCollapsed, handleCollapse },
}: LayoutProps): JSX.Element => {
  const [isMenuToggle, setIsMenuToggle] = useState(false)
  const { isTabletOrMobile } = useResponsiveMediaQuery()

  return (
    <LayoutWrapper>
      <AntLayout hasSider>
        {isTabletOrMobile ? (
          <DrawerSidebar
            setIsMenuToggle={setIsMenuToggle}
            isToggled={isMenuToggle}
            menuItems={menuItems}
            logOut={logOut}
            sideBarProps={sideBarProps}
          />
        ) : (
          <Sidebar
            setCollapsed={setCollapsed}
            collapsed={collapsed}
            menuItems={menuItems}
            logOut={logOut}
            sideBarProps={sideBarProps}
            handleCollapse={handleCollapse}
          />
        )}
        <AntLayout
          style={{ marginLeft: isTabletOrMobile ? 0 : collapsed ? 81 : 260 }}
        >
          <div className={"main__container"}>
            <AuthorizedHeader
              setCollapsed={setCollapsed}
              collapsed={collapsed}
              setIsMenuToggle={() => setIsMenuToggle(!isMenuToggle)}
              isTabletOrMobile={isTabletOrMobile}
              logOut={logOut}
              isToggled={isMenuToggle}
              headerInfo={headerInfo}
            />
            <AntLayout.Content
              className={"main__container--content"}
              style={{
                margin: isTabletOrMobile ? "10px 10px" : "15px 20px",
              }}
            >
              {children}
            </AntLayout.Content>
            <AuthorizedFooter />
          </div>
        </AntLayout>
      </AntLayout>
    </LayoutWrapper>
  )
}
Layout.displayName = "Layout"
export { Layout }
