import { API, removeBlankAttributes } from "@project/common"
import { AssessmentFormValues } from "../types"

export const getAllAssessment = (args: any) => {
  const params = removeBlankAttributes(args)
  return API.get("/assessment", { params })
}

export const getAssessmentSettings = () => {
  return API.get("/assessment-setting?display_settings=1")
}

export const createAssessment = (values: AssessmentFormValues) => {
  return API.post("/assessment", { ...values })
}

export const getAssessment = (id: string) => {
  return API.get(`/assessment/${id}`)
}

export const updateAssessment = ({
  assessment_id,
  ...values
}: AssessmentFormValues & { assessment_id: string }) => {
  return API.put(`/assessment/${assessment_id}`, { ...values })
}

export const deleteAssessment = (id) => API.delete(`/assessment/${id}`)
