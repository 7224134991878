import React from "react"
import { ConfigProvider } from "antd"
import { CalendarWrapper } from "./Calendar.style"
import { CalendarProps } from "../../types"
import { PrimaryCalendar } from "./PrimaryCalendar"
import { SecondaryCalendar } from "./SecondaryCalendar"

const Calendar = ({
  type = "PRIMARY",
  className,
  ...props
}: CalendarProps): JSX.Element => {
  const renderCalendar = () => {
    switch (type) {
      case "PRIMARY":
        return <PrimaryCalendar {...props} />
      case "SECONDARY":
        return <SecondaryCalendar {...props} />
      default:
        return null
    }
  }
  return (
    <ConfigProvider
      theme={{
        components: {
          Calendar: {
            itemActiveBg: "none",
          },
        },
      }}
    >
      <CalendarWrapper className={className}>
        {renderCalendar()}
      </CalendarWrapper>
    </ConfigProvider>
  )
}
Calendar.DisplayName = "Calendar"
export { Calendar }
