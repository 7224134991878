import styled from "styled-components"
import { Collapse } from "antd"
import { DownOutlined } from "@ant-design/icons"
import { Card } from "antd/lib"
const { Panel } = Collapse

interface LiskKeyValue {
  key: string
  value: string
}
interface DateCardListProps {
  title?: string
  content?: string
  subContent?: string
  expandTitle?: string
  expandText?: string
  station?: string
  dates?: Date[]
  borderColor?: string
  icon?: string
  list?: Array<LiskKeyValue>
  showCollapse?: boolean
}

const Container = styled(Card)`
  border: 1px solid #e00000;

  .contents {
    .title {
      display: flex;
      color: #e00000;
      gap: 12px;
      font-size: 16px;
      font-weight: 600;
      align-items: center;
      .icon-wrapper {
        height: 20px;
      }
    }
    .content,
    .sub-content {
      color: #e00000;
      margin-top: -2px;
      font-weight: 500;
    }
  }
  .ant-collapse-ghost {
    border: none !important;
  }
`

const StyledCollapse = styled(Collapse)<{ borderColor?: string }>`
  @media screen and (max-width: 600px) {
    * {
      font-size: 12px;
    }
    & .ant-collapse-content-box {
      padding-top: 6px !important;
      padding-bottom: 6px !important;
    }
  }
  .ant-collapse-item > .ant-collapse-header {
    padding: 0;
    color: #e00000;
    margin-top: 12px;
    text-decoration: underline;
    font-weight: 500;
  }
  .ant-collapse-item > .ant-collapse-content {
    padding: 0;
    color: #e00000;
  }
  .ant-collapse-content > .ant-collapse-content-box {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
`

const TableWrapper = styled.table`
  width: 100%;
  border: solid 1px #d2d1d1;
  border-collapse: collapse;
`

const LeftColumn = styled.td`
  background-color: #f3f3f3;
  border: solid 1px #d2d1d1;
  border-collapse: collapse;
  width: 15rem;
  padding: 1rem;
  color: #000000;
  @media (max-width: 767px) {
    width: 5rem;
  }
`
const RightColumn = styled.td`
  border: solid 1px #d2d1d1;
  border-collapse: collapse;
  padding: 1rem;
  color: #000000;
  @media (max-width: 767px) {
    font-size: 14px;
  }
`

export const DateCardList = ({
  title,
  content,
  subContent,
  expandTitle,
  icon,
  showCollapse = true,
  list = [],
}: DateCardListProps) => {
  return (
    <Container>
      <div className={"contents"}>
        <div className={"title"}>
          {icon ? (
            <div className={"icon-wrapper"}>
              <img src={icon} width={20} height={20} alt={"icon"} />
            </div>
          ) : (
            ""
          )}
          {title}
        </div>
        <div className={"content"}>{content}</div>
        <div className={"sub-content"}>{subContent}</div>
      </div>
      {showCollapse ? (
        <StyledCollapse
          className={"expand-title"}
          ghost
          expandIcon={() => (
            <DownOutlined
              style={{
                verticalAlign: "middle",
              }}
            />
          )}
        >
          <Panel key={""} header={expandTitle}>
            <TableWrapper>
              {list?.map((l, index) => (
                <tr key={index}>
                  <LeftColumn>{l.key}</LeftColumn>
                  <RightColumn>{l.value}</RightColumn>
                </tr>
              ))}
            </TableWrapper>
          </Panel>
        </StyledCollapse>
      ) : null}
    </Container>
  )
}
