import { API, removeBlankAttributes } from "@project/common"

import {
  WRBusinessActivityStatement,
  WageRevenueTabResponse,
  I_EmploymentBusinessSaleDivision,
  I_EMPLOYMENT_BUSINESS_STATEMENT,
} from "../types"

//overall tab
export const getAllOverallWageRevenue = ({
  facility_id,
  year,
}: {
  facility_id: number
  year: number
}) => {
  return API.get(`/overall-wage-revenue/${facility_id}/${year}`)
}

export const getAllWageRevenues = ({
  year,
  month,
  ...rest
}: {
  date?: any
  year: number
  month: number
}) => {
  const params = removeBlankAttributes(rest)
  return API.get(`/wage-management/${year}/${month}`, { params })
}
export const createWageManagement = ({
  values,
  year,
  month,
}: {
  values: WageRevenueTabResponse[]
  year: number
  month: number
}) => {
  return API.post(`/wage-management/${year}/${month}`, values)
}

// Business Activity Statement
export const getAllFacilityBusinessActivityStatement = ({
  year,
  month,
}: {
  year: number
  month: number
}) => {
  return API.get(`/business-activity-statement/${year}/${month}`)
}
export const createBusinessActivityStatement = ({
  year,
  month,
  facility_id,
  values,
}: {
  year: number
  month: number
  facility_id: number
  values: WRBusinessActivityStatement[]
}) => {
  return API.put(
    `/business-activity-statement/${year}/${month}/${facility_id}`,
    values,
  )
}
export const getBusinessActivityStatementByFacility = ({
  year,
  month,
  facility_id,
}: {
  year: number
  month: number
  facility_id: number
}) => {
  return API.get(`/business-activity-statement/${year}/${month}/${facility_id}`)
}

//EmploymentSupportBusinessStatement

//EmploymentSupportBusinessStatement
export const getAllFacilityEmploymentSupportBusinessStatement = ({
  year,
  month,
}: {
  year: number
  month: number
}) => {
  return API.get(`/employment-support-business-statement/${year}/${month}`)
}
export const createEmploymentSupportBusinessStatement = ({
  year,
  month,
  facility_id,
  values,
}: {
  year: number
  month: number
  facility_id: number
  values: I_EMPLOYMENT_BUSINESS_STATEMENT[]
}) => {
  return API.put(
    `/employment-support-business-statement/${year}/${month}/${facility_id}`,
    values,
  )
}
export const getEmploymentSupportBusinessStatementByFacility = ({
  year,
  month,
  facility_id,
}: {
  year: number
  month: number
  facility_id: number
}) => {
  return API.get(
    `/employment-support-business-statement/${year}/${month}/${facility_id}`,
  )
}
//Employment Support Business Sales Division
export const getAllFacilityBusinessSaleDivision = ({
  year,
  month,
}: {
  year: number
  month: number
}) => {
  return API.get(`/business-sale-division/${year}/${month}`)
}
export const getBusinessSaleDivisionByFacility = ({
  year,
  month,
  facility_id,
}: {
  year: number
  month: number
  facility_id: number
}) => {
  return API.get(`/business-sale-division/${year}/${month}/${facility_id}`)
}

export const updateBusinessSaleDivisionByFacility = ({
  year,
  month,
  facility_id,
  values,
}: {
  year: number
  month: number
  facility_id: number
  values: I_EmploymentBusinessSaleDivision[]
}) => {
  return API.put(
    `/business-sale-division/${year}/${month}/${facility_id}`,
    values,
  )
}
