import styled from "styled-components"
import { theme } from "../../theme"

export const FeatureNotAvailableInternalContainer = styled.div`
  height: calc(100vh - 224.5px);
  .ant-card {
    overflow: hidden;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    .ant-card-body {
      padding: 0 !important;
      height: 60%;
      width: 100%;
      position: relative;
      .info-container {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;
        z-index: 1;
        margin-top: -115px;
        text-align: center;
        gap: 12px;
        color: ${theme.colors.text};
        .main-title {
          font-size: 32px;
          line-height: normal;
          font-weight: 700;
        }
        .description {
          font-size: 14px;
          line-height: 24px;
          max-width: 394px;
        }
        .back-btn {
          border-color: ${theme.colors.link};
        }
      }
      &::after {
        position: absolute;
        width: calc(100% + 300px);
        height: 900px;
        content: "";
        border-radius: 50%;
        background-color: #f2f2f2;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        z-index: 0;
      }
    }
  }
`
