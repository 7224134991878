import dayjs, { Dayjs } from "dayjs"
import { CalendarObjProps, CalendarProps } from "../../types"
import { AntCalendarWrapper } from "./Calendar.style"

const PrimaryCalendar = ({
  cellRender,
  value,
  minWidth,
  headBg,
  dateStyle,
  dateBg,
}: CalendarProps): JSX.Element => {
  return (
    <AntCalendarWrapper
      $minWidth={minWidth}
      $headBg={headBg}
      $dateBg={dateBg}
      value={dayjs(value)}
      headerRender={() => null}
      disabledDate={(date: Dayjs) => {
        const today = value ? dayjs(value) : dayjs()
        const isThisMonth = today.month() !== date.month()
        return isThisMonth
      }}
      validRange={[
        dayjs([dayjs(value)?.year(), dayjs(value)?.month(), 1]),
        dayjs([
          dayjs(value)?.year(),
          dayjs(value)?.month(),
          dayjs(value).daysInMonth() + 1,
        ]),
      ]}
      fullCellRender={(date: Dayjs) => {
        const today = value ? dayjs(value) : dayjs()
        const active = today.month() === date.month()
        const { isHoliday, content, holidayContent } = cellRender(
          date,
        ) as CalendarObjProps
        return active ? (
          <div
            className={`content ${isHoliday ? "holiday__content" : ""}`}
            title={""}
          >
            <div className={"month_day"}>
              <div className={dateStyle === "FULL" ? "full_width" : "date"}>
                {dayjs(date).format("DD")}
              </div>
            </div>

            <div className={"info"}>
              {isHoliday ? holidayContent || "" : content}
            </div>
          </div>
        ) : (
          <div className={"content"}>
            <div className={"month_day"}>
              <div
                className={dateStyle === "FULL" ? "full_width" : "date"}
                style={{
                  opacity: 0.3,
                }}
              >
                {dayjs(date).format("DD")}
              </div>
            </div>
            <div className={"info"} />
          </div>
        )
      }}
      fullscreen
    />
  )
}
PrimaryCalendar.DisplayName = "PrimaryCalendar"
export { PrimaryCalendar }
