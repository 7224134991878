import { DatePicker as AntdDatePicker, Button, DatePickerProps } from "antd"
import locale from "antd/lib/date-picker/locale/ja_JP"
import dayjs from "dayjs"
import "dayjs/locale/ja"
import React from "react"
import { useTranslation } from "react-i18next"
import { DatePickerCompProps } from "../../types"
import {
  checkForWidth,
  handleScrollBodyBehavior,
  handleScrollTableBehavior,
} from "../../utils"
import { Wrapper } from "./DatePicker.style"

const dateFormat = "YYYY年MM月DD日"

const DatePicker: React.FC<DatePickerCompProps & DatePickerProps> = (props) => {
  const { t } = useTranslation()
  const {
    date,
    onDateChange,
    placeholder,
    style,
    name,
    disabledDate,
    disabled,
    picker = "date",
    format = dateFormat,
    showArrow = true,
    onBlur,
    clear = false,
    scrollPreventEl,
    popupClassName,
  } = props
  dayjs.locale("ja", {
    weekdaysMin: ["日", "月", "火", "水", "木", "金", "土"],
    monthsShort: [
      "01月",
      "02月",
      "03月",
      "04月",
      "05月",
      "06月",
      "07月",
      "08月",
      "09月",
      "10月",
      "11月",
      "12月",
    ],
  })
  const arrows = showArrow
    ? {}
    : {
        superPrevIcon: null,
        superNextIcon: null,
      }
  const [openCalendar, setOpenCalendar] = React.useState(false)
  const [mode, setMode] = React.useState<any>(picker)
  const [dateMode, setDateMode] = React.useState<any>("")

  React.useEffect(() => {
    handleScrollBodyBehavior(openCalendar)
    if (scrollPreventEl) {
      handleScrollTableBehavior(openCalendar, scrollPreventEl)
    }
  }, [openCalendar])
  React.useEffect(() => {
    setMode(mode)
  }, [dateMode])
  const [defaultFormat, setDefaultFormat] = React.useState<any>(format)
  const renderFooter = () => {
    return (
      <div className={"faculty-datepicker-custom-footer"}>
        <div className={"faculty-datepicker-custom-footer-first"}>
          <Button
            onClick={() => {
              onDateChange(dayjs())
              setMode(picker === "month" ? "month" : "date")
            }}
            shape={"round"}
            style={{
              padding: "2px 8px",
              width: "fit-content",
            }}
          >
            {t("Today")}
          </Button>
          <Button
            onClick={() => {
              if (
                dateMode === "decade" ||
                dateMode === "year" ||
                (dateMode === "month" && picker !== "month")
              ) {
                setMode(
                  picker === "month"
                    ? mode === "month"
                      ? "mm"
                      : "month"
                    : mode === "date"
                      ? "default"
                      : "date",
                )
              } else {
                if (!dayjs(date).isValid()) {
                  onDateChange(dayjs(date))
                } else {
                  onDateChange(null)
                }
              }
            }}
            shape={"round"}
            style={{
              padding: "2px 8px",
              width: "fit-content",
            }}
          >
            {dateMode === "decade" ||
            dateMode === "year" ||
            (dateMode === "month" && picker !== "month")
              ? t("Back")
              : t("Clear")}
          </Button>
        </div>
        <div className={"faculty-datepicker-custom-footer-second"}>
          <Button
            onClick={() => {
              if (picker === "month") {
                setMode("month")
              } else {
                setMode("date")
              }
              setOpenCalendar(false)
            }}
            shape={"round"}
            style={{
              padding: "2px 8px",
              width: "fit-content",
            }}
          >
            {t("Close")}
          </Button>
        </div>
      </div>
    )
  }

  return (
    <Wrapper>
      <div
        className={
          openCalendar
            ? "faculty-datepicker faculty-datepicker-mobile-open"
            : "faculty-datepicker"
        }
      >
        <AntdDatePicker
          locale={locale}
          allowClear={clear}
          picker={mode === "mm" ? "month" : mode}
          className={props.className}
          popupClassName={`eassy_adult_date_picker ${popupClassName}`}
          inputReadOnly={checkForWidth(openCalendar)}
          placeholder={`${placeholder || format}`}
          value={dayjs(date)?.isValid() ? date : null}
          showToday={false}
          format={defaultFormat}
          style={{
            minWidth:
              picker === "month"
                ? "120px"
                : picker === "year"
                  ? "86px"
                  : "160px",

            minHeight: "40px",
            ...style,
          }}
          name={name}
          disabled={disabled}
          open={openCalendar}
          disabledDate={disabledDate}
          status={props.status}
          {...arrows}
          onOpenChange={(val) => {
            setOpenCalendar(val)
          }}
          onChange={(value) => {
            onDateChange(value)
          }}
          onPanelChange={(_, currentMode) => {
            setDateMode(currentMode)
          }}
          onKeyDown={() => {
            setDefaultFormat(picker === "month" ? "YYYYMM" : "YYYYMMDD")
          }}
          onBlur={
            onBlur
              ? onBlur
              : () => {
                  if (picker === "month") {
                    setOpenCalendar(false)
                    setMode("month")
                    setDateMode("")
                  } else if (picker === "year") {
                    setOpenCalendar(false)
                    setMode("year")
                    setDateMode("")
                  } else {
                    setOpenCalendar(false)
                    setMode("date")
                    setDateMode("")
                  }
                  setDefaultFormat(format)
                }
          }
          renderExtraFooter={() => renderFooter()}
          getPopupContainer={props.getPopupContainer}
        />

        {props.error && <div className={"field-error"}>{props.error}</div>}
      </div>
    </Wrapper>
  )
}

export { DatePicker }
