import React from "react"

const XFileIcon = () => {
  return (
    <svg
      width={"17"}
      height={"16"}
      viewBox={"0 0 17 16"}
      fill={"none"}
      xmlns={"http://www.w3.org/2000/svg"}
    >
      <path
        d={
          "M10.1667 1.33203H4.83333C4.47971 1.33203 4.14057 1.47251 3.89052 1.72256C3.64048 1.9726 3.5 2.31174 3.5 2.66536V13.332C3.5 14.072 4.09333 14.6654 4.83333 14.6654H12.8333C13.5733 14.6654 14.1667 14.072 14.1667 13.332V5.33203L10.1667 1.33203ZM12.8333 13.332H4.83333V2.66536H9.5V5.9987H12.8333V13.332ZM9.43333 9.66536L11.3667 12.6654H10.1667L8.83333 10.3987L7.5 12.6654H6.3L8.23333 9.66536L6.3 6.66536H7.5L8.83333 8.93203L10.1667 6.66536H11.3667L9.43333 9.66536Z"
        }
        fill={"currentColor"}
      />
    </svg>
  )
}

export default XFileIcon
