import { keys } from "@project/common"
import { AnyObject } from "antd/es/_util/type"

/**
 * @param
 * ```
 * [{"Id": 15, "FacilityId": 11, "ServiceType": 0, "ActualCostId": 9, "FacilityName": "Adult PK5", "FacilityNameFurigana": "furigana"}, {"Id": 14, "FacilityId": 12, "ServiceType": 0, "ActualCostId": 9, "FacilityName": "Adult PK23", "FacilityNameFurigana": "furigana"}]
 * ```
 * @description Sometimes data comes in this format [stringified list]
 * you can notice that 'key' in the object is uppercase, we want first char to be lowercased
 *
 * @returns lowercased key of the object
 * */
export const lowercaseFirstChar = <TRecord extends AnyObject>(
  stringifiedData: string,
) => {
  if (!stringifiedData) {
    return []
  }
  const parsedList = JSON.parse(stringifiedData) as Array<Record<string, any>>

  return parsedList.map((item) =>
    keys(item).reduce<Partial<TRecord>>((acc, key) => {
      return {
        ...acc,
        [key.charAt(0).toLowerCase() + key.slice(1)]: item[key],
      }
    }, {}),
  )
}
