import React, { useEffect, useState } from "react"
import { Upload, message, notification, Space } from "antd"
import styled, { css } from "styled-components"
import { useTranslation } from "react-i18next"
import { useMutation } from "react-query"
import { CloseCircleFilled } from "@ant-design/icons"
import { fileUpload } from "../../services"
import { Button } from "../Button/Button"
import { ErrorMassage } from "../ErrorMassage/ErrorMassage"
import { pdfFilenameExtract } from "../../utils"

interface ImageUploadProps {
  onChange?: (fileName?: any, value?: any) => void
  uploadHint?: string
  isLoading?: (value: boolean) => void
  currentImage?: any
  isWidthBigger?: boolean
  btnDefault?: boolean
  [key: string]: any
  btnText?: string
  errors?: any
}

const Wrapper = styled.div<{ widthMore?: boolean; $btnDefault?: boolean }>`
  .delete-btn button {
    color: red;
  }

  .file-area {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
    ${(props) =>
      props?.$btnDefault &&
      css`
        .ant-btn {
          color: #888787;
          border: 1px solid #d2d1d1;
          border-radius: 5px;
          font-size: 14px;
          font-weight: 400;
          width: props.widthMore ? "150px !important" : "130px";
          height: 30px;
          display: inline-flex;
          justify-content: center;
          align-items: center;
          margin-right: 11px;
          @media (max-width: 360px) {
            margin-right: 0px;
          }
          .ant-space-item {
            font-size: 16px;
            font-weight: 400;
          }
        }
      `}

    & .min-width-class {
      margin-left: 5px;
      @media (max-width: 411px) {
        min-width: 98px;
      }
    }
  }
  .upload-hint {
    font-size: 14px;
    font-weight: 400;
    color: #191919;
    word-break: break-all;
  }
`

const DisplayFile = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  & > :first-child {
    margin-right: 20px;
  }
  .file-name {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`

const ImageUpload: React.FC<ImageUploadProps> = ({
  onChange,
  uploadHint,
  isLoading,
  currentImage,
  isWidthBigger,
  btnDefault = true,
  btnText,
  errors,
  acceptType,
  isImg = true,
  ...rest
}) => {
  const [file, setFile] = useState(null)
  const [originalFileName, setOriginalFileName] = useState(null)
  const [imageLoadError, setImageLoadError] = useState(false)

  const { t } = useTranslation()

  useEffect(() => {
    if (currentImage != "") {
      const fileName = pdfFilenameExtract(currentImage)
      setFile(currentImage)
      isImg
        ? setOriginalFileName(
            decodeURI(
              currentImage?.file?.originFileObj?.name ||
                file?.file?.originFileObj?.name,
            ),
          )
        : setOriginalFileName(decodeURI(fileName))
    }
  }, [currentImage])

  const { isLoading: uploadLoading, mutate } = useMutation(
    "fileUpload",
    fileUpload,
    {
      onSuccess: (result) => {
        isLoading(false)
        onChange && onChange(result?.data, result?.data)
        setFile(result?.data)
      },
      onError: (error?: any) => {
        isLoading(false)
        const msg = error?.data?.error?.message
        notification.error({
          message: msg ? t(`${msg}`) : t("An error has occurred"),
        })
      },
    },
  )

  const handleDeleteClick = () => {
    setFile(null)
    onChange && onChange(null, null)
  }

  const beforeUpload = (file: any) => {
    const isPdf = file.type == "application/pdf"
    const isLt5M = +(file.size / 1000000).toFixed(2) < 5

    if (!isLt5M) {
      message.error({
        content: t(
          "You can only upload JPG/PNG/GIF/HEIC image with less than 5MB",
        ),
        key: "37",
        icon: <CloseCircleFilled onClick={() => message.destroy("37")} />,
      })
    }
    if (isPdf) {
      return isPdf && isLt5M
    }
    const isJpgOrPng =
      file.type === "image/jpeg" ||
      file.type === "image/png" ||
      file.type === "image/gif" ||
      file.type === "image/heic"
    if (!isJpgOrPng) {
      message.error({
        content: t("You can only upload JPG/PNG/GIF/HEIC file!"),
        key: "38",
        icon: <CloseCircleFilled onClick={() => message.destroy("38")} />,
      })
    }
    return isJpgOrPng && isLt5M
  }

  const handleChange = async (info) => {
    isLoading(true)
    if (info.file.status === "done") {
      const bodyFormData = new FormData()
      bodyFormData.append("file", info?.file?.originFileObj)
      bodyFormData.append("model", "temp")
      mutate(bodyFormData)
      setOriginalFileName(decodeURI(info?.file?.originFileObj?.name))
    }
  }

  return (
    <Wrapper widthMore={isWidthBigger} $btnDefault={btnDefault}>
      {!file && (
        <Upload
          showUploadList={false}
          beforeUpload={beforeUpload}
          onChange={handleChange}
          customRequest={({ onSuccess }) => {
            onSuccess("ok")
          }}
          accept={acceptType || "image/*"}
        >
          <div className={"file-area"}>
            <Button
              isLoading={uploadLoading}
              style={{
                width: "fit-content",
              }}
              btnText={
                uploadLoading ? t("Uploading") : btnText || t("Select file")
              }
              {...rest}
            />
            <Space className={"min-width-class"}>
              {t("File not selected")}
            </Space>
          </div>
          <p className={"upload-hint"}>
            {uploadHint ||
              t("(Image: jpg,gif,png,HEIC/Size:300x222px/Maximum:640x640px)")}
          </p>
        </Upload>
      )}
      {file && (
        <DisplayFile>
          <div className={"file-name"}>
            {!imageLoadError ? (
              <img
                src={file}
                onLoad={() => setImageLoadError(false)}
                onError={() => setImageLoadError(true)}
              />
            ) : (
              <p>{originalFileName}</p>
            )}
          </div>
          <br />
          <div className={"delete-btn"} onClick={handleDeleteClick}>
            <Button btnText={t("Delete")} />
          </div>
        </DisplayFile>
      )}
      {errors && <ErrorMassage message={errors} />}
    </Wrapper>
  )
}

export { ImageUpload }
