import { sizeResolver } from "./sizeResolver"
import { spacingResolver } from "./spacingResolver"
import { identityResolver } from "./identityResolver"
import { fontFamilyResolver } from "./fontFamilyResolver"
import { lineHeightResolver } from "./lineHeightResolver"
import { colorResolver, textColorResolver } from "./colorResolver"
import { fontSizeResolver } from "./font-size-resolver/font-size-resolver"

export const resolvers = {
  color: colorResolver,
  textColor: textColorResolver,
  fontSize: fontSizeResolver,
  spacing: spacingResolver,
  identity: identityResolver,
  size: sizeResolver,
  lineHeight: lineHeightResolver,
  fontFamily: fontFamilyResolver,
}

export type StylePropType = keyof typeof resolvers
