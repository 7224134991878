import React from "react"
import { CardProps as AntCardProps } from "antd"

import { AntCard } from "./Card.style"
interface CardProps extends AntCardProps {
  boxShadow?: string
  borderStyle?: string
  footer?: React.ReactNode
}
const Card = ({ children, ...props }: CardProps): JSX.Element => {
  return (
    <AntCard {...props}>
      {children}
      {props.footer && <div className={"card-footer"}>{props.footer}</div>}
    </AntCard>
  )
}
Card.displayName = "Card"
export { Card }
