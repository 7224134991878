import dayjs, { Dayjs } from "dayjs"
import { t } from "i18next"
import wareki from "wareki"
import { Option } from "../types"
const MONTHS = [
  { label: t("01月"), value: 1 },
  { label: t("02月"), value: 2 },
  { label: t("03月"), value: 3 },
  { label: t("04月"), value: 4 },
  { label: t("05月"), value: 5 },
  { label: t("06月"), value: 6 },
  { label: t("07月"), value: 7 },
  { label: t("08月"), value: 8 },
  { label: t("09月"), value: 9 },
  { label: t("10月"), value: 10 },
  { label: t("11月"), value: 11 },
  { label: t("12月"), value: 12 },
]
//get all year from now to 1990 list
const getPastYearList = (reverse?: boolean) => {
  const yearNow = Number(dayjs().format("YYYY"))
  const yearList = []
  for (let i = 1980; i <= yearNow; i = i + 1) {
    yearList.push(i)
  }
  const list = yearList.map((item) => {
    return {
      label: `${item}`,
      value: item,
    }
  })
  if (reverse) {
    return list.reverse()
  }
  return list
}

const getDateString = (year, month) => {
  const date = new Date(year, month - 1)
  const formatter = new Intl.DateTimeFormat("ja-JP", {
    year: "numeric",
    month: "2-digit",
  })
  const parts = formatter.formatToParts(date)
  const yearStr = parts.find((part) => part.type === "year").value
  const monthStr = parts.find((part) => part.type === "month").value
  return `${yearStr}年${monthStr}月`
}

const CALENDAR_HOURS_MINUTES: { hours: Option[]; minutes: Option[] } = {
  hours: [
    {
      value: "",
      label: "--",
    },
    {
      value: "00",
      label: "00",
    },
    {
      value: "01",
      label: "01",
    },
    {
      value: "02",
      label: "02",
    },
    {
      value: "03",
      label: "03",
    },
    {
      value: "04",
      label: "04",
    },
    {
      value: "05",
      label: "05",
    },
    {
      value: "06",
      label: "06",
    },
    {
      value: "07",
      label: "07",
    },
    {
      value: "08",
      label: "08",
    },
    {
      value: "09",
      label: "09",
    },
    {
      value: "10",
      label: "10",
    },
    {
      value: "11",
      label: "11",
    },
    {
      value: "12",
      label: "12",
    },
    {
      value: "13",
      label: "13",
    },
    {
      value: "14",
      label: "14",
    },
    {
      value: "15",
      label: "15",
    },
    {
      value: "16",
      label: "16",
    },
    {
      value: "17",
      label: "17",
    },
    {
      value: "18",
      label: "18",
    },
    {
      value: "19",
      label: "19",
    },
    {
      value: "20",
      label: "20",
    },
    {
      value: "21",
      label: "21",
    },
    {
      value: "22",
      label: "22",
    },
    {
      value: "23",
      label: "23",
    },
  ],
  minutes: [
    {
      value: "",
      label: "--",
    },
    {
      value: "00",
      label: "00",
    },
    {
      value: "01",
      label: "01",
    },
    {
      value: "02",
      label: "02",
    },
    {
      value: "03",
      label: "03",
    },
    {
      value: "04",
      label: "04",
    },
    {
      value: "05",
      label: "05",
    },
    {
      value: "06",
      label: "06",
    },
    {
      value: "07",
      label: "07",
    },
    {
      value: "08",
      label: "08",
    },
    {
      value: "09",
      label: "09",
    },
    {
      value: "10",
      label: "10",
    },
    {
      value: "11",
      label: "11",
    },
    {
      value: "12",
      label: "12",
    },
    {
      value: "13",
      label: "13",
    },
    {
      value: "14",
      label: "14",
    },
    {
      value: "15",
      label: "15",
    },
    {
      value: "16",
      label: "16",
    },
    {
      value: "17",
      label: "17",
    },
    {
      value: "18",
      label: "18",
    },
    {
      value: "19",
      label: "19",
    },
    {
      value: "20",
      label: "20",
    },
    {
      value: "21",
      label: "21",
    },
    {
      value: "22",
      label: "22",
    },
    {
      value: "23",
      label: "23",
    },
    {
      value: "24",
      label: "24",
    },
    {
      value: "25",
      label: "25",
    },
    {
      value: "26",
      label: "26",
    },
    {
      value: "27",
      label: "27",
    },
    {
      value: "28",
      label: "28",
    },
    {
      value: "29",
      label: "29",
    },
    {
      value: "30",
      label: "30",
    },
    {
      value: "31",
      label: "31",
    },
    {
      value: "32",
      label: "32",
    },
    {
      value: "33",
      label: "33",
    },
    {
      value: "34",
      label: "34",
    },
    {
      value: "35",
      label: "35",
    },
    {
      value: "36",
      label: "36",
    },
    {
      value: "37",
      label: "37",
    },
    {
      value: "38",
      label: "38",
    },
    {
      value: "39",
      label: "39",
    },
    {
      value: "40",
      label: "40",
    },
    {
      value: "41",
      label: "41",
    },
    {
      value: "42",
      label: "42",
    },
    {
      value: "43",
      label: "43",
    },
    {
      value: "44",
      label: "44",
    },
    {
      value: "45",
      label: "45",
    },
    {
      value: "46",
      label: "46",
    },
    {
      value: "47",
      label: "47",
    },
    {
      value: "48",
      label: "48",
    },
    {
      value: "49",
      label: "49",
    },
    {
      value: "50",
      label: "50",
    },
    {
      value: "51",
      label: "51",
    },
    {
      value: "52",
      label: "52",
    },
    {
      value: "53",
      label: "53",
    },
    {
      value: "54",
      label: "54",
    },
    {
      value: "55",
      label: "55",
    },
    {
      value: "56",
      label: "56",
    },
    {
      value: "57",
      label: "57",
    },
    {
      value: "58",
      label: "58",
    },
    {
      value: "59",
      label: "59",
    },
  ],
}
const getWeekNameByDate = (day: number) => {
  switch (day) {
    case 0:
      return "Sun"
    case 1:
      return "Mon"
    case 2:
      return "Tue"
    case 3:
      return "Wed"
    case 4:
      return "Thu"
    case 5:
      return "Fri"
    case 6:
      return "Sat"
    default:
      return ""
  }
}
const formattedDateAndTime = (date: string, format: string) => {
  return dayjs(date).isValid() ? dayjs(date).format(format) : ""
}
const getCurrentJapaneseEra = () => {
  const japaneseDateWithEra = new Intl.DateTimeFormat("ja-JP-u-ca-japanese", {
    era: "long",
    year: "numeric",
  }).format(Date.now())
  //Above date is in the format of 令和5年 where the characters before the numeric value 5 represents era, split the string and get era.
  return t(japaneseDateWithEra?.split(/[0-9]/)[0]) || ""
}

const daysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"]
export const FULL_MONTHS_NAME = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
]
export const getFullEraDate = (year, month) => {
  const era = wareki(`${year}-${month}`, {
    unit: true,
  })
  return era ? era?.replace(/\b(\d)\b/g, "0$1") : ""
}
const FULL_WEEK_NAME = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
]

//filter end time(hour) according to start time(hour)
export const getEndTimeHour = (hours: string) => {
  const hrs = hours?.split(":")[0]
  const hrsIndex = CALENDAR_HOURS_MINUTES?.hours?.findIndex(
    (h) => h?.value === hrs,
  )
  const new_array = [CALENDAR_HOURS_MINUTES?.hours?.[0]].concat(
    CALENDAR_HOURS_MINUTES?.hours?.slice(
      hrsIndex,
      CALENDAR_HOURS_MINUTES?.hours?.length,
    ),
  )

  return new_array
}
export const getFullDateWithEraIncluded = (date: Dayjs) => {
  const era = wareki(`${dayjs(date).year()}-${dayjs(date).month()}`, {
    unit: true,
  })
  return `${era}${dayjs(date).month() + 1}月${dayjs(date).date()}日`
}

export {
  CALENDAR_HOURS_MINUTES,
  daysOfWeek,
  formattedDateAndTime,
  FULL_WEEK_NAME,
  getCurrentJapaneseEra,
  getDateString,
  getPastYearList,
  getWeekNameByDate,
  MONTHS,
}
