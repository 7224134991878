import React from "react"
import styled from "styled-components"
import { useTranslation } from "react-i18next"
import Link from "next/link"
import { theme } from "../../theme"
import Image from "next/image"
const LogoWrapper = styled.div`
  padding: 10px;
  background-color: ${theme?.colors.secondary};
  display: flex;
  align-items: center;
  gap: 1em;
  height: 81px;
  cursor: pointer;
  .tagline {
    color: ${theme?.colors?.white};
    font-weight: 500;
    font-size: 16px;
    white-space: pre-wrap;
  }
`

const Logo = ({ collapsed }: { collapsed?: boolean }) => {
  const { t } = useTranslation()
  return (
    <Link href={"/"}>
      <LogoWrapper>
        <Image
          src={"/assets/main-logo.svg"}
          alt={"logo"}
          height={50}
          width={50}
        />
        {!collapsed && (
          <span className={"tagline"}>{t("就労支援システム")}</span>
        )}
      </LogoWrapper>
    </Link>
  )
}
export { Logo }
