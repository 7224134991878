import React, { forwardRef } from "react"

// packages
import { Empty } from "antd"
import Table, { TableProps as RcTableProps } from "rc-table"
import { convertChildrenToColumns } from "rc-table/lib/hooks/useColumns"

// styles
import { PrintFriendlyTableStyle } from "./PrintFriendlyTable.styles"

// types
import { PFTableProps } from "./types"
import { ColumnsType } from "antd/es/table"
import { AnyObject } from "antd/es/_util/type"

// ! ## Main component
export const InternalPrintFriendlyTable = <
  TRecordType extends AnyObject = AnyObject,
>({
  visibleInScreenMode,
  columns,
  dataSource,
  children,
  ref,
  ...props
}: PFTableProps<TRecordType> & {
  headerBg?: string
  border?: string
  visibleInScreenMode?: boolean
  padding?: string
  fontSize?: string
  ref?: React.Ref<HTMLDivElement>
}) => {
  const baseColumns = React.useMemo(
    () =>
      columns ||
      (convertChildrenToColumns(children) as ColumnsType<TRecordType>),
    [columns, children],
  )
  return (
    <PrintFriendlyTableStyle
      ref={ref}
      $headerBg={props.headerBg}
      $border={props.border}
      $visibleInScreenMode={visibleInScreenMode}
      $padding={props.padding}
      $fontSize={props?.fontSize}
    >
      <Table
        prefixCls={"pft"} // as in print-friendly-table
        columns={baseColumns as RcTableProps<TRecordType>["columns"]} // [NOTE: Type casting is required here to calm down angry typescript👿]
        data={dataSource}
        emptyText={
          <div>
            <Empty />
          </div>
        }
        {...props}
      />
    </PrintFriendlyTableStyle>
  )
}

/**
 * `PrintFriendlyTable` component accepts almost all the props that `antd` table accepts [NOTE: antd uses rc-table internally with some customizations].
 * Including `columns` and `dataSource` props (These are the most important props for a table).
 * `component` prop is also available to override the default table component. (If you want to use a custom table component)
 * It can be updated according to the requirements incrementally.
 */
export const PrintFriendlyTable = forwardRef(InternalPrintFriendlyTable) as <
  TRecordType extends AnyObject = AnyObject,
>(
  props: PFTableProps<TRecordType> & {
    ref?: React.Ref<HTMLDivElement>
    headerBg?: string
    border?: string
    visibleInScreenMode?: boolean
    padding?: string
    fontSize?: string
  },
) => React.ReactElement
