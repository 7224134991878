import { API, removeBlankAttributes } from "@project/common"
import { MonitoringReturnChild, MonitoringSearchParams } from "../types"

export const fetchAllMonitoring = async (
  args: MonitoringSearchParams,
): Promise<MonitoringReturnChild> => {
  const params = removeBlankAttributes(args)
  return API.get(`/user-monitoring`, {
    params,
  })
}
export const getOneUserSupportPlanByUserId = ({
  userId,
  createCount,
}: {
  userId: number
  createCount: number
  operation?: string
}) => {
  const params = removeBlankAttributes({
    create_count: createCount,
    from: "monitoring",
  })
  return API.get(`/user-support/user/${userId}`, {
    params,
  })
}

export const createMonitoring = async (data: any) => {
  return API.post(`/user-monitoring`, data)
}

export const getOneUserMonitoring = async (id: string, user_id?: string) => {
  const params = removeBlankAttributes({ user_id })
  return API.get(`/user-monitoring/${id}`, {
    params,
  })
}

export const updateSignatureOfMonitoringDetail = (values: any) => {
  const data = removeBlankAttributes(values)
  return API.put(`/user-monitoring/${values?.planId}/update_signature`, data)
}

export const updateMonitoring = async (data: any) => {
  const { id, ...rest } = data
  await API.put(`/user-monitoring/${id}`, rest)
}

export const deleeteUserMonitoring = async (id: string, user_id?: string) => {
  const params = removeBlankAttributes({ user_id })
  return API.delete(`/user-monitoring/${id}`, {
    params,
  })
}
