import React from "react"
import styled from "styled-components"
import { theme } from "../../theme"
interface BoxContainerProps {
  children: React.ReactNode
}
const StyledBoxContainer = styled.div<BoxContainerProps>`
  box-shadow: 0px 0px 25px 0px ${theme.colors.shadow};
  border-radius: 12px;
  min-height: 100px;
  background-color: ${theme.colors.white};
  padding: 12px 5px;
`
const BoxContainer = ({
  children,
  ...props
}: BoxContainerProps): JSX.Element => {
  return <StyledBoxContainer {...props}>{children}</StyledBoxContainer>
}
BoxContainer.displayName = "BoxContainer"
export { BoxContainer }
