import styled, { css } from "styled-components"
import { theme } from "../../../theme"

const TdStyle = css`
  tbody {
    tr {
      page-break-inside: avoid !important;
      td {
        border: 1px solid ${theme.colors.text};
        padding: 10px;
        &:first-child {
          width: 200px;
        }
        #transportation_info {
          display: flex;
          flex-direction: column;
        }
      }
    }
  }
`
export const StyledPrintPageWrapper = styled.section`
  background-color: white;
  display: flex;
  flex-direction: column;
  gap: 16px;
  .title {
    background: #f3f3f3;
    padding: 10px;
  }
  .text__info {
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    margin-bottom: 8px !important;
  }
`
export const PrintSummaryTable = styled.div`
  #plan_detail_summary {
    width: 100%;
    border-collapse: collapse;
    ${TdStyle};
  }
`

export const PrintSummaryContentTable = styled.div`
  #efa__table {
    width: 100%;
    border-collapse: collapse;
    ${TdStyle};
  }
`
export const PrintSummaryGoalsContentTable = styled.div`
  #plan_detail_goals {
    width: 100%;
    border-collapse: collapse;
    thead {
      tr {
        th {
          border: 1px solid ${theme.colors.text};
          font-weight: normal;
          padding: 10px;
        }
      }
    }
    tbody {
      tr {
        page-break-inside: avoid !important;
        td {
          border: 1px solid ${theme.colors.text};
          padding: 10px;
          text-align: center;
          .list__item {
            display: flex;
            align-items: center;
            gap: 0.5rem;
            justify-content: center;
            .dot {
              width: 5px;
              height: 5px;
              background: #000;
              border-radius: 50%;
            }
          }
        }
      }
    }
  }
`
export const PrintSummaryFooter = styled.div`
  display: flex;
  column-gap: 16px;
  page-break-inside: avoid !important;
  .left__container {
    display: flex;
    flex-direction: column;
    row-gap: 16px;
    flex: 1;
    .content__container {
      display: flex;
      align-items: center;
      column-gap: 8px;
      .content {
        border-bottom: 1px solid ${theme.colors.text};
        display: flex;
        height: 40px;
        width: 300px;
        justify-content: space-between;
        &--image {
          display: flex;
          align-items: center;
          height: 100%;
          padding: 10px 0;
          width: 100%;
        }
      }
    }
  }
  .right__container {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    flex: 1;
    &--content {
      .content--support {
        display: flex;
        column-gap: 8px;
        .sign__field {
          display: flex;
          column-gap: 8px;
          border-bottom: 1px solid ${theme.colors.text};
          padding-bottom: 10px;
        }
      }
    }
  }
  #plan__date {
    display: flex;
    gap: 2.5rem;
    align-items: center;
    &--info {
      display: flex;
      gap: 2rem;
      color: ${theme.colors.border};
    }
  }
  #sign-img {
    height: auto;
    max-height: 26px;
    max-width: 163px;
  }
`
