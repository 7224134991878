/**
 * if value is in px, convert it to rem
 * if value is in rem, return it as is
 */
function createConverter(units: string) {
  function converter(value: unknown): string {
    if (value === 0 || value === "0") {
      return `0${units}`
    }

    if (typeof value === "number") {
      const val = `${value / 14}${units}`
      return val
    }

    if (typeof value === "string") {
      if (
        value.startsWith("calc(") ||
        value.startsWith("var(") ||
        value.startsWith("clamp(")
      ) {
        return value
      }

      if (value.includes(" ")) {
        return value
          .split(" ")
          .map((val) => converter(val))
          .join(" ")
      }

      if (value.includes(units)) {
        return value
      }

      const replaced = value.replace("px", "")
      if (!Number.isNaN(Number(replaced))) {
        const val = `${Number(replaced) / 14}${units}`
        return val
      }
    }

    return value as string
  }

  return converter
}
export const rem = createConverter("rem")
