import styled from "styled-components"
import { theme } from "../../theme"

export const SidebarWrapper = styled.div`
  position: relative;
  .ant-layout {
    background: ${theme.colors.background};
  }
  #site__sidebar {
    background-color: ${theme.colors.primary} !important;
  }
  @media print {
    display: none;
  }
`
