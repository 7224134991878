import styled, { css } from "styled-components"
import { theme } from "../../theme"

interface Props {
  collapsed?: boolean
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const spanSTyle = ({ theme: sTheme, collapsed }) => css`
  font-size: 14px !important;
  font-weight: normal;
  line-height: 20.27px;
  color: ${theme?.colors.secondary} !important;
  white-space: ${collapsed ? "nowrap" : "pre-wrap"} !important;
`

export const SideMenuWrapper = styled.div<Props>`
  height: calc(100vh - 125px) !important;
  overflow-y: auto;
  ul.ant-menu-inline-collapsed li > * span {
    display: none !important;
  }
  ul.ant-menu-root.ant-menu-light {
    background-color: ${theme.colors?.primary} !important;
    border-radius: 0 !important;
    width: 100% !important;
    border-inline-end: 0 !important;
    li.ant-menu-item {
      width: 100% !important;
      margin-block: 0 !important;
      margin-inline: 0 !important;
      border-radius: 0 !important;
      min-height: 45px !important;
      height: max-content !important;
      position: relative;
      padding-left: ${(props) =>
        props.collapsed ? "24px" : "10px !important;"};
      span {
        ${spanSTyle}
      }
      .ant-menu-title-content {
        span,
        a {
          ${spanSTyle}
        }
      }
      &::before {
        content: " ";
        width: 100%;
        height: 100%;
        position: absolute;
        border-bottom: 1px solid ${theme.colors.border};
        left: 0px;
      }
    }
    ${(props) =>
      props.collapsed &&
      css`
        li.ant-menu-submenu-selected {
          background-color: ${theme.colors.active} !important;
        }
      `};
    li.ant-menu-submenu {
      width: 100% !important;
      border-radius: 0 !important;
      position: relative;
      .ant-menu-submenu-title {
        margin-block: 0 !important;
        margin-inline: 0 !important;
        border-radius: 0 !important;
        width: 100% !important;
        min-height: 45px !important;
        height: max-content !important;
        padding-right: 0 !important;
        position: relative;
        padding-left: ${(props) =>
          props.collapsed ? "24px" : "10px !important;"};
        span {
          ${spanSTyle};
        }
        .ant-menu-submenu-arrow {
          position: absolute;
          right: 4px;
        }
      }
      ul.ant-menu-sub {
        background-color: ${theme.colors["t-head"]} !important;
        li {
          min-height: 50px !important;
          padding-left: ${(props) =>
            props.collapsed ? "48px" : "18px !important;"};
          position: relative;
          span {
            ${spanSTyle};
          }
          .ant-menu-title-content {
            span,
            a {
              ${spanSTyle};
            }
          }
          &::before {
            content: " ";
            width: 90%;
            height: 100%;
            position: absolute;
            border-bottom: 1px solid ${theme.colors.border};
            left: 10px;
          }
        }
      }
      &::before {
        content: " ";
        width: 100%;
        height: 100%;
        position: absolute;
        border-bottom: 1px solid ${theme.colors.border};
        left: 0px;
      }
      li.ant-menu-submenu-selected {
        div {
          .ant-menu-submenu-arrow {
            color: ${theme.colors.active} !important;
          }
        }
      }
    }

    .ant-menu-item-selected {
      background-color: ${theme.colors.active} !important;
    }
  }
`
