import { API } from "@project/common"
import { PAGE_SIZE } from "../constants"

export interface IReturnJointFacility {
  count?: number
  data?: any
}

interface IJointFacilityParam {
  page?: string
  pageSize?: string
  keyword?: string
}

export const fetchJointFacility = async ({
  queryKey,
}): Promise<IReturnJointFacility> => {
  const params = {} as IJointFacilityParam
  params.page = queryKey[1]
  params.keyword = queryKey[2] ? queryKey[2] : undefined
  params.pageSize = String(PAGE_SIZE)

  return API.get(`/joint-facility`, {
    params,
  })
}

export const fetchAllJointFacility = async () => {
  const params = {} as IJointFacilityParam
  params.pageSize = "Infinity"
  return API.get("/joint-facility", { params })
}

export const getJointFacility = async (id) => {
  return API.get(`/joint-facility/${id}`)
}

export const createJointFacility = async (values): Promise<any> => {
  return API.post(`/joint-facility`, values)
}

export const updateJointFacility = async (values) => {
  const id = values.id
  delete values.id
  return API.put(`/joint-facility/${id}`, values)
}
export const deleteJointFacility = async (id) => {
  return API.delete(`/joint-facility/${id}`)
}
