import { API, filterObject, removeBlankAttributes } from "@project/common"
import dayjs from "dayjs"
import { PAGE_SIZE } from "../constants"
import {
  UserAttendanceManagementMonthlyApprovalListDataType,
  UserAttendanceManagementOperationTypes,
  UserAttendanceManagementRequestListResponseTypes,
} from "../types"
import {
  DailyLeaveRecParams,
  DailyLeaveRecordData,
  UpdateStatusPayload,
} from "../types/userAttendanceMgmt.types"

export const getUserAttendanceManagementList = ({
  year,
  month,
  user_id,
  facility,
  page,
  pageSize = PAGE_SIZE,
}: {
  year: number | string
  month: number | string
  user_id: number | string
  facility: number | string
  page: number
  pageSize?: number
}): Promise<UserAttendanceManagementMonthlyApprovalListDataType> => {
  const params = removeBlankAttributes({
    year,
    month,
    user_id,
    facility,
    page,
    pageSize,
  })
  return API.get("/user-service-provision/list", { params })
}

export const getUserAttendanceManagementRequestList = ({
  facility_id,
  pageSize = PAGE_SIZE,
  ...queries
}: UserAttendanceManagementOperationTypes): Promise<UserAttendanceManagementRequestListResponseTypes> => {
  const params: any = removeBlankAttributes({
    ...queries,
    pageSize,
    facility: facility_id || null,
    year: dayjs(queries?.date).year() || null,
    month: dayjs(queries?.date).month() + 1 || null,
  })
  delete params.date
  return API.get("/user-daily-record-request/attendance", { params })
}
export const updateFacilityUserRecordRequestStatus = (
  values: {
    status: number
    id: number
    rejected_reason: string
  }[],
) => {
  return API.post("/user-daily-record-request/update-status", values)
}

export const getDailyLeaveRecord = (
  params?: Partial<DailyLeaveRecParams>,
): Promise<DailyLeaveRecordData> => {
  return API.get("/user-daily-record-request/leave", {
    params: filterObject(params),
  })
}

export const updateStatus = (payload: UpdateStatusPayload[]) =>
  API.post("/user-daily-record-request/update-status", payload)
export const getUserServiceContentResultMonthlyDetails = ({
  year,
  month,
  user_id,
  facility_id,
}: {
  year: number
  month: number
  user_id: number | string
  facility_id?: number | string
}): Promise<any> => {
  const params = removeBlankAttributes({ user_id, year, month, facility_id })
  return API.get(`/user-attendance/monthly-details`, { params })
}

export const createFacilityUser = (payload) => {
  API.post("/user-attendance/monthly-details", payload)
}

export const postAllUsersAttendanceManagement = (data): Promise<any> => {
  return API.post(`/user-attendance/monthly-details`, data)
}

export const updatePaidLeaveForRequest = (payload: {
  id: number
  is_paid_leave: boolean
}) =>
  API.put(`/user-daily-record-request/update-paid-leave/${payload.id}`, payload)
