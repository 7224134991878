import styled from "styled-components"
import { theme } from "../../theme"

export const Wrapper = styled.div<{ signType: string }>`
  /* padding: 16px 0; */
  width: 100% !important;
  .btns-container {
    border-top: 1px solid ${theme.colors.border};
    margin-top: 10px;
    padding: 10px;
    display: flex;
    gap: 10px;
    align-items: center;
    button {
      padding: 5px 12px;
      width: fit-content;
    }
  }
  .pad-container .pad span {
    position: absolute;
    top: 50%;
    left: 40%;
  }
  .pad-container {
    padding: 0 10px;
    width: 100% !important;
    position: relative;

    .date__signature_pad_container {
      border: 2px solid black;
      border-radius: 5px;
      padding: 10px 10px 10px 10px !important;
      width: 100% !important;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;

      span {
        font-weight: bold;
        font-size: 16px;
      }
      button {
        position: absolute;
        top: 8px;
        right: 4px;
        z-index: 30000 !important;
        min-width: 75px !important;

        > span {
          font-size: 12px;
        }
      }

      canvas {
        z-index: 20;
      }
    }
    .pad {
      border: 2px solid black;
      border-radius: 5px;
      padding: 10px 10px 10px 10px;
      width: 100% !important;
      border-top-left-radius: 0px;
      position: relative;
      z-index: 1 !important;

      button {
        position: absolute;
        top: 8px;
        right: 4px;
        min-width: 75px !important;

        > span {
          font-size: 12px;
          position: static;
        }
      }
    }
  }
`
