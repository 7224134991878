import React, { ReactNode } from "react"
import { PageTitleWrapper } from "./PageTitle.style"

interface PageTitleProps {
  extra?: ReactNode
  icon?: ReactNode
  title: string
  pageSubTitle?: string
  extraChild?: ReactNode
  marginBottom?: string
}
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const PageTitle = ({
  extra,
  icon,
  title,
  pageSubTitle,
  extraChild,
  marginBottom,
}: PageTitleProps): JSX.Element => {
  return (
    <PageTitleWrapper marginBottom={marginBottom}>
      <div className={"page__title--right"}>
        <div className={"icon__container"}>{icon ? icon : ""}</div>
        <h3 className={"text__container"}>{title}</h3>
      </div>
      <span>{extraChild && extraChild}</span>

      {pageSubTitle || extra ? (
        <div className={"sub_title_content"}>
          <span>{pageSubTitle && pageSubTitle}</span>
          <span>{extra && extra}</span>
        </div>
      ) : null}
    </PageTitleWrapper>
  )
}
PageTitle.displayName = "PageTitle"
export { PageTitle }
