import React, { useCallback } from "react"
import styled from "styled-components"
import { Button } from "../Button/Button"
import dayjs from "dayjs"

const CalendarPaginationWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 5px 0px;
  .year-pagination-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    button {
      background: white;
      color: black;
      border: 1px solid #0782c8;
      border-radius: 10px;
      height: 40px;

      &.active {
        background: rgba(7, 130, 200, 0.2);
      }
    }
    .common-btn-styling-right {
      display: flex;
      gap: 7px;
    }
  }
  @media (max-width: 900px) {
    .year-pagination-wrapper {
      display: flex;
    }
  }
  @media print {
    display: none;
  }
`
interface ICalendarPagination {
  currentDate: any
  onNext: (v: any) => void
  onPrev: (v: any) => void
  activeText?: string
  setActiveText: (v: any) => void
  picker?: string
}

const CalendarPaginationForDay: React.FC<ICalendarPagination> = ({
  currentDate,
  onNext,
  onPrev,
  activeText,
  setActiveText,
  picker,
}) => {
  const handleYearChange = useCallback(
    (type: string) => {
      const saveDate = currentDate || new Date()
      const saveYear = +currentDate

      if (picker == "year") {
        if (type === "previousYear") {
          setActiveText("previousYear")
          const _date = saveYear - 1

          return onPrev(_date)
        } else if (type === "nextYear") {
          setActiveText("nextYear")

          const _date = saveYear + 1
          onNext(_date)
        } else if (type === "currentYear") {
          setActiveText("currentYear")
          const _date = dayjs().format("YYYY")
          onNext(_date)
        }
      } else {
        if (type === "previousYear") {
          setActiveText("previousYear")
          const _date = new Date(saveDate.setDate(saveDate.getDate() - 1))

          return onPrev(_date)
        } else if (type === "nextYear") {
          setActiveText("nextYear")

          const _date = new Date(saveDate.setDate(saveDate.getDate() + 1))
          onNext(_date)
        } else if (type === "currentYear") {
          setActiveText("currentYear")

          const _date = new Date()
          onNext(_date)
        }
      }
    },

    [currentDate],
  )
  return (
    <CalendarPaginationWrapper>
      <div className={"year-pagination-wrapper"}>
        <Button
          btnText={"前年"}
          onClick={() => handleYearChange("previousYear")}
          className={`left-btn common-btn-styling ${
            activeText === "previousYear" ? "active active-btn" : ""
          }`}
          iconType={"backward-arrow"}
        />

        <Button
          btnText={"今年"}
          onClick={() => handleYearChange("currentYear")}
          className={` active-btn common-btn-styling ${
            activeText === "currentYear" ? "active active-btn" : ""
          }`}
        />

        <Button
          btnText={"翌年"}
          onClick={() => handleYearChange("nextYear")}
          className={`right-btn common-btn-styling-right ${
            activeText === "nextYear" ? "active active-btn" : ""
          }`}
          iconType={"frontward-arrow"}
        />
      </div>
    </CalendarPaginationWrapper>
  )
}

export { CalendarPaginationForDay }
