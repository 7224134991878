import { Menu, MenuProps } from "antd"
import Link from "next/link"
import { useRouter } from "next/router"
import React, { useEffect, useMemo } from "react"
import { SIDE_BAR_PROPS } from "../../types"
import { SideMenuWrapper } from "./SideMenu.style"

interface SideMenuProps {
  menuItems: any[]
  collapsed?: boolean
  sideBarProps: SIDE_BAR_PROPS
}
type MenuItem = Required<MenuProps>["items"][number]
function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[],
  type?: "group",
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
    type,
  } as MenuItem
}

const SideMenu = ({
  menuItems,
  collapsed,
  sideBarProps: { openKeys, setOpenKeys, current, setCurrent },
}: SideMenuProps): JSX.Element => {
  const router = useRouter()

  const onOpenChange: MenuProps["onOpenChange"] = (keys) => {
    const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1)
    const rootSubmenuKeys = menuItems?.map((v) => v?.key)
    if (latestOpenKey && rootSubmenuKeys.indexOf(latestOpenKey!) === -1) {
      setOpenKeys(keys)
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : [])
    }
  }
  const sideMenuItems = useMemo(
    () =>
      menuItems?.map((menu: any) => {
        return getItem(
          menu?.link ? (
            <Link href={menu?.link} className={"fuck0it"}>
              {menu?.label}
            </Link>
          ) : (
            <>{menu?.label}</>
          ),
          menu?.key,
          menu?.icon,
          menu?.children?.length > 0
            ? menu?.children?.map((sub) =>
                getItem(
                  sub?.link ? (
                    <Link href={sub?.link}>{sub?.label}</Link>
                  ) : (
                    <>{sub?.label}</>
                  ),
                  sub?.key,
                  null,
                ),
              )
            : null,
        )
      }),
    [],
  )
  useEffect(() => {
    menuItems?.map((item) => {
      if (router?.pathname.startsWith("/feature-not-available")) {
        setCurrent("feature-not-available")
      }
      if (!collapsed) {
        if (item?.children?.length) {
          item?.children?.map((subItem) => {
            if (router?.pathname?.startsWith(subItem?.link)) {
              setOpenKeys([item?.key])
              setCurrent(subItem?.key)
            }
          })
        } else {
          setCurrent(item?.key)
        }
      }
    })
  }, [router?.pathname, collapsed])

  return (
    <SideMenuWrapper collapsed={collapsed}>
      <Menu
        mode={collapsed ? "vertical" : "inline"}
        inlineCollapsed={collapsed}
        items={sideMenuItems}
        openKeys={openKeys}
        onOpenChange={onOpenChange}
        selectedKeys={[current]}
        onClick={(e) => {
          setCurrent(e.key)
        }}
      />
    </SideMenuWrapper>
  )
}
SideMenu.displayName = "SideMenu"
export { SideMenu }
