import { API, pick } from "@project/common"
import { OptionalPartial } from "../containers/TransportMgmtBulkEditInternal/types"
import {
  CalculateReceipt,
  ConfirmUserInfo,
  InvoiceToCustomerResponse,
  ReceiptProvDetails,
  RemarksFormData,
  UsedFacilityRecordData,
} from "../types/nhif.types"

export const getConfirmedBillingFacilities = ({ year, month, facilityIds }) =>
  API.get(`/nhif-billings/confirmed-facilities/${year}/${month}`, {
    params: { facilityIds },
  })

export const getNHIFBillingSteps = ({
  year,
  month,
  facility,
}: {
  year: number | string
  month: number | string
  facility: number | string
}) =>
  API.get(
    `/nhif-billings/billing-step?facility=${facility}&year=${year}&month=${month}`,
  )

export const getNHIFEligibleUserList = ({
  year,
  month,
  facility,
}: {
  year: number | string
  month: number | string
  facility: number | string
}) =>
  API.get(
    `/nhif-billings/users?facility=${facility}&year=${year}&month=${month}`,
  )

export const saveNHIFEligibleUserList = (values: ConfirmUserInfo) =>
  API.post(`/nhif-billings/confirm-billing-info`, values)

// year=2024&month=6&facilityIds=1,2&adult_ids=1
type BillingStatementParams = {
  year: number
  month: number
  facilityIds: string
  user_ids: number
}

type CustomerInvoiceParams = {
  year: number
  month: number
  facilityIds: string
  user_ids: string
}

type ReceiptProvDetailsParams = {
  year: number
  month: number
  page_no: number
}

type ReceiptProvDetailsPayload = {
  page: number
} & RemarksFormData

export const getCityWiseBillingStatements = (
  params?: Partial<BillingStatementParams>,
): Promise<UsedFacilityRecordData> => {
  return API.get(`/nhif/common-list`, {
    params,
  })
}

export const calculateNHIFReceipt = (values: CalculateReceipt) =>
  API.post(`/receipt`, values)

//disabled_user_outpatient_benefits_print data
export const getDUOBPData = async ({
  facilityId,
  year,
  month,
  user_ids,
  city_ids,
}: {
  facilityId: number | string
  year: number | string
  month: number | string
  show_zero_billing_amount?: number | null
  user_ids: number | string
  city_ids: number | string
}) => {
  const params = { user_ids, city_ids }
  return API.get(`/nhif/print1/${facilityId}/${year}/${month}`, { params })
}
export const getReceiptProvDetails = (
  params: ReceiptProvDetailsParams,
): Promise<ReceiptProvDetails> =>
  API.get(`/nhif/receipt-provision-details/${params.year}/${params.month}`, {
    params: pick(params, ["page_no"]),
  })

export const createReceiptProvDetails = (
  params: Omit<ReceiptProvDetailsParams, "page_no">,
  payload: OptionalPartial<ReceiptProvDetailsPayload, "page">,
): Promise<unknown> =>
  API.post(
    `/nhif/receipt-provision-details/${params.year}/${params.month}`,
    payload,
  )

export const getNHIFAlerts = ({
  year,
  month,
  facility,
}: {
  year: number | string
  month: number | string
  facility: number | string
}) =>
  API.get(
    `/nhif-billings/alerts?facility=${facility}&year=${year}&month=${month}`,
  )

export const getCustomerInvoiceDetails = (
  params: CustomerInvoiceParams,
): Promise<InvoiceToCustomerResponse> =>
  API.get(`/nhif/invoice-to-customer/${params.year}/${params.month}`, {
    params: pick(params, ["facilityIds", "user_ids"]),
  })
