import { App } from "antd"
import type { NotificationPlacement } from "antd/es/notification/interface"
type NotificationType = "success" | "info" | "warning" | "error"

export const useNotification = () => {
  const { notification } = App.useApp()
  const showToast = ({
    type,
    message,
    placement = "topRight",
    description,
  }: {
    placement?: NotificationPlacement
    type: NotificationType
    message: string
    description?: string
  }) => {
    notification[type]({
      message,
      placement,
      className: "eassy-toast-icon",
      description,
    })
  }
  return { showToast }
}
