import { AxiosResponse } from "axios"

export const transformDriverStaffs = (
  data?: AxiosResponse<any, any>,
): {
  id: number
  value: number
  label: string
  furigana: string
}[] => {
  return data?.data.map((item) => ({
    id: item?.id,
    value: item?.id,
    label: item?.staff_name || "",
    furigana: item?.staff_name_furiganaame || "",
  }))
}
