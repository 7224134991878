import { API } from "@project/common"
export interface IReturnPaySlipList {
  count?: number
  data?: any
}

export interface IPaySlipListParam {
  page?: string | number
  facility_id?: string
  user_id?: number
  year?: string
  month?: string
  pageSize?: number
}

const BASE_ENDPOINT = "user-pay-slip"

export const fetchPaySlipList = async ({
  queryKey,
}): Promise<IReturnPaySlipList> => {
  const params = {} as IPaySlipListParam
  params.pageSize = 10
  params.page = queryKey[1]
  params.facility_id = queryKey[2]?.facility
  params.user_id = queryKey[2]?.user_id
  params.year = queryKey[2]?.date
  params.month = queryKey[2]?.month
  return API.get(`/${BASE_ENDPOINT}`, {
    params,
  })
}

export const fetchPaySlipDetails = async ({
  queryKey,
}): Promise<IReturnPaySlipList> => {
  const user_id = queryKey[1]
  const year = queryKey[2]
  const month = queryKey[3]
  return API.get(`/${BASE_ENDPOINT}/${user_id}/${year}/${month}`)
}

export const getPaySlip = async ({ queryKey }): Promise<any> => {
  const id = queryKey[1]
  return API.get(`/${BASE_ENDPOINT}/${id}`)
}

export const updatePaySlip = async (values): Promise<any> => {
  const id = values?.id
  const year = values?.year
  const month = values?.month
  delete values?.id
  delete values?.year
  delete values?.month
  return API.post(`/${BASE_ENDPOINT}/${id}/${year}/${month}`, values)
}

export const recalculateBaseWage = async (values): Promise<any> => {
  const id = values?.id
  const year = values?.year
  const month = values?.month
  return API.post(
    `/${BASE_ENDPOINT}/recalculate-base-wage/${id}/${year}/${month}`,
    values,
  )
}
