import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"
import SignaturePad from "react-signature-canvas"
import { Wrapper } from "./SignaturePad.style"
import { Button } from "../Button/Button"

interface IProps {
  onSave: (sign: any) => void
  isLoading?: boolean
  sign?: string
  signType?: "date" | "name" | "onlyDate" | "both"
  dateSign?: string
  isDateSignatureChecked?: boolean
  DEFAULT_DATE_SIGNATURE_PAD_IMAGE: string
}
export const SignatureCanvas: React.FC<IProps> = ({
  onSave,
  isLoading,
  sign,
  dateSign,
  signType,

  DEFAULT_DATE_SIGNATURE_PAD_IMAGE,
}) => {
  const { t } = useTranslation()
  const signCanvasRef = React.useRef<any>()
  const dateCanvasRef = React.useRef<any>()

  const clearSignature = () => {
    if (signCanvasRef) {
      signCanvasRef?.current?.clear()
    }
    dateCanvasRef?.current?.clear()
    dateCanvasRef?.current?.fromDataURL(DEFAULT_DATE_SIGNATURE_PAD_IMAGE, {
      width: 480,
      height: 80,
    })
  }
  const clearSignSignature = () => {
    if (signCanvasRef) {
      signCanvasRef?.current?.clear()
    }
  }
  const clearDateSignature = () => {
    dateCanvasRef?.current?.clear()
    dateCanvasRef?.current?.fromDataURL(DEFAULT_DATE_SIGNATURE_PAD_IMAGE, {
      width: 480,
      height: 80,
    })
  }
  useEffect(() => {
    if (signCanvasRef?.current != undefined) {
      signCanvasRef?.current.fromDataURL(sign, { width: 480, height: 250 })
    }
    dateCanvasRef?.current?.fromDataURL(
      dateSign || DEFAULT_DATE_SIGNATURE_PAD_IMAGE,
      {
        width: 480,
        height: 80,
      },
    )
  }, [sign, dateSign])

  const saveSignature = async () => {
    if (signType === "name") {
      const sign = signCanvasRef?.current
        ?.getTrimmedCanvas()
        .toDataURL("image/png")
      onSave(sign)
    } else {
      const dateSignature = dateCanvasRef.current
        .getTrimmedCanvas()
        .toDataURL("image/png")
      onSave(dateSignature)
    }
  }
  const renderPad = (type) => {
    switch (type) {
      case "name":
        return (
          <div className={"pad"}>
            <SignaturePad
              ref={signCanvasRef}
              canvasProps={{
                width: 480,
                height: 250,
                className: "sigCanvas",
              }}
            />
            <span
              style={{
                pointerEvents: "none",
              }}
            >
              {t("Sign")}
            </span>
            <Button
              onClick={clearSignSignature}
              type={"default"}
              danger
              btnText={t("Clear")}
              height={"20px"}
            />
          </div>
        )
      case "date":
        return (
          <div className={"date__signature_pad_container"}>
            <SignaturePad
              ref={dateCanvasRef}
              canvasProps={{
                width: 480,
                height: 80,
                className: "sigCanvas",
              }}
            />

            <Button
              onClick={clearDateSignature}
              type={"default"}
              danger
              btnText={t("Clear")}
            />
          </div>
        )
      default:
        return null
    }
  }

  return (
    <Wrapper signType={signType}>
      <div className={"pad-container"}>{renderPad(signType)}</div>
      <div className={"btns-container"}>
        <Button
          onClick={clearSignature}
          type={"default"}
          danger
          btnText={t("Clear all")}
          shape={"round"}
          disabled={isLoading}
        />
        <Button
          onClick={saveSignature}
          isLoading={isLoading}
          btnText={t("Save")}
          shape={"round"}
        />
      </div>
    </Wrapper>
  )
}
