export const theme = {
  colors: {
    primary: "#77D5F7",
    secondary: "#374151",
    action: "#0782C8",
    "bright-blue": "#1677ff",
    "action-light": "#086BA4",
    "action-light-01": "#E2F4FF",
    "action-200": "#0782C8CC",
    "action-300": "#0782C899",
    "action-400": "#EAF9FF",
    disabled: "#F3F3F3",
    accent: "#FCD95F",
    accent2: "#FFEFB8",
    "accent-light-2": "#ECF0BD",
    "accent-light": "#FFF2E7",
    text: "#191919",
    "text-gray": "#333333",
    "sub-text": "#393939",
    "sub-text2": "#565555",
    "disabled-text": "#A9A9A9",
    placeholder: "#888787",
    border: "#D2D1D1",
    shadow: " #00000014",
    btnShadow: "#0000001a",
    "t-head": "#E0F2FE",
    error: "#E00000",
    "error-light-01": "#d4a9a4",
    error_dark: "#BB0000",
    red: "#ca3030",
    "error-light": "#E000001A",
    success: "#10A359",
    info: "#D26D10",
    "info-light": "#FCEDDE",
    infoDark: "#E26827",
    white: "#FFFFFF",
    background: "#F9F9F9",
    link: "#0782C8",
    active: "#F3FAFF",
    disabled2: "#E6E6E6",
    red3: "#E26827",
    gray3: "#858686",
    gray4: "#F6F7F8",
    gray5: "#D9DEE0",
    accentLight: "#CDA001",
    pink: "#ffaecb",
    lightPink: "rgba(202, 48, 48, 0.12)",
    "pink-light": "#FFE5DD",
    "pink-light2": "#FFC9B8",
    blue7: "#4DA2F8",
    blue9: "#E3F1F9",
    "open-menu": "#E0F2FE",
    orange: "#ef8237",
    orange2: "#FAA43A",
    blue8: "#2195cb",
    alert: "#EB5757",
    blue10: "0782c8",
    rgba: {
      blue: `rgba(7, 130, 200, 0.1)`,
      accent: `rgba(252, 217, 95, 0.28)`,
    },
    activeBtn: "#DFF3FF",
    red7: "rgb(248, 230, 230)",
    darkYellow: "#AF8900",
    lightYellow: "#FFFDF3",
    green: "#4CB476",
    lightGreen: "#F2FFF7",
    gray6: "#F7F7F7",
    red8: "#FCF0E9",
    gray7: "#CCCBCC",
    dimGray: "#F5F5F5",
    blue: "#CDE6F4",
    blue2: "#EAF9FF",
    blueShade: "#F0F6F6",
    blueLight: "#44ACD9",
    gray8: "#F2F2F2",
    border3: "#E4E4E4",
    neutral2: "#5c5c5c",
    gray9: "#c1c1c1",
    gray10: "#737373",
    gray11: "#F4F4F4",
    softGray1: "#FAFAFA",
    cyan1: "#069EB1",
    cyan2: "#06BCD4",
    cyan3: "#EBFAFC",
    cyan4: "#EBF2F2",
    cyan5: "#BDE4E4",
    red4: "#b10644",
    green1: "#06B15F",
    green2: "#069DB0",
    greenish: "#EBF2F2",
    cyan6: "#069DB1",
    "slate-1": "#414d61",
    "yellow-2": "#FDD95F",
    "yellow-3": "#e4eaa1",
    gray12: "#EDEDED",
  },
  // EasyyFontSize "xs" | "sm" | "md" | "lg" | "xl" | "xxl"
  fontSizes: {
    xs: "12px",
    sm: "14px",
    md: "16px",
    lg: "18px",
    xl: "20px",
    xxl: "24px",
  },

  // EasyyLineHeight "xs" | "sm" | "md" | "lg" | "xl" | "xxl"
  lineHeights: {
    xs: "16px",
    sm: "18px",
    md: "20px",
    lg: "22px",
    xl: "24px",
    xxl: "28px",
  },

  // EasyySpacingValues "4xs" | "3xs" | "xxs" | "xs" | "sm" | "md" | "xmd" | "lg" | "xlg" | "xl" | "xxl" | "xxxl"
  spacing: {
    "4xs": "4px",
    "3xs": "6px",
    xxs: "8px",
    xs: "10px",
    sm: "12px",
    md: "16px",
    xmd: "20px",
    lg: "24px",
    xlg: "32px",
    xl: "40px",
    xxl: "48px",
    xxxl: "64px",
  },

  breakpoints: {
    "4xs": "250px",
    "3xs": "300px",
    xxs: "350px",
    xs: "400px",
    sm: "480px",
    md: "767px",
    xmd: "800px",
    lg: "1024px",
    xlg: "1100px",
    xl: "1200px",
    xxl: "1400px",
  },
  space: {
    xxs: "0.2em",
    xs: "0.4em",
    sm: "0.5em",
    md: "0.7em",
    xmd: "0.9em",
    default: "16px",
    lg: "1.5em",
    xlg: "2em",
    xl: "2.5em",
    xxl: "3em",
    xxxl: "4em",
  },
  typography: {
    header: {
      "font-family": `"Noto Sans JP", serif`,
      "font-weight": "500 !important",
      "font-size": "1em !important",
      "letter-spacing": 0,
      " text-decoration": "none !important",
    },
    "body-medium": {
      "font-family": `"Noto Sans JP", serif`,
      "font-weight": "500 !important",
      "font-size": "14px !important",
      "letter-spacing": 0,
      " text-decoration": "none",
    },
    "body-regular": {
      "font-family": `"Noto Sans JP", serif`,
      "font-weight": "400 !important",
      "font-size": "14px !important",
      "letter-spacing": 0,
      " text-decoration": "none",
    },
    "body-small": {
      "font-family": `"Noto Sans JP", serif`,
      "font-weight": "100 !important",
      "font-size": "12px !important",
      "letter-spacing": 0,
      " text-decoration": "none",
    },
    "bnt-text": {
      "font-family": `"Noto Sans JP", serif`,
      "font-weight": "400 !important",
      "font-size": "14px !important",
      "letter-spacing": 0,
      " text-decoration": "none",
    },
  },
  radius: {
    xs: "0.4em",
    sm: "0.5em",
    md: "0.7em",
    lg: "1em",
    xl: "2em",
    circle: "50%",
    round: "4%",
  },
  shadow: {
    btnShadow: "box-shadow: 2px 2px 6px 0px #0000001a",
  },
  border: {
    default: `1px solid #D2D1D1`,
  },
}

export type EasyyTheme = typeof theme
