import React from "react"
import styled from "styled-components"
import { theme } from "../../theme"

interface BusinessCalendarPaginationProps {
  year?: number
  currentYear: number
  activeYear?: "current" | "next" | "prev"
  currentMonth: number
  handleYearChange: (e: any, year: number, active: string) => void
  handleMonthChange: (e: any, month: number) => void
  hideNextButtons?: boolean
  hideMonths?: boolean
  noFb?: boolean
}

const Wrapper = styled.div<any>`
  .content {
    border: 1px solid ${theme.colors.border};
    width: fit-content;
    border-radius: 10px;
    button {
      padding: 0px 8px;
      border: 0;
      height: 40px;
      min-width: 40px;
      background-color: transparent;
      &:not(:last-child) {
        border-right: 1px solid ${theme.colors.border};
      }
      @media screen and (max-width: ${theme.breakpoints.md}) {
        min-width: 30px;
        padding: 4px;
        &:first-child,
        &:last-child {
          width: fit-content !important;
        }
      }
    }
    .active {
      background-color: ${theme.colors.primary};
    }
  }
  @media screen and (max-width: ${theme.breakpoints.md}) {
    white-space: nowrap;
    overflow: scroll;
    width: 100%;
  }
`

const BusinessCalendarPagination = ({
  handleYearChange,
  handleMonthChange,
  currentYear,
  currentMonth,
}: BusinessCalendarPaginationProps): JSX.Element => {
  return (
    <Wrapper>
      <div className={"content"}>
        <button
          onClick={(e) => handleYearChange(e, currentYear - 1, "prev")}
        >{`${currentYear}年`}</button>
        {[...Array(12)].map((_, i) => (
          <button
            key={i}
            className={i + 1 == currentMonth ? "active" : ""}
            onClick={(e) => handleMonthChange(e, i + 1)}
          >
            {i + 1}
          </button>
        ))}
        <button
          onClick={(e) => handleYearChange(e, currentYear + 1, "next")}
        >{`${currentYear + 1}年`}</button>
      </div>
    </Wrapper>
  )
}
BusinessCalendarPagination.displayName = "BusinessCalendarPagination"
export { BusinessCalendarPagination }
