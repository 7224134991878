import {
  Table as AntTable,
  TableProps as AntTableProps,
  ConfigProvider,
  Flex,
} from "antd"
import { PaginationProps } from "antd/lib"
import { type TableProps as RcTableProps } from "rc-table"
import React, { ReactElement } from "react"
import { useTranslation } from "react-i18next"
import styled from "styled-components"
import { Pagination } from "../../components"
import { theme } from "../../theme"

interface TableProps<T = unknown> extends Omit<AntTableProps<T>, "dataSource"> {
  customPagination?: boolean
  showPaginationOf?: "bottom" | "top" | "both"
  borderColor?: string
  headerBg?: string
  cellPaddingBlock?: number
  children?: any
  noHeaderBorder?: boolean
  headerBorderRadius?: number
  headerCellPaddingBlock?: string
  displayText?: string
  displayText2?: string
  displayTextIcon?: ReactElement
  displayText2Icon?: ReactElement
  dataSource?: RcTableProps<T>["data"]
  tableAttributesExtra?: React.ReactNode
  tableContainerStyle?: React.CSSProperties
  extraHeader?: React.ReactNode
}

const Container = styled.div<TableProps>`
  .table__attributes {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px 24px;
    .total_count_info {
      ${theme.typography["body-regular"]}
    }
  }
  .ant-table-title {
    padding: 0 !important;
    overflow: hidden !important;
  }
`
const StyledTable = styled(AntTable)<TableProps>`
  border-collapse: collapse !important;

  .ant-table-bordered {
    border: 0 !important;
    .ant-table-container {
      ${(props) => props?.noHeaderBorder && `border-top:0 !important;`}
      ${(props) => props?.noHeaderBorder && `border-inline-start:0 !important;`}
    }
  }
  thead {
    tr {
      th,
      th > * {
        ${theme.typography["header"]};
        ${(props) =>
          props?.noHeaderBorder &&
          ` border-top: 0 !important;
          border-right: 0 !important;
          border-left: 0 !important;
          `};
      }
      ${(props) =>
        props?.headerCellPaddingBlock &&
        `th{
          padding:${props?.headerCellPaddingBlock} !important;
        }`};
    }
  }
  tbody {
    tr {
      td,
      td > * {
        ${theme.typography["body-regular"]};
        a {
          color: ${theme.colors.link};
          font-size: 14px;
          text-decoration: underline !important;
        }
      }
      ${(props) =>
        props?.noHeaderBorder &&
        `td {
        &:first-child {
          border-left: 1px solid ${theme.colors.border} !important;
        }
      };
      `}
    }
  }
  @media print {
    border-collapse: separate !important;
    .ant-table-content {
      overflow: auto !important;
      thead {
        tr {
          th,
          th > * {
            font-size: 8pt !important;
            background-color: transparent !important;
          }
        }
      }
      tbody {
        tr {
          td,
          td > * {
            font-size: 8pt !important;
            background-color: transparent !important;
          }
        }
      }
    }
  }

  /* checkbox Selection */
  .ant-checkbox-inner {
    width: 24px;
    height: 24px;
  }
  .ant-checkbox-checked .ant-checkbox-inner {
    background: linear-gradient(0deg, #dffdff, #dffdff);
    border-color: ${theme.colors.border};
    &::after {
      left: 6px;
      width: 6px;
      height: 13px;
      border: 2px solid ${theme.colors.action};
      border-left: 0;
      border-top: 0;
      transform: rotate(45deg) scale(1) translate(-50%, -50%);
    }
  }
`

function Table<T>({
  customPagination = true,
  showPaginationOf = "bottom",
  borderColor,
  headerBg,
  cellPaddingBlock = 8,
  children,
  headerBorderRadius = 8,
  tableAttributesExtra,
  tableContainerStyle,
  extraHeader,
  ...props
}: TableProps<T> & PaginationProps): JSX.Element {
  const { t } = useTranslation()
  const tableAttributes = (direction?: string) => {
    return (
      <div
        className={"table__attributes"}
        style={{
          marginBottom: direction === "bottom" && "1em",
          marginTop: direction === "top" && "1em",
        }}
      >
        <Flex align={"center"} wrap={"wrap"} gap={"10px 24px"}>
          <span className={"total_count_info"}>
            {t("Total {{total}} data", {
              total: props?.total,
            })}
          </span>
          {direction === "bottom" && tableAttributesExtra}
        </Flex>
        <Pagination {...props} />
      </div>
    )
  }
  return (
    <Container style={tableContainerStyle}>
      {props?.total
        ? customPagination
          ? (showPaginationOf === "top" || showPaginationOf === "both") &&
            tableAttributes("bottom")
          : ""
        : null}
      {extraHeader || ""}
      {children}
      <ConfigProvider
        theme={{
          components: {
            Table: {
              borderColor: borderColor || theme.colors.border,
              headerBg: headerBg || theme.colors["t-head"],
              cellPaddingBlock: cellPaddingBlock,
              headerBorderRadius: headerBorderRadius,
            },
          },
        }}
      >
        <StyledTable bordered pagination={false} {...props} />
      </ConfigProvider>
      {props?.total
        ? customPagination &&
          (showPaginationOf === "bottom" || showPaginationOf === "both") &&
          tableAttributes("top")
        : ""}
    </Container>
  )
}
Table.displayName = "Table"
export { AntTable, Table }
