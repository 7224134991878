import styled from "styled-components"
import { theme } from "../../theme"
export const MonthPaginationWrapper = styled.div<{
  $width?: string | undefined
}>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  .month-pagination-wrapper {
    button {
      background: white;
      color: ${theme.colors.text};
      border: 1px solid ${theme.colors.action};
      border-radius: 6px;
      height: 36px;
      margin-right: 10px;
      padding: 0px 8px;
      min-width: ${(props) => props?.$width || "fit-content"};
      width: fit-content;
      &.active {
        min-width: ${(props) => props?.$width || "auto"};
        width: auto;
        color: ${theme.colors.action};
      }
      .left-btn-label {
        margin-left: 0.3em;
      }
      .right-btn-label {
        margin-right: 0.3em;
      }
    }
  }
  @media (max-width: ${theme.breakpoints.sm}) {
    width: 100%;
    .month-pagination-wrapper {
      display: flex;
      flex-wrap: wrap;
      row-gap: 4px;
      button {
        font-size: 12px !important;
        margin-right: 5px;
      }
    }
  }
  @media (max-width: ${theme.breakpoints.xs}) {
    .month-pagination-wrapper {
      button {
        margin-right: 2px;
      }
    }
  }
  @media print {
    display: none;
  }
`
