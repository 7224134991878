import { API } from "@project/common"
import {
  B_ACTIVE,
  BusinessDayRegistrationProps,
  INITIAL_CALENDAR_DATA,
  INITIAL_CALENDAR_DATA_EXTRA,
} from "../types"

export const fetchMonthlyBusinessDays = async (year: number) => {
  return API.get(`/business-days/list/${year}`)
}

export const fetchBusinessDayRegistration = async ({
  facilityId,
  currentYear,
  currentMonth,
  tab,
}: BusinessDayRegistrationProps): Promise<{
  count: number
  data: INITIAL_CALENDAR_DATA & INITIAL_CALENDAR_DATA_EXTRA
}> => {
  if (tab === "bulk_setting_for_year")
    return API.get(
      `/business-days/year-setting?facility=${facilityId}&year=${currentYear}`,
    )
  if (tab === "bulk_setting_for_month")
    return API.get(
      `/business-days?facility=${facilityId}&year=${currentYear}&month=${currentMonth}`,
    )
}

export const registerBusinessDay = async ({
  values,
  tab,
}: {
  values?: any
  tab: B_ACTIVE
}) => {
  if (tab === "bulk_setting_for_year")
    return API.post(`/business-days/year-setting`, values)
  if (tab === "bulk_setting_for_month")
    return API.post(`/business-days`, values)
}

export const deleteBusinessDayRegistration = async (values: any) => {
  return API.delete(
    `/business-days/${values?.facility_id}/${values?.year}/${values?.month}`,
  )
}
