import React from "react"
import { Checkbox } from "antd"
import styled, { css } from "styled-components"
import { CheckboxProps, CheckboxGroupProps } from "antd/es/checkbox"
import { theme } from "../../theme"
import { useTranslation } from "react-i18next"

type item = {
  value: string | number | Array<number | string>
  label?: string
  disabled?: boolean
  [x: string]: any
}
interface CheckBoxProps extends CheckboxProps {
  label?: string
  noLabel?: boolean
}
interface CheckBoxGroupProps extends CheckboxGroupProps {
  items: item[] | any
}

//styles
const StyledCheckBox = styled(Checkbox)<CheckBoxProps>`
  .ant-checkbox-inner {
    width: 24px;
    height: 24px;
  }
  .ant-checkbox-checked .ant-checkbox-inner {
    background: linear-gradient(0deg, #dffdff, #dffdff);
    border-color: ${theme.colors.border};
    &::after {
      left: 6px;
      width: 6px;
      height: 13px;
      border: 2px solid ${theme.colors.action};
      border-left: 0;
      border-top: 0;
      transform: rotate(45deg) scale(1) translate(-50%, -50%);
    }
  }
  ${(props) =>
    props?.noLabel &&
    css`
      .ant-checkbox + span {
        padding-inline-start: 0 !important;
        padding-inline-end: 0 !important;
      }
    `}
`

const StyledCheckBoxGroup = styled(Checkbox.Group)<CheckBoxGroupProps>`
  row-gap: 10px;
  .ant-checkbox-wrapper {
    & > * {
      ${theme.typography["body-regular"]};
    }
  }
`

const CheckBox = ({ label, ...props }: CheckBoxProps): JSX.Element => {
  return <StyledCheckBox {...props}>{label}</StyledCheckBox>
}
CheckBox.displayName = "CheckBox"

const CheckBoxGroup = ({
  items,
  ...props
}: CheckBoxGroupProps): JSX.Element => {
  const { t } = useTranslation()
  return (
    <StyledCheckBoxGroup {...props}>
      {items?.map((item) => (
        <>
          <CheckBox
            key={item.value}
            label={
              t(item?.label) +
              (item?.total ? `(${item.total} ${t("people")})` : "")
            }
            value={item?.value}
            disabled={item?.disabled}
          />
        </>
      ))}
    </StyledCheckBoxGroup>
  )
}
CheckBoxGroup.displayName = "CheckBoxGroup"
export { CheckBox, CheckBoxGroup }
