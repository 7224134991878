import { API, removeBlankAttributes } from "@project/common"

export const saveShiftMaster = async (values: any) => {
  return API.post(`/attendance-shift`, values)
}

export const fetchShiftMaster = (id: string | string[] | number) =>
  API.get(`/attendance-shift/${id}`)

export const updateShiftMaster = ({ id, ...values }: any) => {
  return API.put(`/attendance-shift/${id}`, { ...values })
}
export const deleteShiftMaster = (id) => {
  return API.delete(`/attendance-shift/${id}`)
}

export const fetchAllShiftMaster = ({
  page,
  pageSize,
  keyword,
  facilityIds,
}: {
  page: number
  pageSize: number | string
  keyword?: string
  facilityIds?: Array<number>
  targetIds?: string[]
}) => {
  const params = removeBlankAttributes({
    facilityIds: facilityIds?.toString(),
    keyword,
  })

  return API.get(`/attendance-shift?page=${page}&pageSize=${pageSize}`, {
    params,
  })
}
