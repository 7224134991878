import React from "react"

import { useTranslation } from "react-i18next"
import { Button } from "antd"
import { MonthPaginationWrapper } from "./MonthPagination.style"
import ArrowLeft from "../../assets/icons/arrow-left.svg"
import ArrowRight from "../../assets/icons/arrow.svg"
import dayjs from "dayjs"
interface ICalendarPagination {
  className?: string
  currentDay: string
  onDayChange?: (day: string) => void
  showDate?: boolean
  disabled?: boolean
}

const DayPagination: React.FC<ICalendarPagination> = ({
  className,
  disabled,
  onDayChange,
  currentDay,
}): JSX.Element => {
  const { t } = useTranslation()
  const changeToPreviousDay = () => {
    const previousDate = dayjs(currentDay)
      .subtract(1, "day")
      .format("YYYY-MM-DD")
    onDayChange(previousDate)
  }

  const changeDateToToday = () => {
    const today = dayjs().format("YYYY-MM-DD")
    onDayChange(today)
  }

  const changeToNextDay = () => {
    const nextDate = dayjs(currentDay).add(1, "day").format("YYYY-MM-DD")
    onDayChange(nextDate)
  }

  return (
    <MonthPaginationWrapper className={className}>
      <div className={"month-pagination-wrapper"}>
        <Button
          onClick={changeToPreviousDay}
          className={"left-btn"}
          disabled={disabled}
        >
          <ArrowLeft />
          <span className={"left-btn-label"}>{t("Prev day")}</span>
        </Button>
        <Button
          onClick={changeDateToToday}
          className={"active active-btn"}
          disabled={disabled}
        >
          <>{t("Today")}</>
        </Button>
        <Button
          onClick={changeToNextDay}
          className={"right-btn"}
          disabled={disabled}
        >
          <span className={"right-btn-label"}>{t("Next day")}</span>
          <ArrowRight />
        </Button>
      </div>
    </MonthPaginationWrapper>
  )
}

export { DayPagination }
