import dayjs from "dayjs"
import { t } from "i18next"
import React from "react"
import Audit from "../public/assets/icons/aduit.svg"
import Data from "../public/assets/icons/data.svg"
import Home from "../public/assets/icons/home.svg"
import Operation from "../public/assets/icons/operational.svg"
import Revenue from "../public/assets/icons/revenew.svg"
import Settings from "../public/assets/icons/setting.svg"
import Support from "../public/assets/icons/support.svg"
import {
  ActualCostBurdenPermission,
  ActualCostMasterPermission,
  ActualCostSchedulePermission,
  AdditionalItemFacilityPermission,
  AdditionalItemUserPermission,
  AdditionMinutePermission,
  AssessmentMasterPermission,
  AttendanceSchedulePermission,
  BillingCSVPermission,
  BusinessListPermission,
  ConsultationSupportMasterPermission,
  DailyBusinessReportPermission,
  EmploymentCertificateRegistrtionPermission,
  FacilityApplicationListPermission,
  FacilityInformationPermission,
  IndividualSupportPlanPermission,
  InstructorMasterPermission,
  JointFacilityMasterPermission,
  MealMasterPermission,
  MonthlyStatusPermission,
  MunicipalSubsidiaryPermission,
  ProvisionCityMasterPermission,
  RecipientCertificatePermission,
  RevenueManagementPermission,
  ServiceProvisionRecordPermission,
  ShiftMasterPermission,
  ShuttleCarMasterPermission,
  StaffAttendanceRecordPermission,
  SystemMailSettings,
  TransportationManagementPermission,
  TreatmentImprovementPermission,
  UserAttendanceManagementPermission,
  UserDailyRecordPermission,
  UserManagementPermission,
  WageManagementPermission,
  WorkDetailsMasterPermission,
} from "./PermissionKeys"
type PermissionType = "write" | "both"
const READPERMISSION = "1"
const WRITEPERMISSION = "2"

type MenuItem = {
  key: string
  icon?: any
  label: string
  requiresPermission: boolean
  hasTopMenuPermission?: boolean
  link?: string
  permissionKeys?: string | Array<string> | Array<Array<string>>
  children?: Array<MenuItem>
}
const handleStringPermission = (
  value: string,
  permissionType: PermissionType,
) => {
  switch (permissionType) {
    case "both":
      return value === READPERMISSION || value === WRITEPERMISSION
    case "write":
      return value === WRITEPERMISSION
  }
  return false
}
// if permission is array then further drill down is necessary treating each element as object
const handleArrayPermission = (
  currentPermission: Array<any>,
  nextKeys: Array<any>,
  permissionType: PermissionType,
) => {
  let newPermissionObject: any
  for (let i = 0; i < currentPermission.length; i++) {
    newPermissionObject = currentPermission[i]
    if (typeof newPermissionObject === "string")
      return handleStringPermission(newPermissionObject, permissionType)
    else if (typeof newPermissionObject === "object") {
      let currentLoop = 0
      while (
        typeof newPermissionObject === "object" &&
        currentLoop < nextKeys.length
      ) {
        newPermissionObject = handleObjectPermission(
          newPermissionObject,
          nextKeys[currentLoop],
        )
        currentLoop++
      }
      if (
        typeof newPermissionObject === "string" &&
        handleStringPermission(newPermissionObject, permissionType)
      )
        return true
    }
  }
  return false
}
// if permission is object then further drill down is necessary
const handleObjectPermission = (currentPermission: any, nextKey: string) => {
  return currentPermission[nextKey]
}

export const hasPermissionForMenu = (
  permissions: any,
  permissionsForMenu: Array<any>,
  permissionType: PermissionType = "both",
) => {
  if (typeof permissions !== "object" || !permissions) return false
  let permissionList = [],
    loopFor = 1

  if (Array.isArray(permissionsForMenu[0])) {
    loopFor = permissionsForMenu.length
    permissionList = permissionsForMenu
  } else {
    permissionList.push(permissionsForMenu)
  }

  let currentPermissionObject: any
  for (let eachPermission = 0; eachPermission < loopFor; eachPermission++) {
    const permissionKeys = permissionList[eachPermission]
    for (let i = 0; i < permissionKeys.length; i++) {
      const currentPermissionKey = permissions[permissionKeys[i]]
      if (!currentPermissionObject)
        currentPermissionObject = currentPermissionKey

      if (
        typeof currentPermissionObject === "string" &&
        handleStringPermission(currentPermissionObject, permissionType)
      )
        return true
      else if (
        Array.isArray(currentPermissionObject) &&
        handleArrayPermission(
          currentPermissionObject,
          permissionKeys.filter((key, index) => index > i),
          permissionType,
        )
      ) {
        return true
      } else if (typeof currentPermissionObject === "object") {
        currentPermissionObject = handleObjectPermission(
          currentPermissionObject,
          i < permissionKeys.length ? permissionKeys[i + 1] : "",
        )
      }
    }
  }
  return false
}

export const menuItems: Array<MenuItem> = [
  {
    key: "home",
    icon: React.createElement(Home),
    label: t("Top page"),
    link: "/",
    requiresPermission: false,
  },
  {
    key: "OperationalManagement",
    icon: React.createElement(Operation),
    label: t("Operational Management"),
    requiresPermission: true,
    hasTopMenuPermission: false,
    children: [
      {
        key: "userAttendanceRecord",
        label: t("User attendance calendar"),
        link: "/user-attendance-calendar",
        requiresPermission: true,
        permissionKeys: UserAttendanceManagementPermission,
      },
      {
        key: "UserAttendanceManagement",
        label: t("User attendance management"),
        link: "/user-attendance-management",
        requiresPermission: true,
        permissionKeys: UserAttendanceManagementPermission,
      },
      {
        key: "UserAbsenceInformation",
        label: t("User absence information"),
        link: `/user-attendance-calendar/absence-record?from_date=${dayjs().format(
          "YYYY-MM-DD",
        )}&to_date=${dayjs().format("YYYY-MM-DD")}`,
        requiresPermission: true,
        permissionKeys: UserAttendanceManagementPermission,
      },
      /*
      {
        key: "UserActivityManagement",
        label: t("facility Activity management"),
        link: "/facility-activity-management",
        requiresPermission: true,
        permissionKeys: [],
      },*/
      {
        key: "TodaysTransportationManagement",
        label: t("Today's transportation management"),
        link: "/user-transport-management",
        requiresPermission: true,
        permissionKeys: TransportationManagementPermission,
      },
      {
        key: "Daily business report list",
        label: t("Daily business report list"),
        link: "/daily-business-report-list",
        requiresPermission: true,
        permissionKeys: DailyBusinessReportPermission,
      },
      // {
      //   key: "ChatWithUser",
      //   label: t("Chat with user"),
      //   link: "/chat-with-user",
      //   requiresPermission: true,
      //   permissionKeys: [],
      // },

      {
        key: "AttendanceSchedule",
        label: t("Attendance schedule"),
        link: "/attendance-schedule",
        requiresPermission: true,
        permissionKeys: AttendanceSchedulePermission,
      },
      {
        key: "AttendanceRecord",
        label: t("Attendance record"),
        link: "/attendance-record",
        requiresPermission: true,
        permissionKeys: StaffAttendanceRecordPermission,
      },
      {
        key: "UserManagement",
        label: t("User management"),
        link: "/users",
        requiresPermission: true,
        permissionKeys: UserManagementPermission,
      },
      {
        key: "UserDailyRecord",
        label: t("User Daily Record"),
        link: "/user-daily-record",
        requiresPermission: true,
        permissionKeys: UserDailyRecordPermission,
      },
      // {
      //   key: "GuardianManagement",
      //   label: t("Guardian management"),
      //   link: "/guardian-management",
      //   requiresPermission: true,
      //   permissionKeys: [],
      // },
      {
        key: "BusinessDayManagementList",
        label: t("Business day management list"),
        link: "/business-day-management",
        requiresPermission: true,
        permissionKeys: BusinessListPermission,
      },
      {
        key: "FacilityInformation",
        label: t("Facility information"),
        link: "/facility-information",
        requiresPermission: true,
        permissionKeys: FacilityInformationPermission,
      },
      {
        key: "List of users who need to update their beneficiary certificate",
        label: t(
          "List of users who need to update their beneficiary certificate",
        ),
        link: "/list-of-users",
        requiresPermission: true,
        permissionKeys: RecipientCertificatePermission,
      },
      // {
      //   key: "FacilityCalendar",
      //   label: t("Facility Calendar"),
      //   link: "/Facility-Calendar",
      //   requiresPermission: true,
      //   permissionKeys: [],
      // },
      {
        key: "WageWorkSetting",
        label: t("Wage settings, Work time settings(Un-defined)"),
        link: "/wage-management",
        requiresPermission: true,
        permissionKeys: WageManagementPermission,
      },
      {
        key: "EmploymentCertificateRegistration",
        label: t("Employment certificate registration(Un-defined)"),
        link: "/employment-certificate",
        requiresPermission: true,
        permissionKeys: EmploymentCertificateRegistrtionPermission,
      },
    ],
  },
  {
    key: "RevenueManagement",
    icon: React.createElement(Revenue),
    label: t("Revenue Management"),
    requiresPermission: true,
    hasTopMenuPermission: false,
    children: [
      {
        key: "RevenueManagement",
        label: t("Revenue management"),
        link: "/revenue-management",
        requiresPermission: true,
        permissionKeys: RevenueManagementPermission,
      },
      {
        key: "WagesRevenue",
        label: t("Wages revenue"),
        link: "/wages-revenue",
        requiresPermission: true,
        permissionKeys: WageManagementPermission,
      },
      // {
      //   key: "FacilityTourAndTrialApplicationManagement",
      //   label: t("Facility tour and trial application management"),
      //   link: "/facility-tour-and-trial-application-management",
      //   requiresPermission: true,
      //   permissionKeys: [],
      // },
      {
        key: "UserFacilityScheduleRegistrationStatus",
        label: t("User facility schedule registration status"),
        link: "/user-facility-schedule-registration-status",
        requiresPermission: true,
        permissionKeys: FacilityApplicationListPermission,
      },
    ],
  },
  {
    key: "SupportPlanManagement",
    icon: React.createElement(Support),
    label: t("Support Plan Management"),
    requiresPermission: true,
    hasTopMenuPermission: false,
    children: [
      {
        key: "UserStatusList",
        label: t("User Status list"),
        link: "/user-status-list",
        requiresPermission: true,
        permissionKeys: UserManagementPermission,
      },
      {
        key: "Assessment",
        label: t("Assessment"),
        link: "/assessment",
        requiresPermission: true,
        permissionKeys: UserManagementPermission,
      },
      {
        key: "IndividualSupportPlan(Draft)",
        label: t("Individual support plan (Draft)"),
        link: "/draft-individual-support-plan",
        requiresPermission: true,
        permissionKeys: IndividualSupportPlanPermission,
      },
      {
        key: "MinutesOfMeetingOfPIC",
        label: t("Minutes of meeting of PIC"),
        link: "/minutes",
        requiresPermission: false,
      },
      {
        key: "IndividualSupportPlan",
        label: t("Individual support plan"),
        link: "/individual-support-plan",
        requiresPermission: true,
        permissionKeys: IndividualSupportPlanPermission,
      },
      {
        key: "MonitoringList",
        label: t("Monitoring"),
        link: "/monitoring-list",
        requiresPermission: true,
        permissionKeys: UserManagementPermission,
      },
      {
        key: "UserWhoNeedsMonitoring",
        label: t("User who needs monitoring"),
        link: "/user-who-needs-monitoring",
        requiresPermission: false,
      },
    ],
  },
  {
    key: "InvoiceManagement",
    icon: React.createElement(Revenue),
    label: t("Invoice Management"),
    requiresPermission: true,
    hasTopMenuPermission: false,
    children: [
      {
        key: "AdditionalItemsManagement",
        label: t("Additional items management"),
        link: "/additional-items-management",
        requiresPermission: true,
        permissionKeys: [
          AdditionalItemFacilityPermission,
          AdditionalItemUserPermission,
        ],
      },
      {
        key: "VariousAdditionsManagemenOfMeetinginutes",
        label: t("Various additions ・Management of meeting minutes"),
        link: "/various-additions-management-of-meeting-minutes",
        requiresPermission: true,
        permissionKeys: AdditionMinutePermission,
      },
      {
        key: "ActualCostBurdenSchedule",
        label: t("Actual cost burden schedule"),
        link: "/actual-cost-burden-schedule",
        requiresPermission: true,
        permissionKeys: ActualCostSchedulePermission,
      },
      {
        key: "ActualCostBurdenRecord",
        label: t("Actual cost burden record"),
        link: "/actual-cost-burden-record",
        requiresPermission: true,
        permissionKeys: ActualCostBurdenPermission,
      },
      {
        key: "ListOfMunicipalSubsidyClaimsInformationManagement",
        label: t("List of Municipal Subsidy Claims Information Management "),
        link: "/list-of-municipal-subsidy-claims-information-management",
        requiresPermission: true,
        permissionKeys: MunicipalSubsidiaryPermission,
      },
      {
        key: "NationalHealthInsuranceBilling",
        label: t("National Health Insurance Billing"),
        link: "/national-health-insurance-billing",
        requiresPermission: true,
        permissionKeys: BillingCSVPermission,
      },
    ],
  },
  {
    key: "AuditDocuments",
    icon: React.createElement(Audit),
    label: t("Audit Documents"),
    requiresPermission: true,
    hasTopMenuPermission: false,
    children: [
      // {
      //   key: "AuditDocumentSubmission",
      //   label: t("Audit document submission"),
      //   link: "/audit-document-submission",
      //   requiresPermission: true,
      //   permissionKeys: [],
      // },
      {
        key: "ServiceProvisionRecord",
        label: t("Service provision record"),
        link: "/user-service-record",
        requiresPermission: true,
        permissionKeys: ServiceProvisionRecordPermission,
      },

      // {
      //   key: "ContractReport",
      //   label: t("Contract report"),
      //   link: "/contract-report",
      //   requiresPermission: true,
      //   permissionKeys: [],
      // },
      {
        key: "Monthly use status chart",
        label: t("Monthly use status chart"),
        link: "/monthly-use-status-chart",
        requiresPermission: true,
        permissionKeys: MonthlyStatusPermission,
      },
      {
        key: "TreatmentImprovementAdditionAmountResultTable",
        label: t("Treatment improvement addition amount result table"),
        link: "/treatment-improvement-addition-amount-result-table",
        requiresPermission: true,
        permissionKeys: TreatmentImprovementPermission,
      },
    ],
  },
  {
    key: "BasicSettingManagement",
    icon: React.createElement(Data),
    label: t("Basic setting management"),
    requiresPermission: true,
    hasTopMenuPermission: false,
    children: [
      {
        key: "StaffMaster",
        label: t("Staff master"),
        link: "/staff-master",
        requiresPermission: true,
        permissionKeys: InstructorMasterPermission,
      },
      {
        key: "StaffShiftMaster",
        label: t("Staff shift master"),
        link: "/shift-master",
        requiresPermission: true,
        permissionKeys: ShiftMasterPermission,
      },
      {
        key: "UserShiftMaster",
        label: t("User shift master"),
        link: "/shift-user-master",
        requiresPermission: true,
        permissionKeys: ShiftMasterPermission,
      },
      {
        key: "ProvisionCityMaster",
        label: t("Provision city master"),
        link: "/provisional-cities",
        requiresPermission: true,
        permissionKeys: ProvisionCityMasterPermission,
      },
      {
        key: "OtherFacilityMaster",
        label: t("Other facility master"),
        link: "/joint-facility",
        requiresPermission: true,
        permissionKeys: JointFacilityMasterPermission,
      },
      {
        key: "ConsultationSupportOfficeMaster",
        label: t("Consultation support Office Master"),
        link: "/consultation-support-office-master",
        requiresPermission: true,
        permissionKeys: ConsultationSupportMasterPermission,
      },
      {
        key: "ShuttleMaster",
        label: t("Shuttle master"),
        link: "/shuttle-master",
        requiresPermission: true,
        permissionKeys: ShuttleCarMasterPermission,
      },
      {
        key: "Actual items master",
        label: t("Actual items master"),
        link: "/actual-items-master",
        requiresPermission: true,
        permissionKeys: ActualCostMasterPermission,
      },
      //hidding this menu for now
      // {
      //   key: "ProgressManagementMaster",
      //   label: t("Progress management master"),
      //   link: "/progress-management",
      //   requiresPermission: true,
      //   permissionKeys: ProgressManagementMasterPermission,
      // },
      /*
      {
        key: "EmploymentAndPartnerCompaniesMaster",
        label: t("Employment and partner companies master"),
        link: "/employment-partner-companies",
        requiresPermission: true,
        permissionKeys: [],
      },
      */
      {
        key: "WorkDetailsMaster",
        label: t("Work Details Master"),
        link: "/work-details-master",
        requiresPermission: true,
        permissionKeys: WorkDetailsMasterPermission,
      },
      {
        key: "MealInformationMaster ",
        label: t("Meal Information master"),
        link: "/meal-master",
        requiresPermission: true,
        permissionKeys: MealMasterPermission,
      },
      {
        key: "AssessmentMaster",
        label: t("Assessment Master"),
        link: "/assessment-master",
        requiresPermission: true,
        permissionKeys: AssessmentMasterPermission,
      },
    ],
  },
  {
    key: "Management/SettingsPage",
    icon: React.createElement(Settings),
    label: t("Management/ Settings Page"),
    requiresPermission: true,
    hasTopMenuPermission: false,
    children: [
      {
        key: "SystemUserAccountManagement",
        label: t("System user account management"),
        link: "/system-user-account-management",
        requiresPermission: true,
        permissionKeys: [],
      },
      /*
      {
        key: "UserGuardianOutputInformationCollectively",
        label: t("User Guardian output information collectively"),
        link: "/user-guardian-output-information-collectively",
        requiresPermission: true,
        permissionKeys: [],
      },
      */

      {
        key: "CompanyInformationManagement",
        label: t("Company information management"),
        link: "/company-information",
        requiresPermission: true,
        permissionKeys: [],
      },
      /*
      {
        key: "MyPageDisplaySetting",
        label: t("My page display setting"),
        link: "/my-page-display-setting",
        requiresPermission: true,
        permissionKeys: [],
      },
      */
      {
        key: "AutoEmailSending",
        label: t("Auto email sending"),
        link: "/auto-email-sending",
        requiresPermission: true,
        permissionKeys: SystemMailSettings,
      },
      /*
      {
        key: "DataBackup",
        label: t("Data backup"),
        link: "/data-backup",
        requiresPermission: true,
        permissionKeys: [],
      },
      */
    ],
  },
]

// Add other future release page's routes here
export const futureReleaseRoutes = [
  { route: "/service-provision-record", name: "Service provision record" },
  {
    route: "/employment-partner-companies",
    name: "Employment and partner companies master",
  },
  {
    route: "/chat-with-user",
    name: "Chat with user",
  },
  {
    route: "/guardian-management",
    name: "Guardian management",
  },
  {
    route: "/Facility-Calendar",
    name: "Facility Calendar",
  },
  {
    route: "/settings",
    name: "Settings",
  },
  {
    route: "/contract-report",
    name: "contract report",
  },
  {
    route: "/data-backup",
    name: "data backup",
  },
  {
    route: "/my-page-display-setting",
    name: "my page display setting",
  },
  {
    route: "/user-guardian-output-information-collectively",
    name: "User Guardian output information collectively",
  },
  {
    route: "/facility-tour-and-trial-application-management",
    name: "Facility Tour and Trial application management",
  },
  {
    route: "/audit-document-submission",
    name: "Audit Document Submission",
  },
  {
    route: "/facility-activity-management",
    name: "Facility activity management",
  },

  // TODO: Add more routes so on......
]

export const hasPermissionForNewRegistration = (permissions) =>
  permissions?.auth_new_delete_flag == "1"
