/**
 *
 * @param allOptions
 * @param furigana
 * @returns Options filtered by furigana
 */
export const getFuriFilteredOptions = (
  allOptions: {
    id: number
    label: string
    value: number
    furigana: string
  }[],
  furigana?: string | null | 0,
) => {
  if (!furigana) {
    return allOptions
  }

  const filteredOptions =
    allOptions &&
    allOptions?.filter((option) => {
      const optionFurigana = option.furigana || ""
      // return optionFurigana.includes(furigana)
      return (
        optionFurigana.startsWith(furigana) ||
        option?.label?.startsWith(furigana)
      )
    })

  return filteredOptions?.map((option) => ({
    label: option.label,
    value: option.value,
  }))
}
